import React, {useState, useEffect, useLayoutEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Container, Grid, Typography, Button, Tooltip, ClickAwayListener, CircularProgress, Link, Divider} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import {connect} from 'react-redux';
import { consoleToLog, showDate, statusColor, trackGAEvent, AccessLevel, showListItemDate, AppRoutes } from '../util/AppUtil';
import FilterDrawer from './FilterDrawer';
import { useIsMount } from './useIsMount';
import moment from 'moment';
import { logout } from '../actions/invoiceAuth';
import { setClearInvoiceAccounts, SendEmailType} from '../actions/invoiceAccount';
import { clearSelectedInvoiceFilters } from '../actions/invoiceFilters';
import { SelectedDrawerState, setSelectedDrawerState } from '../actions/drawer';
import { useSnackbar } from 'notistack';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import {getInvoiceListApi, loggedInUserDownloadApi, deleteInvoiceApi, convertToTaxApi, createDuplicateInvoiceApi} from '../services/authService';
import { CSSTransition, TransitionGroup} from 'react-transition-group';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import ReceiptIcon from '@material-ui/icons/Receipt';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import { InvoiceStatus, InoviceTypes } from '../util/AppUtil';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {exportInvoicesApi} from '../services/authService';
import {OverlayMessage} from '../util/AppUtil'; 
import { setOvelayMessage, setOverlayToken, setOverlayView, OverlayView, setApiRoute } from '../actions/overlay';
import {getClientInvoicesApi} from '../services/authService';
import { formatAmount } from '../util/currencyUtil';
import { usePropsContext } from './context';
import { history } from '../routers/AppRouter';
import useRestoreComponentState from './useRestoreComponentState';
import {setHeightForComponent} from '../util/AppUtil';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import {cancelInvoiceApi, convertTODraftApi} from '../services/authService';
import { isInvoiceStatusCancelled } from '../util/AppUtil';
import TransformIcon from '@material-ui/icons/Transform';
import ShowConfirmDialog from './ShowConfirmDialog';
import {displayInvoiceTypeLabel} from '../util/AppUtil';
import {Popover, MenuItem} from '@material-ui/core';
import {setInvoiceSortByFilter} from '../actions/invoiceFilters';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import SwapVertOutlinedIcon from '@material-ui/icons/SwapVertOutlined';
import {showActivityIcons, statusNameAndColor} from '../util/AppUtil'

const useStyles = makeStyles((theme) => ({
    mainContent : {
        transition: ".5s", 
        //overflow:'hidden',
        overflow:'auto',
        overflowX:'hidden',
        "&::-webkit-scrollbar": {
            display: "none"
        }
    },
    container: {
        marginTop: "8px"
    },
    MuiButtonlabel : {
        fontSize: "12px"
    },
    buttonPlusIcon: {
        marginRight:'6px', 
        fontSize:'1.2rem', 
        marginTop:'-2px'
    },
    icons : {
        color: "black",
        fontSize: "15px",
        minWidth:'20px',
        lineHeight: '10px',
    },
    headings: {
        fontWeight: '600',
        [theme.breakpoints.down(1400)]: {
            fontSize:'14px'
        },
    },
    invoiceListContainer : {
        background:'white', 
        minWidth:"100%", 
        borderRadius:'4px', 
        padding:'0px 16px 0px 16px', 
        color:'#2B2B2B',
    },
    invoiceListHover: {
        padding:'16px 0px',
        position:'relative',
        '& .action__icon' : {
            padding:'4px 4px 0px 4px',
            border:'1px solid white',
            borderRadius:'4px',
            position:'relative',
            top:'2px'
        },
        '&:hover .action__icon' : {
            border:'1px solid #ccc'
        },
        '&:hover':{
            cursor:"pointer"
        },
        '& .more_actionButton' : {
            visibility:'hidden',
            width:'150px',
            background:'white',
            border:'1px solid #ccc',
            borderRadius:'5px',
            fontSize:'14px',
            padding:'1px 0px',
        },
        '& .more_actionButton1' : {
            visibility:'hidden',
            width:'120px',
            background:'white',
            border:'1px solid #ccc',
            borderRadius:'5px',
            fontSize:'14px',
            padding:'1px 0px',
        },
        '& .more_actionButton2' : {
            visibility:'hidden',
            width:'180px',
            background:'white',
            border:'1px solid #ccc',
            borderRadius:'5px',
            fontSize:'14px',
            padding:'1px 0px',
            display:'flex',
            alignItems:'center'
        },
        '&:hover .more_actionButton' : {
            visibility:'visible'
        },
        '&:hover .more_actionButton1' : {
            visibility:'visible'
        },
        '&:hover .more_actionButton2' : {
            visibility:'visible'
        },
    },
    MuiPopoverpaper: {
        marginTop:'20px',
        marginLeft: "-88px",
        border: '1px solid #eef0f2',
        borderRadius: '6px',
        boxShadow: '0 4px 8px rgb(82 91 100 / 10%)',
        boxSizing: 'border-box',
        minWidth: '180px'
    },
    loading: {
        position:'relative',
        left:'50%',
        right:'50%',
        marginTop:'3%'
    },
    // customColumn2: {
    //     flexBasis:'26.5%', 
    //     maxWidth:'26.5%'
    // },
    // customColumn3: {
    //     flexBasis:'26.5%', 
    //     maxWidth:'26.5%'
    // },
    marginDropdown: {
        right: '2.4%',
        [theme.breakpoints.down(1400)] :{
            right: '2.6%'
        },
        [theme.breakpoints.down(1300)] :{
            right: '2.8%'
        }
    },
    marginDropdown1: {
        right: '2.6%',
        [theme.breakpoints.down(1400)] :{
            right: '2.8%'
        },
        [theme.breakpoints.down(1300)] :{
            right: '3%'
        }
    },
    // Responsive table columns
    customColumn1: { //Invoice Number Column
        flexBasis: '4%',
        maxWidth: '4%',
        [theme.breakpoints.down(1400)]: { // Adjust for screen sizes < 1400px
            flexBasis: '6%',
            maxWidth: '6%',
        },
        [theme.breakpoints.down(1200)]: { // Adjust for screen sizes < 1200px
            flexBasis: '8%',
            maxWidth: '8%',
        },
    },
    customColumn2: { // Status Column
        flexBasis: '9%',
        maxWidth: '9%',
        [theme.breakpoints.down(1400)]: {
            flexBasis: '12%',
            maxWidth: '12%',
        },
        [theme.breakpoints.down(1200)]: {
            flexBasis: '14%',
            maxWidth: '14%',
        },
    },
    // Apply ellipsis for long text in tables
    textEllipsis: {
        maxWidth: '210px !important',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        [theme.breakpoints.down(1500)]: {
            maxWidth: '140px !important',
        },
        [theme.breakpoints.down(1200)]: {
            maxWidth: '110px !important',
        },
    },
    textEllipsis: {
        width:'auto',
        maxWidth: '210px !important',
        whiteSpace:  'nowrap !important',
        overflow: 'hidden !important',
        textOverflow: 'ellipsis !important',
        [theme.breakpoints.down(1950)] : {
            maxWidth: '160px !important',
        },
        [theme.breakpoints.down(1500)] : {
            maxWidth: '140px !important',
        },
        [theme.breakpoints.down(1300)] : {
            maxWidth: '130px !important',
        }
    },
    textEllipsis1: {
        width:'auto',
        maxWidth: '200px !important',
        whiteSpace:  'nowrap !important',
        overflow: 'hidden !important',
        textOverflow: 'ellipsis !important',
        [theme.breakpoints.down(1950)] : {
            maxWidth: '170px !important',
        },
        [theme.breakpoints.down(1500)] : {
            maxWidth: '140px !important',
        },
        [theme.breakpoints.down(1300)] : {
            maxWidth: '110px !important',
        }
    },
    textEllipsis2: {
        maxWidth: '25ch !important',
        whiteSpace:  'nowrap !important',
        overflow: 'hidden !important',
        textOverflow: 'ellipsis !important',
        [theme.breakpoints.down(1500)] : {
            maxWidth: '23ch !important',
        },
        [theme.breakpoints.down(1300)] : {
            maxWidth: '19ch !important',
        }
    },
    actionsIcons: {
        fontSize: '18px',
    },
    stickyTopBar : {
        position:'sticky', 
        top:0, 
        zIndex:'999', 
        backgroundColor:theme.palette.primary.light,
        
    },
    proformaTDSColumn1: {
        flexBasis:'8% !important',
        maxWidth:'8% !important',
    },
    proformaTDSColumn2: {
        flexBasis: '9%',
        maxWidth: '9%',
    },
    proformaTDSColumn3: {
        maxWidth: '24%',
        flexWidth:'24%',
    },
    statusCellWidth: {
        width:'78px',
        [theme.breakpoints.down(1470)] : {
            width: '88px',
        }
    },
    buttonContainer: {
        display:'flex',
        justifyContent:'flex-start',
        position:'absolute', 
        right:'44px',
        [theme.breakpoints.down(1950)] : {
            right:'42px',
        },
        [theme.breakpoints.down(1450)] : {
            right:'41px',
        }
    },
    invoiceTypeStyle: {
        fontSize:'10px',
        borderRadius: '4px !important',
        backgroundColor: '#f9f9f9 !important',
        border: '1px solid #ececec !important',
        padding:'3px',
        marginRight:'3px'
    },
    invoiceNoCol: {
        width:'60px',
        [theme.breakpoints.down(1400)] : {
            width:'50px',
        },
        [theme.breakpoints.down(1300)] : {
            width:'40px',
        },
    },
    customHeaderLastColumn: {
        width: '150px',  // Set a fixed width to ensure the icons have enough space
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        gap: '10px',   // Add some space between the icons
        whiteSpace: 'nowrap',  // Prevent icons from wrapping
    },

}))

const updateInvoiceItem = (invoiceItem, updatedInvoiceObj) => {
    return {
        ...invoiceItem,
        client_id: updatedInvoiceObj.client.id,
        client_name: updatedInvoiceObj.client.name,
        entity_id: updatedInvoiceObj.entity.id,
        entity_name: updatedInvoiceObj.entity.name,
        status: updatedInvoiceObj.status,
        can_add_payment: updatedInvoiceObj.can_add_payment,
        can_cancel: updatedInvoiceObj.can_cancel,
        can_convert_to_draft: updatedInvoiceObj.can_convert_to_draft,
        can_edit: updatedInvoiceObj.can_edit,
        total: updatedInvoiceObj.total,
        subtotal: updatedInvoiceObj.subtotal,
        bill_status: updatedInvoiceObj.bill_status,
        invoice_description: updatedInvoiceObj.invoice_description,
        invoice_number: updatedInvoiceObj.invoice_number,
        issue_date: updatedInvoiceObj.issue_date,
        currency: updatedInvoiceObj.currencies,
        proforma_invoice_number: updatedInvoiceObj.proforma_invoice_number,
        recurring_invoice: updatedInvoiceObj.recurring_invoice,
        tds_amount: updatedInvoiceObj.tds_amount,
        due: updatedInvoiceObj.due,
        invoice_type: updatedInvoiceObj.invoice_type,
        recurring_invoice: updatedInvoiceObj.recurring_invoice,
        activity_status:updatedInvoiceObj?.activity_status

    };
};

const InvoiceListComponent =  (props) => {
    const classes = useStyles();
    const isMount = useIsMount();
    const {enqueueSnackbar} = useSnackbar();

    const [isActive, setIsActive] = useState(false);
    const [menuId, setMenuId] = useState('');
    //const [openDialog, setOpenDialog] = useState(false);
    const [invoicesList, setInvoicesList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
    const [total, setTotal] = useState();
    const [isMoreLoading, setIsMoreLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [id, setId] = useState(props.selectedAccount?.id);
    const [animationActive, setAnimationActive] = useState(false);
    const [items, setItems] = useState([]);
    const [apiLoading, setApiLoading] = useState(false);
    
    const { fullName, handleOpenAddPaymentDrawer, paymentInvoiceObj, setPaymentInvoiceObj, handleInvoiceDialogOpen,
        openInvoiceDialog, updatedInvoiceObj, setUpdatedInvoiceObj, setInvoiceComponentState, invoicesComponentState, 
        callgetInvoiceListApi, setCallGetInvoiceListApi} = usePropsContext();

    const { setScrollContainer, saveState, restoreState } = useRestoreComponentState(
        invoicesComponentState, 
        setInvoiceComponentState, 
    );
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [invoiceItem, setInvoiceItem] = useState(undefined);
    const [anchorEl, setAnchorEl] = useState(null);

    const enable_tds = props.selectedAccount?.enable_tds;


    const { access_level } = props.selectedAccount || {};
    const accessLevelViewer = (AccessLevel.getUserAccessLevelValue(access_level) === AccessLevel.VIEWER) ? true : false;
    const entityList = props.selectedAccount?.entities;
    const showTDS = props.selectedAccount?.enable_tds;
    const restoredState = restoreState();

    useEffect(() => {
        if(!props.fromClientDetails) {
            document.title = `Invoices - ${fullName}`;
        }
    }, [])

    useLayoutEffect(() => {
        
        if (Object.keys(restoredState).length > 0) {
            setInvoicesList(restoredState.invoicesList);
            setItems(restoredState.items);
            setPage(restoredState.page);
            setTotal(restoredState.total);
        } 
    }, [restoredState]);
    
    useEffect(() => {
        if(props.fromClientDetails && props.clientInfo && Object.keys(restoredState).length === 0) {
            getClientInvoices();
        } else {
            if(Object.keys(restoredState).length === 0) {
                getInvoiceList();
            }
        }
    }, [id]);

    useEffect(() => {
        setId(props.selectedAccount?.id)
    }, [props.selectedAccount?.id])

    useEffect(() => {
        if(!isMount) {
            if(id !== props.selectedAccount?.id) {
                setPage(1);
            }
        }   
    }, [props.selectedAccount?.id])



    useEffect(() => {
        if (!isMount) {
            if (isMoreLoading) {
                //consoleToLog('More Loading Useffect');
                consoleToLog('isMore loading api called')
                if(props.fromClientDetails && props.clientInfo) {
                    getClientInvoices();
                } else {
                    getInvoiceList();
                }
            }
        }
    }, [isMoreLoading]);

    useEffect(() => {
        if (!isMount) {
                consoleToLog('filter changed');
                getInvoiceList();
        }
    }, [props.issueStartDateFilter, props.issueEndDateFilter, props.invoiceClientsFilter, props.selectedStatusInvoiceFilter, 
        props.selectedTypeInvoiceFilter, props.selectedEntitiesFilter, props.invoiceSortByFilter, props.selectedActivityStatusInvoiceFilter]);

    useEffect(() => {
        if(!isMount) {
            if(paymentInvoiceObj) {
                updateInvoiceListItem()
            }

        }
    }, [paymentInvoiceObj]);

    // useEffect(() => {
    //     if(!isMount) {
    //         if(!props.openInvoiceDialog) {
    //             getInvoiceList(1);
    //         }

    //     }
    // }, [props.openInvoiceDialog]);

    useEffect(() => {
        if(!isMount) {
            if(updatedInvoiceObj && invoicesList && invoicesList.length > 0 && !callgetInvoiceListApi) {
                const invoiceObjPresent = invoicesList.find((invoiceItem) => invoiceItem.id === updatedInvoiceObj?.id);
                if(invoiceObjPresent) {
                    const updatedInvoicesArr = invoicesList.map((invoiceItem) => 
                        invoiceItem.id === updatedInvoiceObj?.id ?  updateInvoiceItem(invoiceItem, updatedInvoiceObj) : invoiceItem
                    );
                    setInvoicesList([...updatedInvoicesArr]);
                    setUpdatedInvoiceObj(undefined);
                } else {
                    //setInvoicesList(oldInvoices => [updatedInvoiceObj, ...oldInvoices]);
                    setUpdatedInvoiceObj(undefined);
                }
            }
        }
    }, [updatedInvoiceObj, invoicesList]);

    useEffect(() => {
        if(!isMount) {
            if(callgetInvoiceListApi) {
                pageReset();
                if(page === 1) {
                    if(props.fromClientDetails && props.clientInfo) {
                        getClientInvoices();
                    } else {
                        getInvoiceList();
                    }
                    setCallGetInvoiceListApi(false);
                }
            }
        }
    }, [callgetInvoiceListApi, page]);


    const pageReset = () => {
        setPage(1);
        // if(page === 1) {
        //     getInvoiceList();
        // }
    }

    let count = 0;
    if (props.invoiceClientsFilter.length > 0) {
        count = count + 1
    }

    let status_filter = props.selectedStatusInvoiceFilter.filter((status) => status === 'paid')
    if(props.selectedStatusInvoiceFilter.length < 4) {
        count = count + 1
    } 
    
    if(props.selectedTypeInvoiceFilter.length < 3) {
        count = count + 1
    }

    if (props.selectedEntitiesFilter.length > 0) {
        count = count + 1
    }

    if (props.selectedActivityStatusInvoiceFilter && props.selectedActivityStatusInvoiceFilter.length < 4) {
        count = count + 1
    }

    const clearRedux = () => {
        props.logout();
        props.setClearInvoiceAccounts();
        props.clearSelectedInvoiceFilters();
        props.setSelectedDrawerState(SelectedDrawerState.SHOW_DASHBOARD);
    }

    const getInvoiceList = (pageNo) => {
        const invoice_account_id = props.selectedAccount?.id;
        const issue_start_date = props.issueStartDateFilter; 
        const issue_end_date = props.issueEndDateFilter; 
        const clients = props.invoiceClientsFilter?.map((client) => client.id)?.join(); 
        const status = props.selectedStatusInvoiceFilter?.map((status) => status)?.join();
        const type = props.selectedTypeInvoiceFilter.length > 0 ? props.selectedTypeInvoiceFilter?.map((type) => type)?.join() : undefined;
        const entities = props.selectedEntitiesFilter && props.selectedEntitiesFilter.length > 0 ? props.selectedEntitiesFilter.map((entity) => entity.id).join() : undefined;
        const order_by = props.invoiceSortByFilter;
        const activity_status = props.selectedActivityStatusInvoiceFilter?.map((status) => status)?.join();

        if(!isMoreLoading) setLoading(true);
        getInvoiceListApi(invoice_account_id, pageNo ? pageNo : page, issue_start_date, issue_end_date, clients, status, type, entities, order_by, activity_status)
        .then((response) => {
            const res = response.data;
            setLoading(false);
            consoleToLog('Respone getInvoiceListApi', res);
            setItems(res.invoices);

            if(isMoreLoading) {
                const newItems = invoicesList.concat(res.invoices);
                setInvoicesList(newItems);
                setPage(page + 1);
                setIsMoreLoading(!isMoreLoading);
            } else {
                if(res.invoices.length === res.page_size && id === props.selectedAccount?.id) setPage(page + 1);
                setInvoicesList(res.invoices);
                setTotal(res.total);
            }
        })
        .catch((e) => {
            if(e.response && e.response.status === 401 && e.response.data && e.response.data.detail) {
                clearRedux();
                enqueueSnackbar( e.response.data.detail, {variant:'error'});
            }
            consoleToLog('Error getInvoiceListApi', e);
            setLoading(false);
            if (e.response && e.response.status !== 401 && e.response.data && e.response.data.message) {
                enqueueSnackbar( e.response.data.message, {variant:'error'});
                return false;
            }
        });
    }

    const getClientInvoices = () => {
        const invoice_account_id = props.selectedAccount?.id;
        const client_id = props.clientInfo?.id;

        setLoading(true);
        getClientInvoicesApi(invoice_account_id, client_id, page)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response getClientInvoicesApi: ', res);
                setLoading(false);

                setItems(res.invoices);

                if(isMoreLoading) {
                    const newItems = invoicesList.concat(res.invoices)
                    setInvoicesList(newItems);
                    setPage(page + 1);
                    setIsMoreLoading(!isMoreLoading);
                } else {
                    if(res.invoices.length === res.page_size && id === props.selectedAccount?.id) setPage(page + 1);
                    setInvoicesList(res.invoices);
                    setTotal(res.total);
                }


            })
            .catch((e) => {
                consoleToLog('Error getClientInvoicesApi: ', e);
                setLoading(false);
                if(e.respose.data && e.response.data.message) {
                    return;
                }
            })
    }

    const storeInvoiceState = (fromEdit, invoice, showSendEmail) => {
        const stateObj = {
            invoicesList,
            items,
            page,
            total,
            ...(props.fromClientDetails && {
                clientListState: {...props.clientComponentObj},
                clientInfo: {...props.showClientInfo},
                tabValue: props.tabValue,
                clientScrollPosition: props.scrollContainer?.scrollTop
            }),
        };
        saveState(stateObj);
        fromEdit ? history.push(`/invoice/${invoice.slug}/edit`, {invoice}) : 
        history.push(`/invoice/${invoice.slug}/preview`, {showSendEmail: showSendEmail, invoice});
    }

    const onEditInvoiceClick = (invoice) => {
        trackGAEvent(props.selectedAccount.name, 'Invoice list Item - Edit Invoice clicked', `${props.user.firstname} ${props.user.lastname}`);
        storeInvoiceState(true, invoice, false);
        handleInvoiceDialogOpen(true);
        closeOptionsMenu();
    }

    const onPreviewInvoiceClick = (e, invoice) => {
        e.stopPropagation();
        trackGAEvent(props.selectedAccount.name, 'Invoice list Item - Preview button clicked', `${props.user.firstname} ${props.user.lastname}`);
        storeInvoiceState(false, invoice, false);
        handleInvoiceDialogOpen(true);
        closeOptionsMenu();

    }

    const onSendInvoiceClick = (e, invoice) => {
        e.stopPropagation();
        trackGAEvent(props.selectedAccount.name, 'Send button clicked', `${props.user.firstname} ${props.user.lastname}`);
        storeInvoiceState(false, invoice, true, invoice.invoice_type);
        handleInvoiceDialogOpen(true);
        closeOptionsMenu();

    }

    const onSendProformaInvoiceClick = (e, invoice) => {
        e.stopPropagation();
        trackGAEvent(props.selectedAccount.name, 'Invoice list Item - Send Proforma Invoice button clicked', `${props.user.firstname} ${props.user.lastname}`);
        storeInvoiceState(false, invoice, true, SendEmailType.PROFORMA);
        handleInvoiceDialogOpen(true);
        closeOptionsMenu();

    }

    const onConvertToTaxInvoiceClick = (invoice) => {
        const invoice_account_id = props.selectedAccount?.id;
        const invoice_id = invoice.id;
        const entity_id = invoice?.entity_id;
        const client_id = invoice?.client_id;
        closeOptionsMenu();

        convertToTaxApi(invoice_account_id, entity_id, client_id, invoice_id)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response convertToTaxApi: ', res);
                enqueueSnackbar('Invoice Converted to Tax', {variant:"success"});

                let updated_array = invoicesList.map((invoice) => {
                    if(invoice.id === res.id) {
                        return invoice = res;
                    };
                    return invoice;
                });
        
                setInvoicesList(updated_array);

                
            })
            .catch((e) => {
                consoleToLog('Error convertToTaxApi: ', e);
                if(e.response.data && e.response.data.message){
                    enqueueSnackbar(e.response.data.message, {variant:"error"});
                    return;
                }
            })
    }

    const handleOpenFilterDrawer = () => {
        setOpenFilterDrawer(true);
    }

    const handleCloseFilterDrawer = () => {
        setOpenFilterDrawer(false);
    }

    const closeOptionsMenu = () => {
        setIsActive(false);
        setMenuId('');
    }

    const onDownloadInvoiceClick = (invoice) => {
        const invoice_account_id = props.selectedAccount?.id;
        const invoice_id = invoice.id;
        const entity_id = invoice?.entity_id;
        const client_id = invoice?.client_id;

        loggedInUserDownloadApi(invoice_account_id, entity_id, client_id, invoice_id)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response loggedInUserDownloadApi: ', res);

                const downloadableURL = res.document_signed_url;

                //initiate download
                document.getElementById('invoice_download').href = downloadableURL;
                document.getElementById('invoice_download').click();
                closeOptionsMenu();

                trackGAEvent(props.selectedAccount.name, 'Invoice list Item - Invoice downloaded', `${props.user.firstname} ${props.user.lastname}`);

            })
            .catch((e) => {
                consoleToLog('Error loggedInUserDownloadApi: ', e.response);
                closeOptionsMenu();
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant:'error'});
                    return;
                }
            })
    }

    const loadMoreLogic = () => {
        return (
            <Grid item container>
                <Grid item lg={12}>
                {items.length === 20 && !isMoreLoading &&
                    <div style={{textAlign: "center", margin:'16px 0px'}}>
                        <Button type="button"
                            color="secondary"
                            variant="contained"
                            onClick={() => {
                                setIsMoreLoading(!isMoreLoading);
                            }}>Load More
                        </Button>
                    </div>}

                { isMoreLoading &&
                    <CircularProgress size={35} style={{position:'relative', left:'50%', right:'50%', margin:'16px 0px'}}/>
                }
                </Grid>
            </Grid>
        )
    }

    const updateInvoiceListItem = () => {
        let updated_array = invoicesList.map((invoice) => {
            if(invoice.id === paymentInvoiceObj.invoice.id) {
                let obj = paymentInvoiceObj.invoice;
                return {
                    ...invoice,
                    paid: obj.paid,
                    status: obj.status,
                    due: obj.due
                }
            } else 
            return invoice;
        });

        setInvoicesList(updated_array);
        setPaymentInvoiceObj(undefined);
    }

    const onDeleteInvoiceClick = (e, invoice) => {
        e.stopPropagation();
        
    }

    const callDeleteInvoiceApi = (invoice_account_id, invoice_id) => {
        setAnimationActive(true);
        closeOptionsMenu();
        setApiLoading(true);

        deleteInvoiceApi(invoice_account_id, invoiceItem?.entity_id, invoiceItem?.client_id, invoice_id)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response deleteInvoiceApi: ', res);
                enqueueSnackbar('Invoice deleted successfully!!', {variant: 'success'});

                const updated_array = invoicesList.filter((invoice) => invoice.id !== invoice_id);
                setInvoicesList(updated_array);
                setTotal(total - 1);
                setAnimationActive(false);
                setApiLoading(false);

                trackGAEvent(props.selectedAccount.name, 'Invoice deleted', `${props.user.firstname} ${props.user.lastname}`);

                handleConfirmDialogClose();
            })
            .catch((e) => {
                consoleToLog('Error loggedInUserDownloadApi: ', e.response);
                setAnimationActive(false);
                setApiLoading(false);
                closeOptionsMenu();
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant:'error'});
                    return;
                }
            })
    }

    const createDuplicateInvoice = (invoice) => {
        const invoice_account_id = props.selectedAccount?.id;
        const invoice_id = invoice.id;
        const entity_id = invoice?.entity_id;
        const client_id = invoice?.client_id;

        closeOptionsMenu();
        createDuplicateInvoiceApi(invoice_account_id, entity_id, client_id, invoice_id)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response createDuplicateInvoiceApi: ', res);
                enqueueSnackbar('Invoice duplicated successfully', {variant:'success'});

                setInvoicesList([res, ...invoicesList]);
            })
            .catch((e) => {
                consoleToLog('Error createDuplicateInvoiceApi: ', e.response);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant:'error'});
                    return;
                }
            })
    }

    const onExportInvoicesClick = () => {
        const invoice_account_id = props.selectedAccount?.id;
        const issue_start_date = props.issueStartDateFilter; 
        const issue_end_date = props.issueEndDateFilter; 
        const clients = props.invoiceClientsFilter?.map((client) => client.id)?.join(); 
        const entities = props.selectedEntitiesFilter && props.selectedEntitiesFilter.length > 0 ? props.selectedEntitiesFilter.map((entity) => entity.id).join() : undefined;
        const type = props.selectedTypeInvoiceFilter.length > 0 ? props.selectedTypeInvoiceFilter?.map((type) => type)?.join() : undefined;
        const status = props.selectedStatusInvoiceFilter?.map((status) => status)?.join();

        props.setApiRoute('setup_export');
        exportInvoicesApi(invoice_account_id, issue_start_date, issue_end_date, clients, entities, showTDS, type, status)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response exportInvoicesApi: ', res);

                props.setOverlayView(OverlayView.SHOW_OVERLAY_LOADING);
                props.setOverlayToken(res.task_id);
                props.setOvelayMessage(OverlayMessage.EXPORT_MSG);
            })
            .catch((e) => {
                consoleToLog('Error exportInvoicesApi: ', e);
                props.setOverlayView(OverlayView.NO_VIEW);
                props.setOverlayToken("");
                if(e.response.data && e.response.data.message){
                    enqueueSnackbar(e.response.data.message, {variant:"error"});
                    return;
                }
            })
    }

    const updateInvoiceObjInArray = (res) => {
        const updatedArray = invoicesList.map((invoice) => {
            if(invoice.id === res.id) {
                return {
                    ...invoice,
                    status: res.status,
                    invoice_type: res.invoice_type,
                    can_cancel: res.can_cancel,
                    can_add_payment: res.can_add_payment,
                    can_convert_to_draft: res.can_convert_to_draft,
                    can_edit: res.can_edit,
                    can_reopen: res.can_reopen,
                    recurring_invoice: res.recurring_invoice
                }
            }
            return invoice
        })
        setInvoicesList([...updatedArray]);
    }

    const onCancelInvoiceClick = (e, invoice) => {
        e.stopPropagation();
        closeOptionsMenu();

        const invoice_account_id = props.selectedAccount?.id;
        const invoice_id = invoice.id;
        const cancel_recurring_invoice = false;
        const recurring_invoice_id = undefined;
        callCancelInvoiceApi(invoice_account_id, invoice_id, cancel_recurring_invoice, recurring_invoice_id);

    }

    const callCancelInvoiceApi = async (invoice_account_id, invoice_id, cancel_recurring_invoice, recurring_invoice_id) => {

        setApiLoading(true);

        try {
            const response = await cancelInvoiceApi(invoice_account_id, invoiceItem?.entity_id, invoiceItem?.client_id, invoice_id, cancel_recurring_invoice, recurring_invoice_id);
            
            consoleToLog("***Response cancelInvoiceApi: ", response.data);
            const res = response.data;
            updateInvoiceObjInArray(res);
            
            enqueueSnackbar('Invoice updated successfully', { variant: 'success'});
            setApiLoading(false);

            handleConfirmDialogClose();
            
        } catch (e) {
            consoleToLog("Err: cancelInvoiceApi", e);
            setApiLoading(false);
            if (e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, { variant: 'error' });
            }
        }
    }


    const convertToDraftInvoice = async (invoice_account_id, invoice_id) => {

        setApiLoading(true);

        try {
            const response = await convertTODraftApi(invoice_account_id, invoiceItem?.entity_id, invoiceItem?.client_id, invoice_id);
            
            consoleToLog("***Response convertTODraftApi: ", response.data);
            const res = response.data;
            updateInvoiceObjInArray(res);
            enqueueSnackbar('Invoice updated successfully', { variant: 'success'});
            setApiLoading(false);

            handleConfirmDialogClose();
            
        } catch (e) {
            consoleToLog("Err: convertTODraftApi", e);
            setApiLoading(false);
            if (e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, { variant: 'error' });
            }
        }

    }

    const handleConfirmDialogOpen = (invoice, obj) => {
        setOpenConfirmDialog(true);

        let invoice_obj = {
            ...invoice,
            ...obj
        }
        setInvoiceItem(invoice_obj);
        closeOptionsMenu();
    }

    const handleConfirmDialogClose = () => {
        setOpenConfirmDialog(false);
        setInvoiceItem(undefined);
    }

    const handleSortMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleSortMenuClose = (event) => {
        setAnchorEl(null);
    };

    const selectSortValue = (value) => {
        trackGAEvent(props.selectedAccount.name, 'Invoices Sort', `${props.user.firstname} ${props.user.lastname} `);
        props.setInvoiceSortByFilter(value);
        pageReset();
        setAnchorEl(null);
    } 

    return (
        <div className={classes.mainContent}
            ref={(node) => setScrollContainer(node)}
            id='invoices-container'
            style={{
                height: '100%',
                backgroundColor: props.fromClientDetails ? '#ffffff' : '#f7f7f7'}}>

            <Container className={classes.container} 
                maxWidth="xl" style={{padding: "0px 16px"}}>

                {loading 
                ?
                <Grid item container justifyContent='center' style={{marginTop:'32px'}}> 
                    <CircularProgress size={25} />
                </Grid>
                :
                <Paper variant='outlined' elevation={0} style={{marginTop:'10px', marginBottom:'24px', width:'100%'}}>
                    <TableContainer style={{height:'100%', overflow:'visible'}}>
                        <Table>
                        <TableHead className='table_header_style'>
                            <TableRow>
                                <TableCell className={`tableheading_style ${classes.invoiceNoCol}`} align='center'>#</TableCell>
                                <TableCell className='tableheading_style'>Status</TableCell>
                                <TableCell className='tableheading_style'>Activity</TableCell>
                                <TableCell className='tableheading_style'>Issued</TableCell>
                                {entityList && entityList.length > 1 && <TableCell className='tableheading_style'>Entity</TableCell>}
                                <TableCell className='tableheading_style'>Client</TableCell>
                                <TableCell className='tableheading_style'>Description</TableCell>
                                <TableCell className='tableheading_style'>Total</TableCell>
                                <TableCell className='tableheading_style'>Due</TableCell>
                                {!props.fromClientDetails &&
                                <TableCell colSpan={2} className='tableheading_style'>
                                    <div style={{display:'flex', alignItems:'center'}}> 

                                        <div className='table_header_icon_column'>
                                            <Tooltip title="Filters" arrow>
                                                <Button
                                                    onClick={handleOpenFilterDrawer}  
                                                    className={classes.icons}
                                                    >
                                                    {<img src='/images/filter_icon.svg' 
                                                    className={(count && count > 0) ? `filter_icon active` : 'filter_icon'}/>}
                                                    {/* <FilterListOutlinedIcon className={`table_header_icons ${count && count > 0 && 'filter_acitve'}`}/> */}
                                                    {   count && count > 0 ?
                                                        <div className='filter__dot'></div> : <div></div>
                                                    }
                                                </Button>
                                            </Tooltip>
                                        </div>

                                        <div className='table_header_icon_column'>
                                            <Tooltip title="Export" arrow>
                                                <Button
                                                    onClick={onExportInvoicesClick}  
                                                    className={classes.icons}
                                                    >
                                                    {/* <i className={`flaticon-export`}></i> */}
                                                    <CloudUploadOutlinedIcon className='table_header_icons'/>
                                                </Button>
                                            </Tooltip>
                                        </div>

                                        <div className='table_header_icon_column'>
                                            <Tooltip title="Sort" arrow>
                                                <Button aria-label="sort" 
                                                    onClick={handleSortMenuClick} 
                                                    className={classes.icons}>
                                                    {/* <i className="flaticon-sort-1"></i> */}
                                                    <SwapVertOutlinedIcon className='table_header_icons'/>
                                                </Button>
                                            </Tooltip>

                                            <Popover
                                                id="sort-menu"
                                                anchorEl={anchorEl}
                                                keepMounted
                                                open={Boolean(anchorEl)}
                                                onClose={handleSortMenuClose}
                                                style={{marginTop:'20px'}}
                                            >
                                                <MenuItem 
                                                    selected={props.invoiceSortByFilter === 'created_at'}
                                                    value="created_at"
                                                    onClick={(e) => selectSortValue("created_at")}>Latest First</MenuItem>
                                                <MenuItem 
                                                    selected={props.invoiceSortByFilter === 'issue_date'}
                                                    value="issue_date"
                                                    onClick={(e) => selectSortValue("issue_date")}>Issue Date</MenuItem>
                                                <MenuItem
                                                    selected={props.invoiceSortByFilter === 'invoice_number'}
                                                    value="invoice_number"
                                                    onClick={(e) => selectSortValue("invoice_number")}>Invoice Number</MenuItem>
                                            </Popover>
                                        </div>

                                    </div>
                                </TableCell>}
                            </TableRow>
                        </TableHead>
                        {loading ?
                        <TableBody>
                            <TableRow hover className={classes.invoiceListHover}>
                                <TableCell colSpan={entityList && entityList.length > 1 ? 9 : 8} align='center'>
                                    <CircularProgress size={25} style={{margin:'24px 0px'}}/>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                        :
                        <TableBody>
                            {
                            invoicesList && invoicesList.length > 0 ? invoicesList.map((invoice) => {
                                let over_due = (moment(invoice?.due_date).toDate().getTime() < Date.now())
                                let recurr_invoice_status = invoice.recurring_invoice?.status === 'active';
                                const currencyObj = invoice?.currency;
                                return (
                                    <TableRow hover key={invoice.id} className={classes.invoiceListHover}
                                        onClick={(e) => onPreviewInvoiceClick(e, invoice)}>
                                        <TableCell component="th" scope="row" className={`tablebody_style ${classes.invoiceNoCol}`}>
                                            {<span className={classes.invoiceTypeStyle}>
                                                {displayInvoiceTypeLabel(invoice.invoice_type)}
                                            </span>} 
                                            <span style={{fontSize:'13px', position:'relative', top:'1px'}}>
                                                {invoice.invoice_type !== InoviceTypes.TAX ? invoice.proforma_invoice_number : invoice.invoice_number}
                                            </span>
                                        </TableCell>
                                        <TableCell className={`tablebody_style ${classes.statusCellWidth}`}>
                                            <Grid item container alignItems='center'>
                                                <div className='status_style'
                                                    style={{
                                                        background: statusColor(invoice.status.toLowerCase())
                                                        }}>
                                                </div>
                                                <Typography 
                                                    style={{
                                                    color:statusColor(invoice.status.toLowerCase()), 
                                                    fontSize: "10px"
                                                    
                                                }}>
                                                    {invoice.status.charAt(0).toUpperCase() + invoice.status.slice(1).toLowerCase()}
                                                    {recurr_invoice_status &&
                                                        <Typography style={{
                                                            color:'#007bfb', 
                                                            fontSize: "8px"
                                                        }}>
                                                            Recurring
                                                        </Typography>
                                                    } 
                                                </Typography>
                                            </Grid>
                                        </TableCell>
                                        <TableCell className={`tablebody_style`} style={{width:'18px'}}>
                                            <div className='table_activity_icon_container'>
                                                <Tooltip title={statusNameAndColor(invoice?.activity_status)?.name} arrow>
                                                    <img src={showActivityIcons(invoice?.activity_status)} className='table_activity_icons'/>
                                                </Tooltip>
                                            </div>
                                        </TableCell>
                                        <TableCell className='tablebody_style'>
                                            <Tooltip title={moment(invoice.issue_date, 'YYYY-MM-DD').format('MMM Do, YY')} arrow>
                                                <Typography variant='subtitle1' className='issue_date_text_ellipsis'>
                                                    {/* {showListItemDate(invoice.issue_date)} */}
                                                    {moment(invoice.issue_date, 'YYYY-MM-DD').format('MMM Do, YY')}
                                                </Typography>
                                            </Tooltip>
                                        </TableCell>
                                        {entityList && entityList.length > 1 && 
                                            <TableCell className='tablebody_style'>
                                                <Tooltip title={invoice.entity_name} arrow>
                                                    <Typography variant="subtitle1" 
                                                        className={'text_ellipsis'}>
                                                        {invoice.entity_name}
                                                    </Typography>
                                                </Tooltip>
                                            </TableCell>
                                        }
                                        <TableCell className='tablebody_style'>
                                            <Tooltip title={invoice.client_name} arrow>
                                                <Typography variant="subtitle1" 
                                                    className={'text_ellipsis1'}>
                                                        {invoice.client_name}
                                                </Typography>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell className='tablebody_style'>
                                            <Tooltip title={invoice.invoice_description} arrow>
                                                <Typography variant="subtitle1" 
                                                    className={classes.textEllipsis1}>
                                                        {invoice.invoice_description}
                                                </Typography>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell className='tablebody_style'>
                                            {formatAmount(invoice.total, currencyObj)}
                                        </TableCell>
                                        <TableCell className='tablebody_style'>
                                            <Typography variant='subtitle1'
                                                style={{color: (parseInt(invoice.due) > 0 && over_due && 
                                                invoice.status !==  InvoiceStatus.PAID) && 'red'}}>
                                                {formatAmount(invoice.due, currencyObj)}
                                            </Typography>
                                        </TableCell>
                                        {!props.fromClientDetails &&
                                        <TableCell className='tablebody_style'>
                                        {!accessLevelViewer &&
                                            <Grid item
                                                    style={{display:'flex',
                                                    alignItems:'center',
                                                    flexDirection:'row-reverse',
                                                    justifyContent:'center',
                                                    position:'relative'}}>
                                                <div style={{position:'absolute', right:'0px'}}>        
                                                    <Tooltip title="More Actions" arrow>  
                                                        <i className="flaticon-more action__icon"
                                                        onClick={(e) => {
                                                                e.stopPropagation();
                                                                setIsActive(!isActive);
                                                                setMenuId(invoice.id);
                                                            }}
                                                        style={{fontSize:'15px', position:'relative', left:'-16px'}}></i>
                                                    </Tooltip>
                                                <>    
                                                {invoice.id === menuId &&
                                                <ClickAwayListener onClickAway={() => {
                                                        setIsActive(false);
                                                        setMenuId('');
                                                }}>
                                                <Grid item className={`menu ${isActive ? `active ${classes.marginDropdown}` : "inactive"}`}>
                                                    <ul>

                                                        {
                                                            invoice.invoice_type === SendEmailType.PROFORMA && !isInvoiceStatusCancelled(invoice.status) &&
                                                            <li onClick={(e) => {
                                                                e.stopPropagation();
                                                                onConvertToTaxInvoiceClick(invoice);
                                                            }}>
                                                                <a style={{display:'flex', alignItems:'center'}}>
                                                                    <ReceiptIcon fontSize='small'  
                                                                    className={`more_actionsIcons ${classes.actionsIcons}`}/>
                                                                    Convert To Tax Invoice
                                                                </a>
                                                            </li>
                                                        }

                                                        {invoice.can_edit &&
                                                            <li onClick={(e) => {
                                                                e.stopPropagation();
                                                                onEditInvoiceClick(invoice)
                                                            }}>
                                                                <a style={{display:'flex', alignItems:'center'}}>
                                                                    <EditOutlinedIcon fontSize='small'  
                                                                    className={`more_actionsIcons ${classes.actionsIcons}`}/>
                                                                    Edit
                                                                </a>
                                                            </li>
                                                        }

                                                        {!isInvoiceStatusCancelled(invoice.status) &&
                                                            <li onClick={(e) => {
                                                                e.stopPropagation();
                                                                createDuplicateInvoice(invoice)
                                                            }}>
                                                                <a style={{display:'flex', alignItems:'center'}}>
                                                                    <FilterNoneIcon fontSize='small' style={{fontSize:'14px'}} 
                                                                    className={`more_actionsIcons ${classes.actionsIcons}`}/>
                                                                    Duplicate
                                                                </a>
                                                            </li>
                                                        }

                                                        {!isInvoiceStatusCancelled(invoice.status) &&
                                                        <li onClick={(e) => {
                                                            e.stopPropagation();
                                                            onDownloadInvoiceClick(invoice);
                                                        }}>
                                                            <a style={{display:'flex', alignItems:'center'}}>
                                                                <CloudDownloadOutlinedIcon fontSize='small'  
                                                                className={`more_actionsIcons ${classes.actionsIcons} icon_position_top`}/>
                                                                Download
                                                                <Link
                                                                    style={{ display: "none" }}
                                                                    id="invoice_download"
                                                                    target="_blank"
                                                                    onClick={(e) => e.stopPropagation()}
                                                                    download
                                                                ></Link>
                                                            </a>
                                                        </li>}

                                                        {((invoice.status !== InvoiceStatus.PAID && invoice.due !== 0  && !isInvoiceStatusCancelled(invoice.status)))  &&  
                                                        <li onClick={(e) => onPreviewInvoiceClick(e, invoice)}>
                                                            <a style={{display:'flex', alignItems:'center'}}>
                                                                <VisibilityOutlinedIcon fontSize='small'  
                                                                className={`more_actionsIcons ${classes.actionsIcons}`}/>
                                                                Preview
                                                            </a>
                                                        </li>}

                                                        {invoice?.can_cancel &&
                                                        <li onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleConfirmDialogOpen(invoice, {cancel_invoice_clicked: true});
                                                        }}>
                                                            <a style={{display:'flex', alignItems:'center'}}>
                                                                <CancelOutlinedIcon fontSize='small'  
                                                                className={`more_actionsIcons ${classes.actionsIcons} icon_position_top`}/>
                                                                Cancel
                                                            </a>
                                                        </li>}

                                                        <li onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleConfirmDialogOpen(invoice,  {delete_invoice_clicked: true})
                                                            }}>
                                                            <a href="#" style={{display:'flex', alignItems:'center'}}>
                                                                <DeleteOutlinedIcon fontSize='small' 
                                                                className={`more_actionsIcons ${classes.actionsIcons} icon_position_top`}/>
                                                                Delete
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </Grid>
                                                </ClickAwayListener>
                                                }
                                                </>
                                                </div>

                                                {<div className={classes.buttonContainer}>
                                                    {invoice.can_add_payment
                                                    && <Button item className="more_actionButton" 
                                                            onClick={(e)=> handleOpenAddPaymentDrawer(e, invoice)}>
                                                            <MonetizationOnOutlinedIcon  
                                                                style={{fontSize:'17px', color:'green'}} 
                                                                className='more_actionsIcons'/>
                                                            <span> Add Payment </span>
                                                    </Button>}

                                                    {(invoice.status !== InvoiceStatus.PAID && invoice.activity_status ==='sent' &&  !isInvoiceStatusCancelled(invoice.status)) && 
                                                    <Button item className="more_actionButton"
                                                        onClick={(e) => {
                                                            trackGAEvent(props.selectedAccount.name, 'Invoice list Item - Send Reminder button clicked', `${props.user.firstname} ${props.user.lastname}`);
                                                            onSendInvoiceClick(e, invoice);
                                                            }}> 
                                                            <SendIcon 
                                                            className='more_actionsIcons'
                                                            style={{fontSize:'15px', verticalAlign:'baseline'}}   
                                                            />
                                                        <span> Send Reminder </span>
                                                        
                                                    </Button>} 

                                                    {(invoice.status === InvoiceStatus.PAID ||  isInvoiceStatusCancelled(invoice.status)) &&
                                                        <Button item className="more_actionButton1"
                                                            onClick={(e) => onPreviewInvoiceClick(e, invoice)}> 
                                                                <VisibilityOutlinedIcon fontSize='small'  
                                                                className={`more_actionsIcons ${classes.actionsIcons}`}/> 
                                                            <span> Preview </span>
                                                        </Button>
                                                    }

                                                    {invoice.can_convert_to_draft &&
                                                        <Button item className="more_actionButton"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleConfirmDialogOpen(invoice, {convert_to_draft_clicked: true});
                                                            }}> 
                                                                <TransformIcon fontSize='small'  
                                                                className={`more_actionsIcons ${classes.actionsIcons}`}/> 
                                                            <span> Convert to Open </span>
                                                        </Button>
                                                    }

                                                    {/* {isInvoiceStatusCancelled(invoice.status) &&
                                                        <Button item className="more_actionButton1"
                                                            onClick={(e) => onDeleteInvoiceClick(e, invoice)}> 
                                                            <DeleteOutlinedIcon fontSize='small' 
                                                                className={`more_actionsIcons ${classes.actionsIcons}`}/>
                                                            <span> Delete </span>
                                                        </Button>
                                                    } */}

                                                    {(invoice.status !== InvoiceStatus.PAID && invoice.activity_status ==='none' && invoice.invoice_type !== InoviceTypes.NONE 
                                                        && !isInvoiceStatusCancelled(invoice.status)) && 
                                                    <Button item className="more_actionButton1"
                                                        onClick={(e) => {
                                                            trackGAEvent(props.selectedAccount.name, 'Invoice list Item - Send button clicked', `${props.user.firstname} ${props.user.lastname}`);
                                                            onSendInvoiceClick(e, invoice)
                                                            
                                                            }}> 
                                                            <SendIcon 
                                                            className='more_actionsIcons'
                                                            style={{fontSize:'15px', verticalAlign:'baseline'}}   
                                                            />
                                                        <span> Send </span>
                                                    </Button>
                                                    }

                                                    {(invoice.invoice_type === InoviceTypes.NONE && !isInvoiceStatusCancelled(invoice.status)) &&
                                                        <Button item className="more_actionButton2"
                                                            style={{width:"210px", marginTop:'-1px'}}
                                                            onClick={(e) => onSendProformaInvoiceClick(e, invoice)}> 
                                                                <SendOutlinedIcon fontSize='small'  
                                                                className={`more_actionsIcons ${classes.actionsIcons}`}/> 
                                                            <span> Send Proforma Invoice </span>
                                                        </Button>
                                                    }

                                                    {(invoice.invoice_type === InoviceTypes.NONE && !isInvoiceStatusCancelled(invoice.status)) &&
                                                    <Button item className="more_actionButton2"
                                                        onClick={(e) => {
                                                            trackGAEvent(props.selectedAccount.name, 'Invoice list Item - Send Tax Invoice button clicked', `${props.user.firstname} ${props.user.lastname}`);
                                                            onSendInvoiceClick(e, invoice)
                                                            }}> 
                                                            <SendIcon style={{marginTop:'-1px'}}
                                                                className={`more_actionsIcons ${classes.actionsIcons}`}
                                                            />
                                                        <span> Send Tax Invoice </span>
                                                    </Button>
                                                    }
                                                </div> }
                                            </Grid>  }
                                        </TableCell>}
                                    </TableRow>
                                );
                            })
                            :
                            <TableRow hover className={classes.invoiceListHover}>
                                <TableCell colSpan={entityList && entityList.length > 1 ? 9 : 8} align='center'>
                                    No Invoices Found
                                </TableCell>
                            </TableRow>
                            
                            }
                        </TableBody>
                        }
                        </Table>
                    </TableContainer>
                    {!loading && loadMoreLogic()}
                </Paper>
                }

                {/* { openDialog && 
                    <InvoiceModalComponent openDialog={openDialog}
                        handleClickDialogClose={handleClickDialogClose}/>
                } */}
                
                {/* { openAddPaymentDrawer && <AddEditPaymentDrawer openAddPaymentDrawer={openAddPaymentDrawer}
                    handleCloseAddPaymentDrawer={handleCloseAddPaymentDrawer}
                    invoiceObj={invoiceObj}
                    fromInvoiceListPage={true}
                    updateInvoiceListItem={updateInvoiceListItem}
                />} */}

                <FilterDrawer openFilterDrawer={openFilterDrawer}
                    handleCloseFilterDrawer={handleCloseFilterDrawer}
                    pageReset={pageReset}
                />

                {
                    openConfirmDialog && <ShowConfirmDialog openConfirmDialog={openConfirmDialog}
                                            handleConfirmDialogClose={handleConfirmDialogClose}
                                            fromInvoiceListComponent={true}
                                            invoiceItem={invoiceItem}
                                            callCancelInvoiceApi={callCancelInvoiceApi}
                                            convertToDraftInvoice={convertToDraftInvoice}
                                            apiLoading={apiLoading}
                                            callDeleteInvoiceApi={callDeleteInvoiceApi}
                                        />
                }
            </Container>
        </div>
    );
}

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
    issueStartDateFilter: state.invoiceFilters.issueStartDateFilter,
    issueEndDateFilter: state.invoiceFilters.issueEndDateFilter,
    invoiceClientsFilter: state.invoiceFilters.invoiceClientsFilter,
    selectedStatusInvoiceFilter: state.invoiceFilters.selectedStatusInvoiceFilter,
    selectedTypeInvoiceFilter: state.invoiceFilters.selectedTypeInvoiceFilter,
    user: state.invoiceAccounts.user,
    selectedEntitiesFilter: state.invoiceFilters.selectedEntitiesFilter,
    drawerState: state.drawerState.setDrawerState,

    invoiceSortByFilter: state.invoiceFilters.invoiceSortByFilter,
    selectedActivityStatusInvoiceFilter: state.invoiceFilters.selectedActivityStatusInvoiceFilter
});

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logout()),
    clearSelectedInvoiceFilters: () => dispatch(clearSelectedInvoiceFilters()),
    setSelectedDrawerState: (drawerState) => dispatch((setSelectedDrawerState(drawerState))),
    setClearInvoiceAccounts: () => dispatch((setClearInvoiceAccounts())),
    setInvoiceSortByFilter: (sortBy) => dispatch(setInvoiceSortByFilter(sortBy)),

    setOverlayView: (exportView) => dispatch(setOverlayView(exportView)),
    setOverlayToken: (randomToken) => dispatch(setOverlayToken(randomToken)),
    setOvelayMessage: (overlayMessage) => dispatch(setOvelayMessage(overlayMessage)),
    setApiRoute: (route) => dispatch(setApiRoute(route))
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceListComponent);