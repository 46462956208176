import React, {useState, useEffect} from 'react';
import { Button, Checkbox, CircularProgress, FormControlLabel, MenuItem, makeStyles } from '@material-ui/core';
import {Grid, Typography, Dialog, Avatar, Slide, TextField, InputAdornment, Tooltip, Divider, ClickAwayListener} from '@material-ui/core';
import { connect } from 'react-redux';
import {getBillStatusApi, getClientListApi, uploadAttachmentAndCreateBill} from '../../services/authService';
import {consoleToLog, boldString, FileExtenions} from '../../util/AppUtil';
import {useIsMount} from '../useIsMount';
import {changeInvoiceCustomerApi} from '../../services/authService';
import {autoCompleteClientListApi, getClientDetailsObjApi} from '../../services/clientService';
import { useSnackbar } from 'notistack';
import { setSelectedDrawerState, SelectedDrawerState } from '../../actions/drawer';
import { history } from '../../routers/AppRouter';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ClearIcon from '@material-ui/icons/Clear';
import jsPDF from 'jspdf';

const useStyles = makeStyles((theme) => ({
    dialogTitle : {
        borderBottom:'1px solid #ccc', 
        padding:'16px 24px', 
        display:'flex',  
        justifyContent:'space-between', 
        alignItems:'center',
        fontSize:'16px',
        fontWeight:'600'
    },
    avatarStyle: {
        width:"28px",
        height:"28px",
        fontSize:'12px',
        fontWeight:'600',
        color:'#2B2B2B',
        marginRight:'-8px'
    },
    accountListContainer : {
        padding:'8px',
        borderRadius: '4px',
        cursor:'pointer',
        '&:hover' : {
            background: '#eef2f7'
        }
    },
    MuiTextFieldroot: {
        background:'#f0f3f7',
        border:'none'
    },
    list: {
        display: 'flex',
        cursor: 'pointer',
        alignItems:'center',
        padding:'0px 8px 0px 16px',
        '&:hover' : {
            background:'#F9F9F9'
        }
    },
    MuiFormControlroot: {
        '& .MuiOutlinedInput-input' :{
            paddingLeft: '16px',
        },   
    },
    listContainer : {
        width:'100% !important',
        '&:last-child .dropdownDivider' : {
            display: 'none'
        }
    },
    dividerStyle : {
        background:'#000', 
        opacity:'0.1'
    },
    MuiDialogpaper: {
        scrollbarWidth: 'none',
        overflow:'visible',
        paddingBottom:'5px',
        "&::-webkit-scrollbar": {
            display: "none"
        }   
    },
}))

const BillUploadModal = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();

    const isMount = useIsMount();

    const [searchCustomer, setSearchCustomer] = useState('');
    const [customerList, setCustomerList] = useState([]);
    const [selectedEntity, setSelectedEntity] = useState(-1);

    const entityList = props.selectedAccount?.entities;
    const [attachments, setAttachments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [shouldAttachBill, setShouldAttachBill] = useState(true);
    const MAX_FILES = 10;
    const MAX_TOTAL_SIZE_MB = 25;
    const [totalSize, setTotalSize] = useState(0);

    const fileUploaderContainer={
        display: 'flex',
        width:'100%',
        height: '6rem',
        justifyContent:'center',
        alignItems: 'center',
        cursor: 'pointer',
        borderRadius: '4px',
        border: (attachments && attachments.length > 0) ? 'none' : '2px dashed #c5bebe'
    }

    const clearIcon= {
        minWidth:"20px", 
        padding:"0", 
        marginLeft:'8px',
        marginRight: "10px"
    }

    useEffect(() => {
        if(entityList && entityList.length === 1 && !props.editInvoiceObj) {
            setSelectedEntity(entityList[0]);
        }
    }, [])


    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="down" ref={ref} {...props} />;
    });

   
    const onEntityClick = (e) => {
        const value = e.target.value;
        setSelectedEntity(value);
    }


    const onClose = (shouldOpenNextModal, finalBillObj) => {
        selectedEntity !== -1 && setSelectedEntity(-1);
        props.handleBillDialogClose(shouldOpenNextModal, finalBillObj,selectedEntity);
    }

    const dragOver = (e) => {
        e.preventDefault();
    }
    
    const dragEnter = (e) => {
        e.preventDefault();
    }
    
    const dragLeave = (e) => {
        e.preventDefault();
    }

    const fileDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        consoleToLog(files);
        const localAttachments=[...attachments, ...files]
        if (localAttachments.length > MAX_FILES) {
            enqueueSnackbar(`You can only upload up to ${MAX_FILES} files.`, {variant:'error'});
            return;
        }
        const newTotalSize = localAttachments.reduce((sum, file) => sum + file.size, totalSize);
        if (newTotalSize > MAX_TOTAL_SIZE_MB * 1024 * 1024) {
            enqueueSnackbar(`Total file size must not exceed ${MAX_TOTAL_SIZE_MB}MB.`, {variant:'error'});
            return;
        }
        setTotalSize(newTotalSize);
        setAttachments(localAttachments);
    }

    const onAttachmentSelected = (e) => {
        const { target: { files } } = e;
        consoleToLog(files);
        const localAttachments=[...attachments, ...files]

        let localFilesArr = [];
        if (localAttachments.length > MAX_FILES) {
            enqueueSnackbar(`You can only upload up to ${MAX_FILES} files.`, {variant:'error'});
            return;
        }
        //find 
        const newTotalSize = localAttachments.reduce((sum, file) => sum + file.size, totalSize);
        if (newTotalSize > MAX_TOTAL_SIZE_MB * 1024 * 1024) {
            enqueueSnackbar(`Total file size must not exceed ${MAX_TOTAL_SIZE_MB}MB.`, {variant:'error'});
            return;
        }
        setTotalSize(newTotalSize);

        for(let i=0;i<localAttachments.length;i++){
            //path:company/:id/attachment_file_name_randomstr
            const fileType =  localAttachments[i].type;
            if(fileType && (fileType.toLowerCase() === FileExtenions.PDF)) {
                localFilesArr.push(localAttachments[i]);
            } else {
                
                if(fileType && (fileType.toLowerCase() === FileExtenions.JPEG || fileType.toLowerCase() === FileExtenions.PNG || fileType.toLowerCase() === FileExtenions.JPG)) {
                        if(files[i].size > 8e6) {
                            enqueueSnackbar(`Image file size exceeds 8MB limit`, {variant:'error'});
                        } else {
                            localFilesArr.push(localAttachments[i]);
                        }
                } else {
                    enqueueSnackbar(`File type is not supported`, {variant:'error'});
                }
            }
        }
        if(localFilesArr && localFilesArr.length > 0) {
            setAttachments(localFilesArr);
        }
    }

    const onRemoveAttachment = (e, index) => {
        //e.stopPropagation();
        const finalAttachments=attachments.filter((item, i) => index !== i);
        setAttachments(finalAttachments);

        const updatedSize = finalAttachments.reduce((sum, file) => sum + file.size, 0);
        setTotalSize(updatedSize);
    }

    const showAttachment = (file, index) => {
        return (
            <Grid key={index} item container 
                alignItems='center'
                style={{marginTop: "8px", background:"#f7f7f7", padding:'8px', borderRadius:'5px'}}>
                <Grid item md={11}>
                    <Typography key={index} 
                        variant="body2" 
                        color="secondary" 
                        style={{fontSize: "14px"}}>
                        {file.name}
                    </Typography>
                </Grid>
                <Grid item md={1}>
                    <Button onClick={(e) => onRemoveAttachment(e, index)} 
                        sx={clearIcon}>
                        <ClearIcon fontSize="small" color="secondary" style={{fontSize:'14px', color:'#555'}}/>
                    </Button>
                </Grid>
            </Grid>
        );
    }

    const convertImageToPdf = (obj) => {
        var pdf = new jsPDF();
        const file = obj;

        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = async (e) => {
                try {
                    const imgData = e.target.result;
                    var width = pdf.internal.pageSize.getWidth();
                    var height = pdf.internal.pageSize.getHeight();
                    const imgQuality = 0.7;
                    pdf.addImage(imgData, 'JPEG', 0, 0, width, height, null, 'FAST', imgQuality); // Adjust the dimensions and position as needed
                    const pdfBlob = pdf.output('blob');
        
                    // Create a new File object from the Blob
                    const pdfFile = new File([pdfBlob], file.name.replace(/\.[^/.]+$/, '.pdf'), {
                    type: 'application/pdf',
                    lastModified: new Date(),
                });
            
                // Return the PDF file object through the callback
                    resolve(pdfFile);
                } catch (err) {
                    reject(err);
                }
                };
                reader.onerror = (error) => {
                    reject(error);
                };
                reader.readAsDataURL(obj);
        });
    }

   const getBillstatus = async(billObj) => {

        const checkBillStatus = async () => {
            try {
                const invoice_account_id = props.selectedAccount.id;
                //const entity_id = selectedEntity.id;
                
                const response = await getBillStatusApi(invoice_account_id, billObj);
                console.log(`Response:Got Bill completely:`,response?.data);
                
                //ocr_json is not null and extracted ocr_json
                //pass bill obj
                if(response?.data?.purchase_invoice && 
                    response?.data?.purchase_invoice?.ocr_json!=null){
                    clearInterval(intervalId);
                    setLoading(false);
                    onClose(true, response?.data);
                    history.push(`/bill/${response.data.purchase_invoice.slug}/create`);
                }

                //ocr_json is null and if ocr_status has error
                //dont pass bill obj
                if(response?.data?.purchase_invoice && 
                    response?.data?.purchase_invoice?.ocr_status==='error'){
                    clearInterval(intervalId);
                    setLoading(false);
                    onClose(true, response?.data);
                    history.push(`/bill/${response.data.purchase_invoice.slug}/create`);
                }

            } catch (error) {
                console.error('Error checking bill status:', error);
                clearInterval(intervalId);
                setLoading(false);
            }
        };
        
        //call api recursively 
        var intervalId = setInterval(checkBillStatus, 5000);
    } 

    const onNextClick = async(e) => {
        e.preventDefault();

        if (!selectedEntity || selectedEntity===-1) {
            enqueueSnackbar('Please select entity!', {
                variant:'error'
            });
            return;
        }

        if(shouldAttachBill){
            const files = attachments;
            if (!files || files.length === 0) {
                enqueueSnackbar('Please attach files', {
                    variant:'error'
                });
                return;
            }
            else{
                console.log("files", files)
            }

            let filesToUploaded=[]
            for(let i=0;i<files.length;i++){
                const fileType =  files[i].type;
                let file;
                if(fileType && (fileType.toLowerCase() === FileExtenions.JPEG || fileType.toLowerCase() === FileExtenions.PNG || fileType.toLowerCase() === FileExtenions.JPG)) 
                {
                    file = await convertImageToPdf(files[i]);
                    filesToUploaded.push(file);
                }
                else if(fileType === FileExtenions.PDF){
                    file =  files[i];
                    filesToUploaded.push(file);
                }
                else{
                    enqueueSnackbar(`One of file type is not supported! `, {variant:'error'});
                    return
                }
                
            }

            try{
                //call upload attachments api
                setLoading(true);
                const invoice_account_id = props.selectedAccount.id;
                const entity_id = selectedEntity.id;

                const res=await uploadAttachmentAndCreateBill(invoice_account_id, entity_id, filesToUploaded)
                consoleToLog('Response uploadAttachmentAndCreateBill:', res.data);
                props.setUpdateBillList(res.data.items);
                setLoading(false);
                onClose(false);
                //check bill status every 5 sec if data received at webhook or not
                //getBillstatus(res.data, selectedEntity);
            }
            catch(e){
                console.log("failed to upload", e);
                setLoading(false)
            }
            // let selectedAttachment=files[0];
            // if(selectedAttachment){
            //     //path:company/:id/attachment_file_name_randomstr
            //     const fileType =  selectedAttachment.type;
            //     let file;
                
            //     if(fileType && (fileType.toLowerCase() === FileExtenions.JPEG || fileType.toLowerCase() === FileExtenions.PNG || fileType.toLowerCase() === FileExtenions.JPG)) {
            //         let fileObj = await convertImageToPdf(selectedAttachment);
            //         if(fileObj) {
            //             //file_name = fileObj.name;
            //             //file_type = fileObj.type;
            //             file = fileObj
            //         } else {
            //             setLoading(false);
            //             console.log("**No file found: ")
            //             return;
            //         }
            //     } else {
            //         //file_name = selectedAttachment.name;
            //         //file_type = selectedAttachment.type;
            //         file = selectedAttachment;
            //     }
            //     try{
            //         //call upload attachment api
            //         setLoading(true);
            //         const invoice_account_id = props.selectedAccount.id;
            //         const entity_id = selectedEntity.id;

            //         const res=await uploadAttachmentAndCreateBill(invoice_account_id, entity_id, file)
            //         consoleToLog('Response uploadAttachmentAndCreateBill:', res.data);
            //         //check bill status every 5 sec if data received at webhook or not
            //         getBillstatus(res.data, selectedEntity);
            //     }
            //     catch(e){
            //         console.log("failed to upload", e);
            //         setLoading(false)
            //     }
            // }
        }
        else{
            //direct open next dialog
            history.push('/bill/create');
            onClose(true);
        }
    }

    return (
            <div>
                <Dialog
                    open={props.openBillDialog}
                    //TransitionComponent={Transition}
                    classes={{
                        paper: classes.MuiDialogpaper}}
                    fullWidth
                    onClose={()=>onClose(false)}>
                    <Typography
                        className={classes.dialogTitle}>
                        <span>Create Bill</span>

                        <ClearIcon fontSize='xs' onClick={()=>onClose(false)}
                            style={{cursor:'pointer', verticalAlign:'inherit'}}/>
                        </Typography>

                    <Grid item container direction="column" style={{padding:'8px 16px', position:'relative'}}> 

                        {entityList && entityList.length > 0 &&
                        <Grid item md={12}>
                            <Typography variant='subtitle1' style={{marginBottom:'-6px'}}>
                                Select Entity
                            </Typography>
                            <TextField fullWidth
                                variant='outlined'
                                margin='dense'
                                classes={{
                                    root:classes.MuiTextFieldroot
                                }}
                                select
                                value={selectedEntity}
                                onChange={onEntityClick}
                            >
                                <MenuItem value={-1}>Select Entity</MenuItem>
                                {
                                    entityList.map((entityItem) => {
                                        return <MenuItem key={entityItem?.id} value={entityItem}>
                                                    {entityItem.name}
                                                </MenuItem>
                                    })
                                }
                            </TextField>
                        </Grid>
                        }

                        <Grid item style={{marginTop: "8px"}}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={!shouldAttachBill}
                                        onChange={() =>{
                                            setShouldAttachBill(!shouldAttachBill)
                                        }}
                                        {...props}
                                    />
                                }
                                label="I don't have a bill copy"
                            />
                        </Grid>
                        

                        {shouldAttachBill &&
                        <Grid item sm={12}>
                            {attachments && attachments.length === 0 &&
                            <div>
                                <label style={fileUploaderContainer}
                                    onDragOver={dragOver}
                                    onDragEnter={dragEnter}
                                    onDragLeave={dragLeave}
                                    onDrop={fileDrop}
                                >  
                                    <input style={{display:'none'}}
                                        type="file"
                                        disabled={!shouldAttachBill}
                                        multiple
                                        id="companyListFile"
                                        accept=".pdf, .png, .jpg, .jpeg"
                                        onChange={onAttachmentSelected}
                                    />
                                    <Grid item container justifyContent='center' style={{cursor:'pointer'}}>
                                        <AttachFileIcon sx={clearIcon} fontSize="small" color="secondary"/>
                                        <Typography variant="body2" color="secondary" style={{fontSize: "16px"}}>
                                            Attach Files
                                        </Typography>
                                    </Grid>
                                </label>  
                            </div>}
                            {attachments && attachments.length > 0 &&
                                <div style={{marginTop:'16px', maxHeight: '300px', overflowY: 'auto'}}>
                                    {(attachments && attachments.length > 0) &&
                                            attachments.map((file, index) => {
                                                return showAttachment(file, index);
                                            })
                                    }
                                </div>
                            }
                        </Grid>}

                        <Grid item container justifyContent='flex-end' style={{marginTop:'16px'}}>
                            {loading ? (
                                <CircularProgress />
                            ) : (
                                <Button variant='contained' color='primary' 
                                    onClick={onNextClick}>
                                    {!shouldAttachBill? `Next` : "Upload"}
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </Dialog>
            </div>
    );
}

const mapStateToProps = (state) => ({
    accessToken: state.invoiceAuth,
    selectedAccount: state.invoiceAccounts.selectedAccount,
    drawerState: state.drawerState.setDrawerState,
});

const mapDispatchToProps = (dispatch) => ({
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState))
});


export default connect(mapStateToProps, mapDispatchToProps)(BillUploadModal);
