import React, { useState } from 'react';
import { Dialog, DialogContent, Grid, Typography, Button, CircularProgress } from '@material-ui/core';
import {
    DeleteOutlineOutlined as DeleteIcon,
    CancelOutlined as CancelOutlinedIcon,
    PlaylistAddCheckOutlined as PlaylistAddCheckOutlinedIcon,
    SendOutlined as SendOutlinedIcon,
    CheckCircleOutlineOutlined as CheckCircleOutlineOutlinedIcon,
    Block as BlockIcon
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    MuiDialogpaper: { scrollbarWidth: 'none !important' },
    dialogActionImg: { width: '60px', marginBottom: '8px', display: 'flex', justifyContent: 'center' },
    dailogHeading: { fontSize: '21px', fontWeight: '600', marginBottom: '8px', textAlign: 'center' },
    yesButtonStyle: { borderRadius: '5px', background: '#318415', color: '#fff', width: '110px', '&:hover': { background: '#318415' } },
    noButtonStyle: { borderRadius: '5px', background: '#888', color: '#fff', width: '110px', '&:hover': { background: 'rgba(0,0,0,0.3)' } },
    redButtonStyle: {
        fontSize: '45px', 
        color: 'red'
    },
    greenButtonStyle: {
        fontSize: '45px', 
        color: 'green'
    },
    greyButtonStyle: {
        fontSize: '45px', 
        color: '#828c98'
    }
}));


const ConfirmDialog = (props) => {
    const classes = useStyles();

    const invoice_account_id = props.selectedAccount?.id;

    // Configuration maps to reduce repeated conditions
    const dialogConfig = {
        delete_doc: { heading: 'Delete Document?', message: 'Are you sure you want to delete this document?', icon: <DeleteIcon className={classes.redButtonStyle} /> },
        delete_temp: { heading: 'Delete Template?', message: 'Are you sure you want to delete this template?', icon: <DeleteIcon className={classes.redButtonStyle} /> },
        void_doc: { heading: 'Void Document?', message: 'Are you sure you want to void this document?', icon: <BlockIcon className={classes.greyButtonStyle} /> },
        // mark_approved: {heading: 'Mark Approved?', message: 'Are you sure you want to mark this proposal as approved?', icon: <PlaylistAddCheckOutlinedIcon className={classes.greenButtonStyle}/>},
        // mark_rejected: {heading: 'Mark Rejected?', message: 'Are you sure you want to mark this proposal as rejected?', icon: <CancelOutlinedIcon className={classes.redButtonStyle}/>},
        // mark_sent: {heading: 'Mark Sent?', message: 'Are you sure you want to mark this proposal as sent?', icon: <SendOutlinedIcon className={classes.greyButtonStyle}/>},
        // approve: {heading: 'Approve Proposal?', message: 'Are you sure you want to approve this proposal?', icon: <CheckCircleOutlineOutlinedIcon className={classes.greenButtonStyle}/>},
        // reject: {heading: 'Reject Proposal?', message: 'Are you sure you want to reject this proposal?', icon: <CancelOutlinedIcon className={classes.redButtonStyle}/>},
    };

    const getDialogData = () => {
        // Define logic to choose appropriate dialog data based on props
        if ((props.fromDocumentList || props.fromDocumentPreview) && props.documentItem?.delete_doc_clicked) return dialogConfig.delete_doc;
        if (props.fromDocumentList && props.documentItem?.delete_temp_clicked) return dialogConfig.delete_temp;
        if ((props.fromDocumentList || props.fromDocumentPreview) && props.documentItem?.void_doc_clicked) return dialogConfig.void_doc;
        
    };

    const dialogData = getDialogData();

    const apiRouteStr = () => {

        if(props.documentItem?.mark_approved_clicked) {
            return 'mark_approved';
        }

        if(props.documentItem?.mark_rejected_clicked) {
            return 'mark_rejected';
        }

        if(props.documentItem?.mark_sent_clicked) {
            return 'mark_sent';
        }

        if(props.documentItem?.approve_proposal_clicked) {
            return 'approve';
        }

        if(props.documentItem?.reject_proposal_clicked) {
            return 'reject';
        }
    }

    const handleYesClick = () => {
        const document_id = props.documentItem?.id;
        if (props.fromDocumentList || props.fromDocumentPreview) {
            if (props.documentItem?.delete_doc_clicked) props.callDeleteDocumentApi(document_id);
            if (props.documentItem?.delete_temp_clicked) props.callDeleteTemplateApi(document_id);
            if (props.documentItem?.void_doc_clicked) props.callVoidApi(document_id);
        }
    };

    return (
        <Dialog open={props.openConfirmDialog} onClose={props.handleConfirmDialogClose} classes={{ paper: classes.MuiDialogpaper }} maxWidth="sm" fullWidth>
            <DialogContent style={{ padding: '16px' }}>
                <Grid container direction="column" alignItems="center" justifyContent="center">
                    <Grid item className={classes.dialogActionImg}>{dialogData?.icon}</Grid>
                    <Grid item>
                        <Typography className={classes.dailogHeading}>{dialogData?.heading}</Typography>
                        <Typography variant="body1">{dialogData?.message}</Typography>
                    </Grid>
                    
                    <Grid item style={{ marginTop: '16px' }}>
                        <Grid container spacing={1}>
                            <Grid item>
                                <Button onClick={handleYesClick} className={classes.yesButtonStyle}>
                                    Yes
                                    {props.apiLoading && <CircularProgress size={15} style={{ color: "white", marginLeft: '8px' }} />}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button onClick={props.handleConfirmDialogClose} className={classes.noButtonStyle}>No</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount
});

export default connect(mapStateToProps)(ConfirmDialog);
