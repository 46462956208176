/**Proposal Filters (start_date, end_date, status, clients) */
export const setProposalIssueStartDateFilter = (start_date) => ({
    type: 'SET_PROPOSAL_ISSUE_START_DATE_FILTER',
    start_date
});

export const setProposalIssueEndDateFilter = (end_date) => ({
    type: 'SET_PROPOSAL_ISSUE_END_DATE_FILTER',
    end_date
});

export const addSelectedStatusToProposalFilter = (status) => ({
    type: 'ADD_SELECTED_STATUS_TO_PROPOSAL_FILTER',
    status
});

export const removeSelectedStatusFromProposalFilter = (status) => ({
    type: 'REMOVE_SELECTED_STATUS_FROM_PROPOSAL_FILTER',
    status
});

export const addSelectedClientToProposalFilter = (client) => ({
    type: 'ADD_SELECTED_CLIENT_PROPOSAL_FILTER',
    client
});

export const deleteSelectedClientFromProposalFilter = (client) => ({
    type: 'DELETE_SELECTED_CLIENT_PROPOSAL_FILTER',
    client
});

export const addSelectedEntitiesToProposalFilter = (entity) => ({
    type: 'ADD_SELECTED_ENTITIES_TO_PROPOSAL_FILTER',
    entity
});

export const removeSelectedEntitiesToProposalFilter = (entity) => ({
    type: 'REMOVE_SELECTED_ENTITIES_TO_PROPOSAL_FILTER',
    entity
});

export const addSelectedActivityStatusToProposalFilter = (activity_status) => ({
    type: 'ADD_SELECTED_ACTIVITY_STATUS_TO_PROPOSAL_FILTER',
    activity_status
});

export const removeSelectedActivityStatusFromProposalFilter = (activity_status) => ({
    type: 'REMOVE_SELECTED_ACTIVITY_STATUS_FROM_PROPOSAL_FILTER',
    activity_status
});

export const clearSelectedProposalFilters = () => ({
    type: 'CLEAR_SELECTED_PROPOSAL_FILTERS'
});