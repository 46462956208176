import React, { memo, useState, useEffect, useRef } from 'react';
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DragItemToolbar from './DragItemToolbar';
import PDFContainer from './PDFContainer';
import DocumentPreview from './DocumentPreview';
import PdfDroppedItems from './PdfDroppedItems';
import { Box } from '@material-ui/core';
import {accountMembersListApi} from '../../services/authService';
import {connect} from 'react-redux';
import { consoleToLog, } from '../../util/AppUtil';

const DocumentLayout = memo(({ 
    droppedItems, 
    setDroppedItems, 
    uploadedPdf, 
    showCreateEditTemplateScreen,
    showCreateEditDocumentScreen,
    showPreviewDocumentScreen,
    showSendEmail,
    setShowSendEmail,
    document_id,
    setDocumentObj,
    documentObj,
    onCloseDocumentModal,
    setUpdatedDocumentObj,
    setUploadedPdf,
    setPdfRendered,
    templateObj,
    predefinedTemplateObj,
    ...props
}) => {
    const containerRef = useRef(null);
    const [originalDimension, setOriginalDimension] = useState({width: 0, height: 0});
    const [accountMembersList, setAccountMembersList] = useState([]);

    useEffect(() => {
        getAccountMemberList();
    }, [])

    const {selectedAccount, user, setShowCreateEditDocumentScreen ,setShowCreateEditTemplateScreen, setShowPreviewDocumentScreen} = props;

    const getAccountMemberList = async() => {
        const invoice_account_id = selectedAccount.id;

        try{ 
        const response = await accountMembersListApi(invoice_account_id);
        const res = response.data;
            consoleToLog("Response accountMembersListApi: ", res);

            setAccountMembersList(res);

        } catch(e) {
            consoleToLog("Error accountMembersListApi: ", e.response);
            if(e.response.data && e.response.data.message) {
                return;
            }
        }

    }

    const setHeightForDocument = () => {
        if(showCreateEditTemplateScreen || showCreateEditDocumentScreen) {
            return 'calc(100vh - 70px)';
        } else {
            return '100vh';
        } 
    }
    

    return (
        <DndProvider backend={HTML5Backend}>
        <div
            className="create_doc_layout"
            ref={containerRef}
            style={{ height: setHeightForDocument() }}
        >
        {(showCreateEditTemplateScreen || showCreateEditDocumentScreen) &&
        <Box minWidth="100%">
            <Box display="flex" position="relative">
                {/* Display the Drag Item Toolbar if editing the template */}
                {showCreateEditTemplateScreen && <DragItemToolbar />}

                {(showCreateEditTemplateScreen || showCreateEditDocumentScreen) && (
                    <Box flex={1} display="flex" justifyContent="center" overflow="hidden">
                        <PDFContainer
                            uploadedPdf={uploadedPdf}
                            droppedItems={droppedItems}
                            setDroppedItems={setDroppedItems}
                            showCreateEditTemplateScreen={showCreateEditTemplateScreen}
                            originalDimension={originalDimension}
                            setOriginalDimension={setOriginalDimension}
                            setPdfRendered={setPdfRendered}
                            documentObj={documentObj}
                            templateObj={templateObj}
                            predefinedTemplateObj={predefinedTemplateObj}
                        />
                    </Box>
                )}

                {/* Display PDF Dropped Items only in Edit Document Screen */}
                {showCreateEditDocumentScreen && accountMembersList.length > 0 && (
                    <Box minWidth="40%">
                        <PdfDroppedItems
                            droppedItems={droppedItems}
                            setDroppedItems={setDroppedItems}
                            accountMembersList={accountMembersList}
                            loggedInUser={user}
                        />
                    </Box>
                )}
            </Box>
        </Box>}

        {/* Display the Preview Document Screen */}
        {showPreviewDocumentScreen && (
            <Box flex={1} position="relative" display="flex" overflow="visible">
                <DocumentPreview
                    uploadedPdf={uploadedPdf}
                    droppedItems={droppedItems}
                    originalDimension={originalDimension}
                    setOriginalDimension={setOriginalDimension}
                    setShowCreateEditDocumentScreen={setShowCreateEditDocumentScreen}
                    setShowPreviewDocumentScreen={setShowPreviewDocumentScreen}
                    selectedAccount={selectedAccount}
                    user={user}
                    showSendEmail={showSendEmail}
                    setShowSendEmail={setShowSendEmail}
                    document_id={document_id}
                    setDocumentObj={setDocumentObj}
                    documentObj={documentObj}
                    onCloseDocumentModal={onCloseDocumentModal}
                    setUpdatedDocumentObj={setUpdatedDocumentObj}
                    setUploadedPdf={setUploadedPdf}
                    setPdfRendered={setPdfRendered}
                    templateObj={templateObj}
                />
            </Box>
        )}
    </div>
</DndProvider>
    );
});

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
    user: state.invoiceAccounts.user
});

export default connect(mapStateToProps)(DocumentLayout);
