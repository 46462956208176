import React , {useState, useEffect} from 'react';
import {Grid, Typography, Button, TextField, InputAdornment, CircularProgress, Divider, Tooltip, Paper} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState,  RichUtils, Modifier } from 'draft-js';
import {getMentionOptionsForSubject ,getMentionOptionsForBody} from '../services/mentionService';
import { MentionsInput, Mention } from 'react-mentions';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import { DroppedItemTypes, emailValidator, EstimateStatus } from '../util/AppUtil';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useIsMount } from './useIsMount';
import SendIcon from '@material-ui/icons/Send';
import { InvoiceStatus, consoleToLog } from '../util/AppUtil';
import AddIcon from '@material-ui/icons/Add';
import { SendEmailType} from '../actions/invoiceAccount';
import { history } from '../routers/AppRouter';
import { SelectedDrawerState } from '../actions/drawer';
import CheckIcon from '@material-ui/icons/Check';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import PlaylistAddCheckOutlinedIcon from '@material-ui/icons/PlaylistAddCheckOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import {connect} from 'react-redux';
import { AccessLevel } from '../util/AppUtil';
import {FormControlLabel, Checkbox} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    paper2: {
        padding: theme.spacing(1),
    },
    buttonStyles: {
       // marginBottom:'8px',
    },
    previewInvoicefirstRow: {
        borderBottom:'1px solid rgba(0,0,0, .1)',
        padding:'10px 16px 8px 16px'
    },
    reportsfirstRow: {
        borderBottom:'1px solid rgba(0,0,0, .1)',
        padding:'16px'
    },
    backIconReview: {
        padding:'6px',
        border:'1px solid rgba(0,0,0, .1)',
        borderRadius:'5px',
        cursor:'pointer'
    },
    backIconSendEmail: {
        width:'40px',
        height:'40px',
        border:'1px solid rgba(0,0,0, .1)',
        borderRadius:'5px',
        cursor:'pointer',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
    },
    plusIcon: {
        fontSize: '18px',
        marginRight:'8px'
    },
    highlightOffIcon: {
        fontSize:'16px',
        marginRight:'4px'
    },
    customMarginBottom : {
        marginBottom: '24px'
    },
    verifyLink: {
        color: '#0000EE',
        cursor: 'pointer',
        fontSize:'12px',
        margin:"0px 1px 0px 8px",
        '&:hover': {
            textDecoration:'underline'
        }
    }

}))

const SendEmailComponent = (props) => {
    const classes = useStyles();
    const isMount = useIsMount();
    const {enqueueSnackbar} = useSnackbar();

    const [emailArr, setEmailArr] = useState([]);
    const [emailArrIndex, setEmailArrIndex] = useState(emailArr.length - 1);
    const [loading, setLoading] = useState(false);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [mentionOptionsForBody, setMentionOptionsForBody] = useState([]);
    const [mentionOptionsForSubject, setMentionOptionsForSubject] = useState([]);
    const [emailSubject, setEmailSubject] = useState('');
    const [mentions, setMentions] = useState();

    const [attachEmailPdf, setAttachEmailPdf] = useState(false);
    const [attachBillCopies, setAttachBillCopies] = useState(false);

    useEffect(() => {
        if(props.fromPreviewInvoiceComponent && props.showEstimateScreen) {
            setEmailSubject(`{{invoice_account.name}} sent you an ${props.selectedAccount.estimate_terminology}`);
            setEmailBody(`Dear {{client.name}},\nThank you for your business, always a pleasure to work with you!`);
        } else if(props.fromPreviewInvoiceComponent) {
                prefillFormData();
                setMentionOptionsForBody(getMentionOptionsForBody())
                setMentionOptionsForSubject(getMentionOptionsForSubject())
        } else if(props.fromReportsComponent) {
            setEmailSubject(`Statement till ${moment(props.toDateFilter).format('DD-MMM-YYYY')}`);
            setEmailBody('Thank you for your business');
        } else if(props.fromProposalDetails) {
            setEmailSubject(`{{invoice_account.name}} sent you a proposal`);
            setEmailBody('Thank you and hope to do business with you');
        } else if(props.fromDocumentDetails) {
            const filterDroppedItems = props.droppedItems.filter((item) => item.component_type === DroppedItemTypes.SIGNATURE && !item.value_json.signature_image_path)
            if(filterDroppedItems && filterDroppedItems.length > 0) {
                setEmailSubject(`Please sign {{document.name}}`);
            } else {
                setEmailSubject(`{{invoice_account.name}} sent you {{document.name}}`);
            }
            setEmailBody('Thank you and hope to do business with you');
        }
    }, []);

    useEffect(() => {
        const email_arr = props.fromPreviewInvoiceComponent ? props.invoiceObj?.email_array : [];
        if(isMount) {
            setEmailArrIndex(email_arr.length - 1);
            // const updatedArray = emailArr;
            
            // updatedArray[emailArrIndex + 1] = {email: client_email};
            const updatedArray = email_arr.map((item, i) => {
                return emailArr[i] = {email: item}
            })
            setEmailArr([...updatedArray]);
        }
    }, []);

    useEffect(() => { 
        if(props.fromDocumentDetails) {
            const filteredSignList = props.droppedItems.filter((item) => item.component_type === DroppedItemTypes.SIGNATURE && !item.value_json.signature_image_path)
            const recipient_emails = props.documentObj?.recipient_emails && props.documentObj?.recipient_emails.length > 0 ? props.documentObj?.recipient_emails : [];
            const emailList = filteredSignList.map((item) => item.value_json.email);
            const email_arr = [...emailList, ...recipient_emails];

            if(isMount) {
                setEmailArrIndex(email_arr.length - 1);
                // const updatedArray = emailArr;
                
                // updatedArray[emailArrIndex + 1] = {email: client_email};
                const updatedArray = email_arr.map((item, i) => {
                    return emailArr[i] = {email: item, disabled:true}
                })
                setEmailArr([...updatedArray]);
            }
        }
    }, [props.fromDocumentDetails]);

    useEffect(() => {
        if(!isMount) {
            setEmailArrIndex(emailArr.length - 1);
           // showSendEmailUI()
        }

    }, [emailArr]);

    const {access_level} = props.selectedAccount;
    const accessLevelViewer = (AccessLevel.getUserAccessLevelValue(access_level) === AccessLevel.VIEWER) ? true : false;
    const is_email_verified = props.fromPreviewInvoiceComponent ? props.invoiceObj?.entity?.is_email_verified : props.proposalObj?.entity?.is_email_verified;

    let toolbarConfig = {
        inline: {
            options: ['bold', 'italic', 'underline', 'strikethrough']
        },
        /* blockType: {
            options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code']
        }, */
        list: {
            options: ['unordered', 'ordered']
        },
        textAlign: {
            options: ['left', 'center', 'right', 'justify']
        },
        link: { options: ['link'] },
        options: ['inline', 'link',/* 'blockType', */ 'fontFamily', 'fontSize', 'colorPicker', 'list', 'textAlign']
        
    };

    const prefillFormData = () => {
        const { body, subject } = props.invoiceObj?.email_json ? props.invoiceObj?.email_json : {};

        if(body) {
            setEmailBody(body);
        }

        
        setEmailSubject(subject);
    }

    const setEmailBody = (body) => {
        const blocksFromHtml = htmlToDraft(body);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);
    }

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState)
    }

    const handleReturn = (e) =>  {
        if (e.keyCode===13) {
            setEditorState(RichUtils.insertSoftNewline(editorState));
            return 'handled';
        }
        return 'not-handled';
    }

    const handlePastedText = (text, html, editorState) => {
        var content = editorState.getCurrentContent();
        var selection = editorState.getSelection();

        var newContent = Modifier.insertText(
            content, 
            selection,
            text
        )

        var newEditorState = EditorState.push(editorState, newContent, "insert-characters")
        //Then send this to onChange()
        setEditorState(newEditorState);
        return 'handled'
    }

    const addEmailArr = () => {
        setEmailArrIndex(emailArrIndex => emailArrIndex + 1);
        let newObj = { 
            email: ''
        }
        setEmailArr([...emailArr, newObj])
    }

    const removeFromEmailArr = (index) => {
        //emailArr.splice(emailArrIndex, 1);
        if(index > -1) {
            emailArr.splice(index, 1)
            setEmailArr([...emailArr]);
            
            //getTaxDetails();
        }
    }

    const removeLabel = (index) => {
        return {
            endAdornment: 
                <InputAdornment position="end" className={classes.daysLabel}>
                    <Button style={{fontSize:'12px'}}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            removeFromEmailArr(index)
                        }}
                    >
                        <HighlightOffIcon 
                        className={classes.highlightOffIcon}
                        fontSize='small'/> Remove
                    </Button>
                </InputAdornment>
        }
    }

    //console.log('api response', props.invoiceObj);

    const onEmailFieldChange = (e, index) => {
        const updatedArray = emailArr;
        updatedArray[index].email = e.target.value;
        const isEmailValid = emailValidator(updatedArray[index].email);
        setEmailArr([...updatedArray]);
    }

    const validateEmail = (index) => {
        if (emailArr[index].email !== '' && !emailValidator(emailArr[index].email)) {
            return 'The email field must be an valid email.';
        } else {
            return true;
        }
    }

    const validateEmail1 = (index) => {
        const updatedEmailArr = emailArr.filter((email, index) => validateEmail(index) !== true);
        return updatedEmailArr && updatedEmailArr.length > 0 ? true : false;
    }

    const onPreviewInvoiceBackIconClick = () => {
            props.onBackIconClick();
    }

    const onSendEmailBackIconClick = () => {
        if(props.fromPreviewInvoiceComponent) {
            props.onBackIconClick();
        }

        if(props.fromProposalDetails || props.fromDocumentDetails) {
            props.setShowSendEmail(false);
        }
    }

    const onReportsBackIconClick = () => {
        props.onBackButtonClick();
    }

    const onSendEmailClick = () => {
        const email_array = emailArr.map((item) => item.email);
        //console.log('email_array', email_array);

        let subject = emailSubject; 
        if(!subject){
            enqueueSnackbar('Subject cannot be empty', {variant:'error'});
            return;
        }

        let body;
        if (!editorState.getCurrentContent().hasText()) {
            enqueueSnackbar('Email body can not be empty!', {
            variant:'error'
        });
        return;
        }
        body = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        consoleToLog('body: ', body);

        let email_json = {subject, body}

        if(props.fromPreviewInvoiceComponent && !props.showEstimateScreen) {
            props.onSendEmailClickFromPreviewInvoice(email_array, email_json, attachEmailPdf, attachBillCopies);
        } else if(props.fromReportsComponent) {
            props.onSendEmailClickFromClientStatement(email_array, subject, body);
        } else if(props.fromPreviewInvoiceComponent && props.showEstimateScreen) {
            props.onSendEstimateClick(email_array, email_json, attachEmailPdf);
        } else if(props.fromProposalDetails) {
            props.callSendProposalMailApi(email_array, email_json)
        } else if(props.fromDocumentDetails) {
            props.callsendEmailDocumentApi(email_array, email_json);
        }
    }

    const disableSendButton = () => {
        if(emailArr.length === 0 || emailArr[emailArrIndex]?.email === '') {
            return true
        } else if(!emailValidator(emailArr[emailArrIndex]?.email)){
            return true
        } else if((props.fromPreviewInvoiceComponent || props.fromProposalDetails) && !is_email_verified) {
            return true;
        } else if(accessLevelViewer) {
            return true
        } else {
            return false;
        }
    }


    const buttonsForPreviewInvoiceAndEstimate = () => {

        const reimbursementsArray = props.invoiceObj?.reimbursements;
        const reimburstmentBillArray = reimbursementsArray && reimbursementsArray.length > 0 ? reimbursementsArray.filter((item) => item.attachment_s3_path) : [];

        return (
            <>
                {props.fromPreviewInvoiceComponent && props.invoiceObj.activity_status === 'none' && 
                    <Divider color='secondary' style={{margin:'16px 0px'}}/>}

                {props.fromPreviewInvoiceComponent && props.invoiceObj.activity_status === 'none' && 
                <Button fullWidth className={classes.buttonStyles}
                        onClick={props.onMarkAsSentClick}
                        variant="outlined" color="secondary">
                        <CheckIcon fontSize='small'
                            style={{marginRight:'5px', fontSize:'16px'}} />
                            Mark as Sent
                            <Tooltip title={`${props.showEstimateScreen ? 'Estimate' : 'Invoice'} will not be send to customer but status will be changed to sent. 
                                It is useful when you don't want to send invoices from eProcessify Invoices.`} arrow>
                                <HelpOutlineIcon fontSize='small'
                                    style={{marginLeft:'8px', fontSize:'16px'}}/>
                            </Tooltip>

                            {props.apiLoading && <CircularProgress size={18} style={{marginLeft:'8px'}}/>}
                </Button>}

                {props.fromPreviewInvoiceComponent && props.invoiceObj.approval_required &&
                    <Divider color='secondary' style={{margin:'16px 0px'}}/>}

                {props.showApprovedOrRejectedForEstimate() &&
                <Button fullWidth className={classes.buttonStyles}
                        onClick={(e) => props.handleConfirmDialogOpen(e, {mark_as_approved: true})}
                        variant="outlined" color="secondary">
                        <PlaylistAddCheckOutlinedIcon fontSize='small'
                            style={{marginRight:'5px', fontSize:'16px'}} />
                            Mark as Approved
                            {/* <Tooltip title="Estimate will not be send to customer but status will be changed to approved. It is useful when you don't want to send estimate from eProcessify Invoices." arrow>
                                <HelpOutlineIcon fontSize='small'
                                    style={{marginLeft:'8px', fontSize:'16px'}}/>
                            </Tooltip> */}
                </Button>}

                {props.showApprovedOrRejectedForEstimate() &&
                <Button fullWidth className={classes.buttonStyles}
                        onClick={(e) => props.handleConfirmDialogOpen(e,  {mark_as_rejected: true})}
                        style={{marginTop:'16px'}}
                        variant="outlined" color="secondary">
                        <CancelOutlinedIcon fontSize='small'
                            style={{marginRight:'5px', fontSize:'16px'}} />
                            Mark as Rejected
                </Button>}

                {props.fromPreviewInvoiceComponent &&
                    <Grid item container direction='column' style={{marginTop:'16px'}}>
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={attachEmailPdf}
                                        onChange={() =>{setAttachEmailPdf(!attachEmailPdf)}}
                                    />
                                }
                                label={props.showEstimateScreen ? "Attach estimate PDF with email" : "Attach invoice PDF with email"}
                            />
                        </Grid>

                        {reimburstmentBillArray && reimburstmentBillArray.length > 0 && !props.showEstimateScreen &&
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={attachBillCopies}
                                        onChange={() =>{setAttachBillCopies(!attachBillCopies)}}
                                    />
                                }
                                label="Attach bill copies with email"
                            />
                        </Grid>}
                    </Grid>
                }
            </>
        )
    }

    const buttonsForPreviewProposal = () => {
        return (
            <>
                {props.fromProposalDetails && props.proposalObj?.can_send &&
                    <Divider color='secondary' style={{margin:'16px 0px'}}/>}

                {props.fromProposalDetails && props.proposalObj?.can_send &&
                <Button fullWidth className={classes.buttonStyles}
                        onClick={(e) => props.handleConfirmDialogOpen(e, {mark_sent_clicked: true})}
                        variant="outlined" color="secondary">
                        <CheckIcon fontSize='small'
                            style={{marginRight:'5px', fontSize:'16px'}} />
                            Mark as Sent
                            <Tooltip title={`Proposal will not be send to customer but status will be changed to sent. 
                                It is useful when you don't want to send invoices from eProcessify Invoices.`} arrow>
                                <HelpOutlineIcon fontSize='small'
                                    style={{marginLeft:'8px', fontSize:'16px'}}/>
                            </Tooltip>
                </Button>}
            </>
        )
    }

    return (
        <Paper elevation={0}
            variant={props.fromPreviewInvoiceComponent ? 'elevation' : 'outlined'}
            >
            <Grid item container direction='column' alignItems='center'>

                <Grid item container alignItems='center'
                    justifyContent='space-between'
                    className={props.fromPreviewInvoiceComponent ? classes.previewInvoicefirstRow : classes.reportsfirstRow}>

                        <Grid item sm={5} className={classes.backIconContainer}>
                            <Grid item container alignItems='center'>
                                {(props.fromPreviewInvoiceComponent || props.fromProposalDetails || props.fromDocumentDetails) ?
                                <Grid item onClick={onSendEmailBackIconClick}>
                                    <ArrowLeftIcon className={classes.backIconReview}/>
                                </Grid>
                                :
                                <div className={classes.backIconSendEmail}
                                    onClick={onReportsBackIconClick}>
                                    <KeyboardArrowLeftIcon fontSize='large' style={{color:'#333'}}/>
                                </div>
                                }

                                <Grid item style={{display:'flex', alignItems:'center', marginLeft:'16px'}}>
                                    <Typography variant="body1">
                                        {(props.fromPreviewInvoiceComponent || props.fromProposalDetails) ? 'Send Email' : 'Send Statement'}
                                    </Typography>
                                </Grid>

                            </Grid>
                        </Grid>
                        <Grid item sm={3}>
                            {props.fromDocumentDetails
                                ?
                                <Button fullWidth
                                    onClick={onSendEmailClick}
                                    disabled={disableSendButton()}
                                    variant="contained" 
                                    className={`${classes.buttonStyles} button_purple`}
                                    style={{
                                            cursor:'pointer',
                                            opacity: disableSendButton() || validateEmail1() ? 0.3 : 1 
                                        }}
                                    >
                                    {props.sendEmailLoading ? <CircularProgress size={15} style={{color:"#fff", marginRight:"6px"}}/> :
                                    <SendIcon fontSize='small'
                                    style={{marginRight:'5px', fontSize:'16px'}} />}
                                        Send 
                                </Button>
                                :
                                <Button fullWidth
                                    onClick={onSendEmailClick}
                                    disabled={disableSendButton()}
                                    variant="contained" 
                                    className={`${classes.buttonStyles} button_purple`}
                                    style={{
                                            cursor:'pointer',
                                            opacity: disableSendButton() || validateEmail1() ? 0.3 : 1 }}>
                                    {props.sendEmailLoading ? <CircularProgress size={15} style={{color:"#fff", marginRight:"6px"}}/> :
                                    <SendIcon fontSize='small'
                                    style={{marginRight:'5px', fontSize:'16px'}} />}
                                        Send 
                                </Button>
                            }
                        </Grid>
                </Grid>

                <Grid item container alignItems='center'
                    justifyContent='space-between'
                    style={{padding:'32px 24px 24px 24px'}}>

                        <Grid item container direction='column'>

                            <Typography variant="body2">
                                Other recipients:
                            </Typography>

                            <Grid item sm={12} style={{marginTop:'4px'}}> 
                            {
                                emailArr && emailArr.length > 0 &&
                                emailArr.map((email, index) => {
                                    return <Grid item sm={12} key={index}>
                                                <TextField fullWidth
                                                    variant="outlined"
                                                    margin="dense"
                                                    type="text"
                                                    value={email.email}
                                                    placeholder="Enter email you want to send invoice"
                                                    onChange={(e) => onEmailFieldChange(e, index)}
                                                    InputProps={removeLabel(index)}
                                                    disabled={email?.disabled}
                                                    //error={errorType === 1 && emailArr[index].email === email.email}
                                                    //helperText={(errorType === 1 &&  emailArr[index].email === email.email) ? errorMessage : ''}
                                                />
                                                <Typography variant="subtitle1" style={{color:'red', marginTop:'-3px'}}>
                                                    {validateEmail(index)}
                                                </Typography>
                                            </Grid>
                                })
                            }
                            </Grid>

                            <Grid item sm={5} style={{marginTop:'4px'}} 
                                className={classes.customMarginBottom}> 
                                <Button variant="outlined" color="primary"
                                    onClick={addEmailArr}
                                    disabled={!props.fromDocumentDetails && emailArr.length === 3}
                                    style={{padding:'2px 6px'}}
                                >
                                    <AddIcon fontSize='small' className={classes.plusIcon}/>    Add Recipient
                                </Button>
                            </Grid>


                            <Typography variant="body2">
                                Subject
                            </Typography>
                            <Grid item sm={12} className={classes.customMarginBottom}>
                                {/* <TextField fullWidth
                                    variant="outlined"
                                    margin="dense"
                                    type="text"
                                    placeholder="Subject"
                                    value={emailSubject}
                                    onChange={(e) => setEmailSubject(e.target.value)}
                                    //style={{border: (errorType === 4 && index === itemIndex) && '1px solid red'}}
                                /> */}
                                <div className='emailSchedulerMention'>        
                                <MentionsInput className="mentionWrapper"
                                    experimental__cutcopypaste="true"
                                    singleLine={true}
                                    //disabled={}
                                    value={emailSubject}
                                    placeholder={props.fromPreviewInvoiceComponent && !props.showEstimateScreen && !props.fromProposalDetails ? 'Press * to display options' : 'Type here'}
                                    allowSpaceInQuery={true}
                                    onChange={(e, newValue, newPlainTextValue, mentions) => {
                                        setEmailSubject(e.target.value);
                                        setMentions(mentions)
                                    }}>
                                        <Mention
                                            trigger="*"
                                            data={!props.showEstimateScreen ? mentionOptionsForSubject : []}
                                            renderSuggestion={(suggestion, search, highlightedDisplay, index, focused) => (
                                                <div className={`user ${focused ? 'focused' : ''}`}>{/*`user ${focused ? 'focused' : ''} m-1 p-1`*/}
                                                    {highlightedDisplay}
                                                </div>
                                            )}
                                        />
                                </MentionsInput> 
                        </div>
                            </Grid>

                            <Grid item container justifyContent='space-between'>
                                <Grid item>
                                    <Typography variant="body2">
                                        Message
                                    </Typography>
                                </Grid>

                                {props.fromPreviewInvoiceComponent && !props.showEstimateScreen && !props.fromProposalDetails &&
                                <Grid item>
                                    <Typography 
                                        onClick={props.onCustomizeEmailSettingsClick}
                                        className={classes.verifyLink}>
                                        Customize email reminder settings
                                    </Typography>
                                </Grid>}
                            </Grid>
                            <Grid item sm={12} style={{marginBottom:'8px'}}>
                                <Editor
                                    editorStyle={{ 
                                        border: '1px solid lightgray',
                                        minHeight: '200px',
                                        scrollbarWidth: 'none',
                                        lineHeight:1.3,
                                        position:'relative',
                                        padding: '12px',
                                        "&::-webkit-scrollbar": {
                                            display: "none"
                                        }
                                    }}

                                    mention={{
                                        separator: ' ',
                                        trigger: '*',
                                        suggestions: (!props.showEstimateScreen || !props.fromProposalDetails) && mentionOptionsForBody,
                                    }}
                                    editorState={editorState}
                                    onEditorStateChange={onEditorStateChange}
                                    toolbar={toolbarConfig}
                                    //handlePastedText={() => false}
                                    placeholder={props.fromPreviewInvoiceComponent && !props.showEstimateScreen ? 'Press * to display options' : 'Type here'}
                                    handlePastedText={handlePastedText}
                                    handleReturn={handleReturn}
                                    //readOnly={}
                                />

                                {
                                    props.fromPreviewInvoiceComponent 
                                    ?
                                    buttonsForPreviewInvoiceAndEstimate()
                                    :
                                    props.fromProposalDetails &&
                                    buttonsForPreviewProposal()
                                }
                            </Grid>

                        </Grid>  

                </Grid>    
            </Grid>
        </Paper>
    );
}

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount
});

export default connect(mapStateToProps)(SendEmailComponent);