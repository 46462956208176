import React, {useEffect, useState, useRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Container, Button, Typography, Tooltip, IconButton, Fab, Divider, Dialog, Paper, CircularProgress} from '@material-ui/core';
import clsx from 'clsx';
import CardMedia from '@material-ui/core/CardMedia';
import {connect} from 'react-redux';
import { trackGAEvent, consoleToLog, shouldDisable, AccessLevel, AppRoutes, ClientsTabValue } from '../util/AppUtil';
import ClearIcon from '@material-ui/icons/Clear';
import { setSelectedInvoiceAccount, setInvoiceAccountsList } from '../actions/invoiceAccount';
import { useSnackbar } from 'notistack';
import {getAllItemListApi, invoiceAccountOnboardingUpdateApi} from '../services/authService';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { setSelectedDrawerState, SelectedDrawerState } from '../actions/drawer';
import { history } from '../routers/AppRouter';
import { getClientsListApi } from '../services/clientService';
import {setHeightForComponent} from '../util/AppUtil';
import OnboardingEntityUpdate from './onboarding/OnboardingEntityUpdate';
import TaxPresets from './TaxPresets';
import {withStyles} from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import ClientListComponent from './ClientListComponent';
import InvoiceItemsComponent from './InvoiceItemsComponent';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import {getItemListApi} from '../services/authService';
import { useIsMount } from './useIsMount';
import { useTheme, useMediaQuery } from '@material-ui/core';
import {updateUserLoginApi} from '../services/authService';
import { setUserObj } from '../actions/invoiceAccount';
import { usePropsContext } from './context';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import CheckIcon from '@material-ui/icons/Check';
import ApartmentOutlinedIcon from '@material-ui/icons/ApartmentOutlined';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import GroupAddOutlinedIcon from '@material-ui/icons/GroupAddOutlined';
import PostAddOutlinedIcon from '@material-ui/icons/PostAddOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import FormatListNumberedOutlinedIcon from '@material-ui/icons/FormatListNumberedOutlined';
import OnboardingNumberingSetup from './OnboardingNumberingSetup';

const useStyles = makeStyles((theme) => ({
    mainContent: {
        width: '100%',
        backgroundColor: theme.palette.primary.light, 
        transition: ".5s", 
        //overflow:'hidden',
        overflow:'auto',
        overflowX:'hidden',
        "&::-webkit-scrollbar": {
            display: "none"
        }
    },
    container: {
        marginTop: "8px"
    },
    root: {
        display: 'flex',
        position:'relative',
    },
    customColumn1: {
        maxWidth:'11%',
        flexBasis:'11%'
    },
    customColumn1A: {
        maxWidth:'20%',
        flexBasis:'20%'
    },
    customColumn2: {
        maxWidth:'89%',
        flexBasis:'89%'
    },
    customColumn2A: {
        maxWidth:'80%',
        flexBasis:'80%'
    },
    buttonStyle: {
        position:'absolute',
        top:'4px',
        right:'4px',
        border:"1px solid #555",
        background:'transparent',
        width:'35px',
        height:'35px',
        color:'#222', 
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    imageContainer: {
        padding:'16px 0px 0px 16px'
    },
    imageStyle1: {
        width: '100px',
        height:'111px'
    },
    imageStyle2: {
        width: '210px',
        height:'231px'
    },
    headingStyle1: {
        fontSize: '25px',
        fontWeight: 600,
        marginTop:'16px',
        [theme.breakpoints.down(1400)] : {
            fontSize: '23.5px'
        }
    },
    subtitleStyle1: {
        fontSize: '15px',
        fontWeight: 400,
        color:'#333',
        [theme.breakpoints.down(1400)] : {
            fontSize: '14px'
        }
    },
    buttonBorderStyle: {
        position:'relative',
        border:'2px dashed #ccc',
        width:'62px',
        height: '62px',
        background:'#fff',
        padding:'15px',
        gap:"10px",
        '&:hover': {
            zIndex: 10,
            background: 'rgba(0, 0, 0, 0.2)',
        }
    },
    buttonBorderStyle1: {
        background:'#fff !important',
        border: '2px dashed blue !important',
        width: '60px !important',
        height: '60px !important',
        display: 'flex !important',
        borderRadius: '50% !important',
        justifyContent: 'center !important',
        alignItems: 'center !important',
        color: 'blue !important',
        zIndex: 10,
    },
    buttonBorderCompleted: {
        background:'#5cd361 !important',
        border: '2px dashed green !important',
        width: '60px !important',
        height: '60px !important',
        display: 'flex !important',
        borderRadius: '50% !important',
        justifyContent: 'center !important',
        alignItems: 'center !important',
        color: '#1f9000 !important',
        zIndex: 10,
    },
    labelStyle: {
        textAlign:'center',
        marginTop:'16px',
        fontSize:'12px',
        fontWeight: 500
    },
    columnContainer: {
        textAlign:'center',
        position:'relative'
    },
    lineStyle: {
        position:'absolute', 
        width:'120px', 
        top:'31px',
        borderBottom:'2px dashed rgba(0,0,0,0.2)',
        color:'rgba(0, 0, 0, 0.1)',
        textAlign:'left',
        '&:hover': {
            color: 'transparent'
        }
        
    },
    MuiDialogpaper: {
        scrollbarWidth: 'none',
        overflow:'visible',
        width:'900px',
        height:'380px',
        "&::-webkit-scrollbar": {
            display: "none"
        }   
    },
    dialogTitle : {
        fontSize:'17px',
        fontWeight:'600',
        marginTop:'24px'
    },
    iconColor: {
        color: '#bdbdbd',
    },
    iconColorActive: {
        color: 'blue !important',
    },
    contentContainer: {
        margin: '-36px 0px 32px 0px',
        position:'relative',
        boxSizing:'border-box',
    },
    colorLibRoot: {
        backgroundColor: '#fff',
        border:'2px dashed rgba(0,0,0,0.1)',
        zIndex: 1,
        color: '#fff',
        width: 60,
        height: 60,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        cursor:'pointer'
    },
    active: {
        border:'2px dashed blue',
    },
    completed: {
        border:'2px dashed blue',
        color:'blue'
    },
}
));

const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 22,
    },
    active: {
        '& $line': {
            borderBottom: '2px dashed blue',
        },
    },
    completed: {
        '& $line': {
            borderBottom: '2px dashed blue',
        },
    },
    line: {
        border: 0,
        position:"relative",
        top:'5px',
        borderBottom: '2px dashed rgba(0,0,0,0.1)',
    },
})(StepConnector);

const OutsideOnboardingComponent = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const isMount = useIsMount();

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down(1400));
    const isLargeScreen = useMediaQuery(theme.breakpoints.up(1950));
    const {handleClickDialogOpen} = usePropsContext();


    const steps = ["Setup Business Entity", "Setup Taxes", "Setup Invoice Number", "Add Client", "Add Item", "Start Generating Invoices"];
    const entity_obj = props.selectedAccount?.entities && props.selectedAccount?.entities.length > 0 ? props.selectedAccount?.entities[0] : [];

    useEffect(() => {
        if(props.user.first_login) {
            updateUserLogin();
        }
    }, []);

    useEffect(() => {
        handleStepClick(0);
        getClientList();
    }, []);

    useEffect(() => {
        if(entity_obj) {
            setEntityObj(entity_obj);
        }
    }, [entity_obj])

    const [expanded, setExpanded] = React.useState(true);
    const [openDialog, setOpenDialog] = useState(false);
    const [onboardingClientList, setOnboardingClientList] = useState(false);
    const [onboardingItemList, setOnboardingItemList] = useState(false);
    const [tabsValue, setTabsValue] = useState(0);
    const [entityObj, setEntityObj] = useState(undefined);
    const stepsRef = useRef([]);


    const { access_level } = props.selectedOrganization || {};
    const accessLevelOwner = shouldDisable(AccessLevel.getUserAccessLevelValue(access_level), AccessLevel.OWNER);
    const accessLevelViewer = (AccessLevel.getUserAccessLevelValue(access_level) === AccessLevel.VIEWER) ? true : false;
    const entityList = props.selectedAccount?.entities;
    const taxPresets = props.selectedAccount?.tax_presets;
    const canCreateInvoice = props.selectedAccount?.can_create_invoice;
    

    let acc_name = entityObj?.name;
    let acc_email = entityObj?.email;
    let acc_address = entityObj?.address;
    let acc_country = entityObj?.country;
    let acc_state = entityObj?.state;
    let acc_client_notes = entityObj?.default_client_notes;
    let acc_payment_terms = entityObj?.default_payment_terms;
    const isEmailVerified = entityObj?.is_email_verified;

    const allEntitiesInfoFilled = () => {
        if(acc_name && acc_email && acc_address && acc_country && acc_state &&
            acc_client_notes && acc_payment_terms && isEmailVerified) {
                return true;
            } else {
                return false;
            }
    }

    const updateUserLogin = () => {

        updateUserLoginApi()
            .then((response) => {
                const res = response.data;
                consoleToLog('Response updateUserLoginApi: ', res);

                props.setUserObj(res);
            })
            .catch((e) => {
                consoleToLog('Error updateUserLoginApi: ', e.reponse);
                if(e.response.data && e.response.data.message) {
                    return;
                }
            })
    }



    function ColorlibStepIcon(props) {
        const classes = useStyles();
        const { active, completed } = props;

        const icons = {
            1: <ApartmentOutlinedIcon className={active ? classes.iconColorActive : classes.iconColor}/>,
            2: <AccountBalanceWalletOutlinedIcon className={active ? classes.iconColorActive : classes.iconColor}/>,
            3: <FormatListNumberedOutlinedIcon className={active ? classes.iconColorActive : classes.iconColor}/>,
            4: <GroupAddOutlinedIcon className={active ? classes.iconColorActive : classes.iconColor}/>,
            5: <PostAddOutlinedIcon className={active ? classes.iconColorActive : classes.iconColor}/>,
            6: <DescriptionOutlinedIcon  className={classes.iconColorActive}/> 
        };

        const setCompletedClasses = () => {
            if(active) {
                return classes.buttonBorderStyle1;
            } else {
                if(props.icon === 1 && allEntitiesInfoFilled()) {
                    return classes.buttonBorderCompleted;
                }
                if(props.icon === 2 && taxPresets && taxPresets.length > 0 ) {
                    return classes.buttonBorderCompleted;
                }

                if(props.icon === 3 && onboardingClientList && onboardingClientList.length > 0 ) {
                    return classes.buttonBorderCompleted;
                }

                if(props.icon === 4 && onboardingItemList && onboardingItemList.length > 0 ) {
                    return classes.buttonBorderCompleted;
                }
            }
        }
    
    return (
        <div 
            className={clsx(classes.colorLibRoot,{
                [classes.active]: active,
                [classes.completed]: completed,
            })}
            >
            {icons[String(props.icon)]}
        </div>
    );
    }

    const onStepClick = (val) => {
        if(val !== 4) {
            setTabsValue(val)
        }
    }

    const getClientList = () => {
        const invoice_account_id = props.selectedAccount?.id
            
        getClientsListApi(invoice_account_id, 0, '', ClientsTabValue.CLIENTS, false)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response getClientsListApi: ', res);
                setOnboardingClientList(res.companies);
                getItemList(invoice_account_id);
            })
            .catch((e) => {
                consoleToLog('Error getClientsListApi: ', e.response);
            })
    }

    const getItemList = (invoice_account_id) => {

        getAllItemListApi(invoice_account_id)
            .then((respose) => {
                const res = respose.data;
                consoleToLog('Response getAllItemListApi: ', res);

                setOnboardingItemList(res);
            })
            .catch((e) => {
                consoleToLog('Error getAllItemListApi', e.response);
                if(e.response.data && e.response.data.message) {
                    //enqueueSnackbar(e.response.data.message, {variant: 'error'});
                    return;
                }
            })
    }

    const onCloseButtonClick = () => {
        const invoice_account_id = props.selectedAccount.id;

        invoiceAccountOnboardingUpdateApi(invoice_account_id, false)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response invoiceAccountOnboardingUpdateApi: ", res);
                enqueueSnackbar('Onboarding settings updated', {variant:'success'});

                let accountList = props.invoiceAccountsList.map((ia)=>{
                    if(ia.id === res.id) return res;
                    else return ia;
                });
                props.setInvoiceAccountsList(accountList);
                props.setSelectedInvoiceAccount(res);
                props.setSelectedDrawerState(SelectedDrawerState.SHOW_DASHBOARD);
                history.push(AppRoutes.DASHBOARD)
            })
            .catch((e) => {
                consoleToLog("invoiceAccountOnboardingUpdateApi error", e.response);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return false;
                }
            });

    }

    const handleDialogOpen = () => {
        setOpenDialog(true);
    }

    const handleDialogClose = () => {
        setOpenDialog(false);
    }

    const onNewInvoiceClick = () => {
        if(canCreateInvoice) {
            handleClickDialogOpen();
        }
    }

    const handleStepClick = (index) => {
        if(index < 5) {
            setTabsValue(index);
        } else {
            onNewInvoiceClick()
        }
    };

    // const handleStepClick = (index) => {
    //     setTabsValue(index);
    //     const stepElement = stepsRef.current[index];
    //     const stepRect = stepElement.getBoundingClientRect();
    //     const containerRect = stepElement.parentElement.getBoundingClientRect();
    //     const arrowLeft = stepRect.left + stepRect.width*2-120 - containerRect.left;
    //     setArrowPosition({ position:"relative", left: `${arrowLeft}px` });
    // };


    return (
        <div className={classes.mainContent}
            style={{
                height: setHeightForComponent(props.is_entity_email_unverified, accessLevelViewer, props.isInvoiceAccountPlanFree, props.entityLimitExceeded)
            }}>
                <Container className={classes.container} maxWidth="xl">
                <div className={classes.root} style={{paddingBottom:'24px'}}>
                    <Grid item className={classes.customColumn1A}>
                        <CardMedia className={classes.imageContainer}>
                            <img src={'/images/onboarding_welcome.svg'} className={classes.imageStyle2}/>
                        </CardMedia>
                    </Grid>
                    <Grid item style={{maxWidth:'3%', flexBasis:'3%', marginLeft:'auto'}}>
                        <IconButton
                            onClick={handleDialogOpen}
                            aria-expanded={expanded}
                            aria-label="show more"
                            className={classes.buttonStyle}
                        >
                            <ClearIcon fontSize='small' style={{color:'#555'}}/>
                        </IconButton>
                    </Grid>
                    <Grid item md={12}>
                        <Grid item container>
                            <Grid item md={12}>
                                <Typography className={classes.headingStyle1}>
                                    {`Greetings ${props.user.firstname}`}
                                </Typography>
                                <Typography className={classes.subtitleStyle1}>
                                    We appreciate your decision to become a part of eProcessify. To initiate your journey, we suggest you complete your profile.
                                </Typography>
                            </Grid>
                        </Grid>
                        <div style={{margin:'8px 0px 0px -64px'}}>
                            <Stepper alternativeLabel 
                                activeStep={tabsValue} 
                                connector={<ColorlibConnector />}
                                style={{background:'transparent'}}>
                                {steps.map((label, index) => (
                                <Step key={label} 
                                    ref={el => stepsRef.current[index] = el}
                                    style={{position:'relative', textAlign:'center'}}
                                    onClick={() => handleStepClick(index)}>

                                    <StepLabel index={index} StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                                    {tabsValue === index && <img  src="/images/up-arrow1.svg"  
                                        className='upArrow_styles'/>}
                                </Step>
                                ))
                                }
                            </Stepper>
                        </div>
                    </Grid>
                </div>


                <Grid item md={12}>
                    
                    <Paper variant='standard' className={classes.contentContainer} style={{zIndex:10, paddingBottom:tabsValue === 0 || tabsValue === 1 ?'16px' : '0px'}}>
                        <div>
                            <React.Suspense fallback={<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}><CircularProgress /></div>}>
                                {
                                    tabsValue === 0 &&
                                    <div style={{display:'flex', justifyContent:'center'}}>
                                        <OnboardingEntityUpdate  entityObj={entityObj}
                                            setEntityObj={setEntityObj}/>
                                    </div>
                                }

                                {
                                    tabsValue === 1 &&
                                    <TaxPresets fromOnboardingComponent={true}/>
                                }

                                {
                                    tabsValue === 2 &&
                                    <OnboardingNumberingSetup />
                                }
                                {
                                    tabsValue === 3 &&
                                            <ClientListComponent fromOnboardingComponent={true} 
                                                setOnboardingClientList={setOnboardingClientList}
                                                value={ClientsTabValue.CLIENTS}
                                            />
                                }

                                {
                                    tabsValue === 4 &&
                                            <InvoiceItemsComponent fromOnboardingComponent={true}
                                                setOnboardingItemList={setOnboardingItemList}
                                            />
                                }
                            </React.Suspense>
                        </div>
                    </Paper>
                </Grid>
                </Container>

                {
                    openDialog && 
                        <Dialog open={openDialog}
                        classes={{
                            paper: classes.MuiDialogpaper}}
                        fullWidth
                        onClose={handleDialogClose}>
                            <div style={{textAlign:"right", marginTop:'16px', marginRight:'16px'}}>
                                <Button onClick={handleDialogClose} style={{minWidth:'20px'}}>
                                    <ClearIcon style={{color:"#ccc"}}/>
                                </Button>
                            </div>
                            
                            <div style={{width:'100%', textAlign:'center', marginTop:'40px', position:'relative'}}>
                                <div>
                                    <ListAltIcon style={{fontSize:"36px", color:'red'}}/>
                                </div>

                                <div style={{marginTop:'24px'}}>
                                    <Typography variant='h4'>
                                        Close Onboarding?
                                    </Typography>
                                </div>

                                <div>
                                    <Typography className={classes.dialogTitle}>
                                        {'You can always bring back onboarding from Settings => Preferences'}
                                    </Typography>
                                </div>

                                <div style={{display:'flex', justifyContent:'center', alignItems:'center', marginTop:'54px'}}>
                                    <Grid item md={5}
                                        onClick={handleDialogClose}>
                                        <Button fullWidth variant='outlined' color='primary'>
                                            Cancel
                                        </Button>
                                    </Grid>

                                    <Grid item md={5} style={{marginLeft:'16px'}}>
                                        <Button 
                                            onClick={onCloseButtonClick}
                                            fullWidth variant='contained' color='primary'>
                                            Close
                                        </Button>
                                    </Grid>
                                </div>
                            </div>

                        </Dialog>
                }
        </div>
    );
}

const mapStateToProps = (state) => ({
    accessToken: state.invoiceAuth,
    selectedAccount: state.invoiceAccounts.selectedAccount,
    drawerState: state.drawerState.setDrawerState,
    user: state.invoiceAccounts.user,
    invoiceAccountsList: state.invoiceAccounts.invoiceAccountsList
});

const mapDispatchToProps = (dispatch) => ({
    setSelectedInvoiceAccount: (account) => dispatch(setSelectedInvoiceAccount(account)),
    setInvoiceAccountsList: (list) => dispatch(setInvoiceAccountsList(list)),
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState)),
    setUserObj: (user_obj) => dispatch(setUserObj(user_obj))
})

export default connect(mapStateToProps, mapDispatchToProps)(OutsideOnboardingComponent);