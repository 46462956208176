import React, {useState, memo, useCallback, useEffect, useRef, useLayoutEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import SetLabelPopover from './SetLabelPopover';
import CloseIcon from '@material-ui/icons/Close';
import {getDroppedComponentWidthAndHeight, getResponsiveFontSize, ItemTypes} from '../../util/AppUtil';
import {Typography, TextareaAutosize} from '@material-ui/core';
import { useIsMount } from '../useIsMount';

const useStyles = makeStyles((theme) => ({
    textAreaStyles: {
        resize:'none',
        fontFamily:'Open Sans',
        '&::-webkit-scrollbar':{
            display:'none'
        },
        '&:focus': {
        outline: 'none',
        },
        cursor: 'move',
        borderColor: '#2ee0af',
        backgroundColor: 'rgba(224, 255, 247, 0.5)',
    },
    resizeDotStyle: {
        position: 'absolute',
        right: '8px',
        bottom: '10px',
        width: 10,
        height: 10,
        background: '#4CAF50',
        cursor: 'se-resize',
        borderRadius: '50%',
        zIndex:100
    },
    setLabelStyle: {
        fontSize:'9px',
        position: 'absolute',
        top:0,
        left:0,
        color: '#007bfb',
        cursor:'pointer',
        opacity: 0.9,
        zIndex:100,
        fontWeight: 600,
        '&:hover': {
            opacity: 1
        }
    }
}))


const DroppedTextArea = memo(({ item, index, droppedItems, setDroppedItems, removeDroppedItem, pdfWidth, 
    pdfHeight, originalDimension, showCreateEditTemplateScreen, fromDateComponent, drag }) => {
    const classes = useStyles();
    const isMount = useIsMount();

    const [size, setSize] = useState({ width: 0, height: 0 });
    const [fontSize, setFontSize] = useState(14);
    const [labelFontSize, setLabelFontSize] = useState(9);
    const [anchorEl, setAnchorEl] = useState(null);
    const [dimensions, setDimension] = useState({width:0, height:0});

    const localRef = useRef(null);

    useEffect(() => {
        if (pdfHeight > 0 && originalDimension?.width) {
            const canvasEl = document.querySelectorAll('.react-pdf__Page__canvas')[item.page - 1];
            if (!canvasEl) return;

            const pageRect = canvasEl.getBoundingClientRect();
            const newFontSize = getResponsiveFontSize(11, pageRect.width, pageRect.height, originalDimension);
            const newLabelFontSize = getResponsiveFontSize(10, pageRect.width, pageRect.height, originalDimension);

            if (fontSize !== newFontSize) setFontSize(newFontSize);
            if (labelFontSize !== newLabelFontSize) setLabelFontSize(newLabelFontSize);

            const scaledDimensions = getDroppedComponentWidthAndHeight(136, 16, pageRect.width, pageRect.height, originalDimension);
            setDimension({
                width: scaledDimensions.new_component_width,
                height: scaledDimensions.new_component_height
            })

            if (item.coordinates?.topLeft && item.coordinates?.bottomRight) {
                const width = parseFloat(item.coordinates.bottomRight.x) - parseFloat(item.coordinates.topLeft.x);
                const height = parseFloat(item.coordinates.bottomRight.y) - parseFloat(item.coordinates.topLeft.y);
                setSize({ width, height });
            } else {
                //const scaledDimensions = getDroppedComponentWidthAndHeight(136, 16, pageRect.width, pageRect.height, originalDimension);
                setSize({
                    width: scaledDimensions.new_component_width,
                    height: scaledDimensions.new_component_height
                });
            }
        }
    }, [pdfWidth, pdfHeight, originalDimension]);

    useEffect(() => {
        const updateCoordinates = () => {
            const canvasRectArr = document.querySelectorAll('.react-pdf__Page__canvas');
            const canvasEl = canvasRectArr[item.page - 1];
            if (!localRef.current || !canvasEl || !showCreateEditTemplateScreen) return;
    
            const pageRect = canvasEl.getBoundingClientRect();
            const itemRect = localRef.current.getBoundingClientRect();
    
            const left = item.position.x;
            const top = item.position.y;
            const width = itemRect.width;
            const height = itemRect.height;
    
            // Store current canvas dimensions
            const currentCanvasWidth = pageRect.width;
            const currentCanvasHeight = pageRect.height;
    
            // If coordinates exist, maintain the same relative dimensions
            if (item.coordinates?.bottomRight && item.coordinates?.topLeft) {
                const prevLeft = parseFloat(item.coordinates.topLeft.x);
                const prevRight = parseFloat(item.coordinates.bottomRight.x);
                const prevTop = parseFloat(item.coordinates.topLeft.y);
                const prevBottom = parseFloat(item.coordinates.bottomRight.y);
    
                const existingWidth = prevRight - prevLeft;
                const existingHeight = prevBottom - prevTop;
    
                item.coordinates = {
                    topLeft: {
                        x: left,
                        y: top
                    },
                    bottomRight: {
                        x: left + existingWidth,
                        y: top + existingHeight
                    },
                    page: item.page,
                    canvasWidth: currentCanvasWidth,
                    canvasHeight: currentCanvasHeight
                };
            } else {
                item.coordinates = {
                    topLeft: { x: left, y: top },
                    bottomRight: { x: left + width, y: top + height },
                    page: item.page,
                    canvasWidth: currentCanvasWidth,
                    canvasHeight: currentCanvasHeight
                };
            }
        };
    
        const timeoutId = setTimeout(updateCoordinates, 0);
        return () => clearTimeout(timeoutId);
    }, [item.position]);

    useEffect(() => {
        // Get the target element by ID
        const textEl = document.getElementById(`${index}`);

        if (textEl && item.showLabelPopover) {
            // Simulate the element triggering a popover opening
            setAnchorEl(textEl);
            item.showLabelPopover = undefined;
        } 
    }, []);

    // useEffect(() => {
    //     // Show the label popover when the component mounts
    //     handleLabelPopoverOpen();
    // }, []); // Empty dependency array to run only on mount


    // useEffect(() => {
    //     if(item.component_label !== '' && item.value_json?.text !== '') {
    //         // Adjust height after the component mounts and after the text changes
    //         adjustHeight();
    //     }
    // }, [size.height, item.value_json?.text]); // Add size.height to dependencies to adjust height on resize

    const handleResize = useCallback((e, direction) => {
        e.stopPropagation();
        e.preventDefault();

        const startX = e.clientX;
        const startY = e.clientY;
        const startWidth = size.width;
        const startHeight = size.height;
    
        const handleMouseMove = (moveEvent) => {
            if (direction === 'se') {
                const canvasRectArr = document.querySelectorAll('.react-pdf__Page__canvas');
                const canvasEl = canvasRectArr[item.page - 1];
                if (!canvasEl) return;
    
                const pageRect = canvasEl.getBoundingClientRect();
                
                const newWidth = Math.max(40, startWidth + (moveEvent.clientX - startX));
                const newHeight = Math.max(dimensions.height, startHeight + (moveEvent.clientY - startY));
    
                setSize({
                    width: newWidth,
                    height: newHeight
                });
    
                const itemRect = localRef.current.getBoundingClientRect();
    
                const left = item.position.x;
                const top =  item.position.y;
                const right = left + newWidth;
                const bottom = top + newHeight;
    
                item.coordinates = {
                    topLeft: {
                        x: Math.max(0, left),
                        y: Math.max(0, top)
                    },
                    bottomRight: {
                        x: Math.min(pageRect.width, right),
                        y: Math.min(pageRect.height, bottom)
                    },
                    pageNumber: item.page,
                    canvasWidth: pageRect.width,
                    canvasHeight: pageRect.height
                };
                //console.log('item.coordinate', item);
            }
        };
    
        const handleMouseUp = () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    }, [size]);

    const handleLabelPopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleLabelPopoverClose = () => {
        setAnchorEl(null);
    };

    const handleChangeLabel = (e) => {
        const updatedFormElements = droppedItems;
        const newLabel = e.target.value;

        // Check for existing labels with the same component_type
        const existingItem = droppedItems.find((item, i) => 
            i !== index && item.component_label === newLabel && item.component_type === droppedItems[index].component_type
        );

        // If a matching item is found, copy its value_json.text to the current item
        if (existingItem) {
            updatedFormElements[index].component_label = newLabel;
            updatedFormElements[index].value_json = {...existingItem.value_json};
            setDroppedItems([...updatedFormElements]);
        } else {
            updatedFormElements[index].component_label = newLabel;
            setDroppedItems([...updatedFormElements]);
        }

    }

    const showLabelPopover = (index) => {
        return (
            <SetLabelPopover anchorEl={anchorEl}
                index={index}
                handleLabelPopoverClose={handleLabelPopoverClose}
                label={droppedItems[index]?.component_label}
                handleChangeLabel={handleChangeLabel}
                droppedItems={droppedItems}
                fromTextArea={true}
                component_type={droppedItems[index]?.component_type}
            />
        )
    }

    return (
    <div >
        {(showCreateEditTemplateScreen || item.value_json.text === '' || item.value_json.date === '') &&
        <div  id={`${index}`} onClick={handleLabelPopoverOpen}>
            <Typography className={classes.setLabelStyle}
                style={{ fontSize: `${labelFontSize}px` }}
            >
                {item.component_label ? `{{${item.component_label}}}` : 'Set Label'}
            </Typography>
        </div>}
        
        <textarea
            ref={(el) => {
                localRef.current = el;
                drag(el);
            }}
            value={showCreateEditTemplateScreen ? '' : fromDateComponent ? item.value_json?.date : item.value_json?.text}
            style={{ fontSize: `${fontSize}px`,  width: size.width, height: size.height, padding:0}}
            //onInput={adjustHeight}
            className={classes.textAreaStyles}
        />
        {showCreateEditTemplateScreen &&
            <div className={classes.resizeDotStyle} 
                onMouseDown={(e) => handleResize(e, 'se')}
            />
        }
        {showCreateEditTemplateScreen &&
            <div onClick={() => removeDroppedItem(index)} 
                className='remove_dropped_item' style={{top:'-8px', right:'-8px'}}>
                <CloseIcon className='dropped_item_close_icon'/>
            </div> 
        } 
        {showLabelPopover(index)}
    </div>
    );
});

export default DroppedTextArea;