import React from 'react';
import {Grid, Typography} from '@material-ui/core';
import {connect} from 'react-redux'; 
import {addSelectedStatusToInvoiceFilter, removeSelectedStatusFromInvoiceFilter} from '../../actions/invoiceFilters';
import {trackGAEvent} from '../../util/AppUtil';
import {addSelectedStatusToEstimateFilter, removeSelectedStatusFromEstimateFilter} from '../../actions/invoiceFilters';
import {addSelectedStatusToProposalFilter, removeSelectedStatusFromProposalFilter} from '../../actions/proposalFilters';
import { addSelectedStatusToDocumentFilter, removeSelectedStatusFromDocumentFilter } from '../../actions/documentFilters';

const StatusFilterComponent = (props) => {
    const invoiceStatusNameList = ["Open", "Partial", "Paid", "Cancelled"];
    const invoiceStatusValueList = ["open", "partial", "paid", "cancelled"];

    const estimateStatusNameList = ["Open", "Approved", "Cancelled", "Rejected", "Closed"];
    const estimateStatusValueList = ["open", "approved", "cancelled", "rejected", "closed"];

    const proposalStatusNameList = ["Draft", "Approved", "Rejected"];
    const proposalStatusValueList = ["draft", "approved", "rejected"];

    const invoiceStatusClassNames = ["sent__status", "partial__status", "paid__status", "cancelled_status", "approved__status", "rejected__status"];
    const invoiceStatusClassNames1 = ["sent__background", "partial__background", "paid__background", "cancelled__background", "approved__background", "rejected__background"]

    const estimateStatusClassNames = ["sent__status", "est_approved__status", "est_cancelled__status", "est_rejected__status", "est_closed__status"];
    const estimateStatusClassNames1 = ["sent__background", "est_approved__background", "est_cancelled__background", "est_rejected__background", "est_closed__background"]

    const proposalStatusClassNames = ["draft__status", "est_approved__status", "est_rejected__status"];
    const proposalStatusClassNames1 = ["draft__background", "est_approved__background", "est_rejected__background"]

    const documentStatusNameList = ["Draft", "Pending Signature", "Void", "Completed"];
    const documentStatusValueList = ["draft", "pending_signature", "void", "completed"];

    const documentStatusClassNames = ["doc_draft__status", "doc_pending__status", "doc_void__status", "doc_completed__status"];
    const documentStatusClassNames1 = ["doc_draft__background", "doc_pending__background", "doc_void__background", "doc_completed__background"]

    let statusNameList = []; //props.fromEstimatesComponent ? estimateStatusNameList : invoiceStatusNameList;
    let statusValueList = []; //props.fromEstimatesComponent ? estimateStatusValueList : invoiceStatusValueList;
    let statusClassNames = []; //props.fromEstimatesComponent ? estimateStatusClassNames : invoiceStatusClassNames;
    let statusClassNames1 = []; //props.fromEstimatesComponent ? estimateStatusClassNames1 : invoiceStatusClassNames1;

        if(props.fromProposalListComponent) {
            statusNameList = proposalStatusNameList;
            statusValueList = proposalStatusValueList;
            statusClassNames = proposalStatusClassNames;
            statusClassNames1 = proposalStatusClassNames1;
        } else if(props.fromEstimatesComponent) {
            statusNameList = estimateStatusNameList;
            statusValueList = estimateStatusValueList;
            statusClassNames = estimateStatusClassNames;
            statusClassNames1 = estimateStatusClassNames1;
        } else if(props.fromDocumentListComponent) {
            statusNameList = documentStatusNameList;
            statusValueList = documentStatusValueList;
            statusClassNames = documentStatusClassNames;
            statusClassNames1 = documentStatusClassNames1;
        } else {
            statusNameList = invoiceStatusNameList;
            statusValueList = invoiceStatusValueList;
            statusClassNames = invoiceStatusClassNames;
            statusClassNames1 = invoiceStatusClassNames1;
        }


    const statusClassName = (index) => {
        const STATUS_CLASS = `status__filterList ${statusClassNames1[index]}`;
        if(props.fromProposalListComponent) {
            return props.selectedStatusProposalFilter.includes(statusValueList[index]) ? `${STATUS_CLASS} active` : STATUS_CLASS;
        } else if(props.fromEstimatesComponent) {
            return props.selectedStatusEstimateFilter.includes(statusValueList[index]) ? `${STATUS_CLASS} active` : STATUS_CLASS;
        } else if(props.fromDocumentListComponent) {
            return props.selectedStatusDocumentFilter.includes(statusValueList[index]) ? `${STATUS_CLASS} active` : STATUS_CLASS;
        } else {
            return props.selectedStatusInvoiceFilter.includes(statusValueList[index]) ? `${STATUS_CLASS} active` : STATUS_CLASS;
        }
    }

    const onStatusClick = (selectedValue) => {
        let selectedStatus;
        if(props.fromProposalListComponent) {
            selectedStatus = props.selectedStatusProposalFilter.filter((status) => status === selectedValue);
            selectedStatus && selectedStatus.length > 0 ? props.removeSelectedStatusFromProposalFilter(selectedValue) : props.addSelectedStatusToProposalFilter(selectedValue);
            trackGAEvent(props.selectedAccount.name, 'Proposal filter - status filter changed');
        } else if(props.fromEstimatesComponent) {
            selectedStatus = props.selectedStatusEstimateFilter.filter((status) => status === selectedValue);
            selectedStatus && selectedStatus.length > 0 ? props.removeSelectedStatusFromEstimateFilter(selectedValue) : props.addSelectedStatusToEstimateFilter(selectedValue);
            trackGAEvent(props.selectedAccount.name, 'Estimate filter - status filter changed');
        } else if(props.fromDocumentListComponent) {
            selectedStatus = props.selectedStatusDocumentFilter.filter((status) => status === selectedValue);
            selectedStatus && selectedStatus.length > 0 ? props.removeSelectedStatusFromDocumentFilter(selectedValue) : props.addSelectedStatusToDocumentFilter(selectedValue);
            trackGAEvent(props.selectedAccount.name, 'Dcoument filter - status filter changed');
        } else {
            selectedStatus = props.selectedStatusInvoiceFilter.filter((status) => status === selectedValue);
            selectedStatus && selectedStatus.length > 0 ? props.removeSelectedStatusFromInvoiceFilter(selectedValue) : props.addSelectedStatusToInvoiceFilter(selectedValue);
            trackGAEvent(props.selectedAccount.name, 'Invoices filter - status filter changed');
        }
        
    }

    return (
        <div style={{marginTop:'18px'}}>
            <Typography style={{fontSize:'14px'}}>
                Status            
            </Typography> 
            <Grid container lg={12} style={{marginTop:'4px'}} >
                {statusNameList.map((status, index) => {
                        return <Grid item
                                    className={statusClassName(index)}
                                    style={{display:'flex', alignItems:'center'}}
                                    key={status}
                                    onClick={() => {
                                        onStatusClick(statusValueList[index])
                                        props.pageReset();
                                    }}
                                >
                            <span className={`circle ${statusClassNames[index]}`}></span> 
                            <Typography  style={{fontSize:'12px'}}>{status}</Typography>
                        </Grid>
                    })
                }
            </Grid> 
        </div>
    );
}

const mapStateToProps = (state) => ({
    accessToken: state.invoiceAuth,
    selectedStatusInvoiceFilter: state.invoiceFilters.selectedStatusInvoiceFilter,
    selectedStatusEstimateFilter: state.invoiceFilters.selectedStatusEstimateFilter,
    selectedAccount: state.invoiceAccounts.selectedAccount,

    selectedStatusProposalFilter: state.proposalFilters.selectedStatusProposalFilter,

    selectedStatusDocumentFilter: state.documentFilters.selectedStatusDocumentFilter
});

const mapDispatchToProps = (dispatch) => ({
    addSelectedStatusToInvoiceFilter: (item) => dispatch(addSelectedStatusToInvoiceFilter(item)),
    removeSelectedStatusFromInvoiceFilter: (item) => dispatch(removeSelectedStatusFromInvoiceFilter(item)),

    addSelectedStatusToEstimateFilter: (item) => dispatch(addSelectedStatusToEstimateFilter(item)),
    removeSelectedStatusFromEstimateFilter: (item) => dispatch(removeSelectedStatusFromEstimateFilter(item)),
    
    addSelectedStatusToProposalFilter: (item) => dispatch(addSelectedStatusToProposalFilter(item)), 
    removeSelectedStatusFromProposalFilter: (item) => dispatch(removeSelectedStatusFromProposalFilter(item)),

    addSelectedStatusToDocumentFilter: (item) => dispatch(addSelectedStatusToDocumentFilter(item)), 
    removeSelectedStatusFromDocumentFilter: (item) => dispatch(removeSelectedStatusFromDocumentFilter(item))
});

export default connect(mapStateToProps, mapDispatchToProps)(StatusFilterComponent);