import React, {useEffect, useRef, useCallback} from 'react';
import { useDrag } from 'react-dnd';
import DroppedTextArea from './DroppedTextArea';
import DroppedSignatureComponent from './DroppedSignatureComponent';
import { DroppedItemTypes } from '../../util/AppUtil';

const DroppedItemsComponent = ({item, index, droppedItems, setDroppedItems, pagesRef, showCreateEditTemplateScreen, pdfWidth, 
    pageHeight, originalDimension,
}) => {

    const [{ isDragging }, drag] = useDrag(() => ({
        type: DroppedItemTypes.DROPPED_ITEM,
        item: { ...item, index },
        collect: (monitor) => ({
        isDragging: monitor.isDragging()
        })
    }), [item, index]);

    const droppedItemRef = useRef(null);

    const removeDroppedItem = useCallback((index) => {
        const updatedFormElements = droppedItems.filter((_, i) => i !== index);
        setDroppedItems([...updatedFormElements]);
    }, [droppedItems, setDroppedItems]);

    //console.log('droppedItems component', droppedItems);

    return (
        <div
            key={item.item_id}
            style={{
                position: 'absolute',
                left: item.position?.x,
                top: item.position?.y,
                opacity: isDragging ? 0.5 : 1,
                cursor: showCreateEditTemplateScreen ? 'move' : 'auto' ,
                zIndex:100,
                pointerEvents: !showCreateEditTemplateScreen && 'none'
            }}
        >
            {item.component_type === DroppedItemTypes.TEXT && (
                <DroppedTextArea
                    item={item}
                    index={index}
                    droppedItems={droppedItems} 
                    setDroppedItems={setDroppedItems}
                    droppedItemRef={droppedItemRef}
                    removeDroppedItem={removeDroppedItem}
                    pdfWidth={pdfWidth}
                    pdfHeight={pageHeight}
                    originalDimension={originalDimension}
                    showCreateEditTemplateScreen={showCreateEditTemplateScreen}
                    drag={drag}
                />
            )
            }
            {item.component_type === DroppedItemTypes.SIGNATURE &&
            (
                <DroppedSignatureComponent item={item}
                    index={index}
                    droppedItems={droppedItems} 
                    setDroppedItems={setDroppedItems}
                    droppedItemRef={droppedItemRef}
                    removeDroppedItem={removeDroppedItem}
                    showCreateEditTemplateScreen={showCreateEditTemplateScreen}
                    pdfWidth={pdfWidth}
                    pdfHeight={pageHeight}
                    originalDimension={originalDimension}
                    pagesRef={pagesRef}
                    drag={drag}
                />
            )}

            {item.component_type ===  DroppedItemTypes.DATE &&
            (
                <DroppedTextArea
                    item={item}
                    index={index}
                    droppedItems={droppedItems} 
                    setDroppedItems={setDroppedItems}
                    droppedItemRef={droppedItemRef}
                    removeDroppedItem={removeDroppedItem}
                    pdfWidth={pdfWidth}
                    pdfHeight={pageHeight}
                    originalDimension={originalDimension}
                    showCreateEditTemplateScreen={showCreateEditTemplateScreen}
                    fromDateComponent={true}
                    drag={drag}
                />
            )}
        </div>
    );
};

export default DroppedItemsComponent