import React, {useState, useEffect, useLayoutEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Container, Grid, Typography, Button, Tooltip, ClickAwayListener, CircularProgress, Link, Divider} from '@material-ui/core';
import {connect} from 'react-redux';
import { consoleToLog, showDate, statusColor, trackGAEvent, AccessLevel, showListItemDate, AppRoutes } from '../../util/AppUtil';
import { useIsMount } from '../useIsMount';
import { SelectedDrawerState, setSelectedDrawerState } from '../../actions/drawer';
import { useSnackbar } from 'notistack';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { usePropsContext } from '../context';
import { history } from '../../routers/AppRouter';
import useRestoreComponentState from '../useRestoreComponentState';
import {storeComponentState} from '../../util/AppUtil';
import { getProposalListApi, deleteProposalApi, duplicateProposalApi } from '../../services/proposalService';
import ProposalTableRow from './ProposalTableRow';
import ShowConfirmModal from './ShowConfirmModal';
import FilterDrawer from '../FilterDrawer';


// Add this function at the beginning of your component or in a separate utility file
const updateProposalItem = (proposalItem, updatedProposalObj) => {
    
        return {
            ...proposalItem,
            can_edit: updatedProposalObj.can_edit,
            can_send: updatedProposalObj.can_send,
            client_name: updatedProposalObj.client.name,
            entity_name: updatedProposalObj.entity.name,
            status: updatedProposalObj.status,
            currency: updatedProposalObj.currency,
            name: updatedProposalObj.name,
            slug: updatedProposalObj.slug,
            total_amount: updatedProposalObj.total_amount
        };
};

const useStyles = makeStyles((theme) => ({
    mainContent : {
        transition: ".5s", 
        //overflow:'hidden',
        overflow:'auto',
        overflowX:'hidden',
        "&::-webkit-scrollbar": {
            display: "none"
        }
    },
    container: {
        marginTop: "8px"
    },
    MuiButtonlabel : {
        fontSize: "12px"
    },
    buttonPlusIcon: {
        marginRight:'6px', 
        fontSize:'1.2rem', 
        marginTop:'-2px'
    },
    icons : {
        color: "black",
        fontSize: "15px",
        minWidth:'20px',
        lineHeight: '10px',
    },
    headings: {
        fontWeight: '600',
        [theme.breakpoints.down(1400)]: {
            fontSize:'14px'
        },
    },
    invoiceListContainer : {
        background:'white', 
        minWidth:"100%", 
        borderRadius:'4px', 
        padding:'0px 16px 0px 16px', 
        color:'#2B2B2B',
    },
    invoiceListHover: {
        padding:'16px 0px',
        position:'relative',
        '& .action__icon' : {
            padding:'6px 4px 1px 4px',
            border:'1px solid white',
            borderRadius:'4px',
            position:'relative',
            top:'3px'
        },
        '&:hover .action__icon' : {
            border:'1px solid #ccc'
        },
        '&:hover':{
            cursor:"pointer"
        },
        '& .more_actionButton' : {
            visibility:'hidden',
            width:'150px',
            background:'white',
            border:'1px solid #ccc',
            borderRadius:'5px',
            fontSize:'14px',
            padding:'1px 0px',
        },
        '& .more_actionButton1' : {
            visibility:'hidden',
            width:'120px',
            background:'white',
            border:'1px solid #ccc',
            borderRadius:'5px',
            fontSize:'14px',
            padding:'1px 0px',
        },
        '& .more_actionButton2' : {
            visibility:'hidden',
            width:'180px',
            background:'white',
            border:'1px solid #ccc',
            borderRadius:'5px',
            fontSize:'14px',
            padding:'1px 0px',
            display:'flex',
            alignItems:'center'
        },
        '&:hover .more_actionButton' : {
            visibility:'visible'
        },
        '&:hover .more_actionButton1' : {
            visibility:'visible'
        },
        '&:hover .more_actionButton2' : {
            visibility:'visible'
        },
    },
    MuiPopoverpaper: {
        marginTop:'20px',
        marginLeft: "-88px",
        border: '1px solid #eef0f2',
        borderRadius: '6px',
        boxShadow: '0 4px 8px rgb(82 91 100 / 10%)',
        boxSizing: 'border-box',
        minWidth: '180px'
    },
    loading: {
        position:'relative',
        left:'50%',
        right:'50%',
        marginTop:'3%'
    },
    // customColumn2: {
    //     flexBasis:'26.5%', 
    //     maxWidth:'26.5%'
    // },
    // customColumn3: {
    //     flexBasis:'26.5%', 
    //     maxWidth:'26.5%'
    // },
    customColumn1: { //Invoice Number Column
        flexBasis:'4%', 
        maxWidth:'4%'
    },
    customColumn2: { //Status Column
        flexBasis:'9% !important',
        maxWidth:'9% !important',
    },
    proformaColumn2: {
        flexBasis:'8.5% !important',
        maxWidth:'8.5% !important'
    },
    customColumn3: { //Issue Date Column
        flexBasis: '12%',
        maxWidth: '12%',
    },
    proformaColumn3: { //Issue Date Column
        flexBasis: '11.5%',
        maxWidth: '11.5%',
    },
    customColumn4: { //Customer and Job Description Column
        flexBasis:'27.5%', 
        maxWidth:'27.5%'
    },
    proformaColumn4: { //Issue Date Column
        flexBasis: '23%',
        maxWidth: '23%',
    },
    customColumn5: { // Total amount and Due column
        flexBasis:'9%', 
        maxWidth:'9%'
    },
    customColumn6: { // last column for more action icon
        flexBasis:'2%', 
        maxWidth:'2%'
    },
    customColumn7: { // last column for more action icon
        flexBasis:'20.5%', 
        maxWidth:'20.5%'
    },
    customColumn8: { // last column for more action icon
        flexBasis:'9.5%', 
        maxWidth:'9.5%'
    },
    actionsIcons: {
        fontSize: '18px',
    },
    stickyTopBar : {
        position:'sticky', 
        top:0, 
        zIndex:'999', 
        backgroundColor:theme.palette.primary.light,
        
    },
    proformaTDSColumn1: {
        flexBasis:'8% !important',
        maxWidth:'8% !important',
    },
    proformaTDSColumn2: {
        flexBasis: '9%',
        maxWidth: '9%',
    },
    proformaTDSColumn3: {
        maxWidth: '24%',
        flexWidth:'24%',
    },
    statusCellWidth: {
        width:'78px',
        [theme.breakpoints.down(1470)] : {
            width: '88px',
        }
    },
    buttonContainer: {
        display:'flex',
        justifyContent:'flex-start',
        position:'absolute', 
        right:'44px',
        [theme.breakpoints.down(1950)] : {
            right:'42px',
        },
        [theme.breakpoints.down(1450)] : {
            right:'41px',
        }
    },
    invoiceTypeStyle: {
        fontSize:'10px',
        borderRadius: '4px !important',
        backgroundColor: '#f9f9f9 !important',
        border: '1px solid #ececec !important',
        padding:'3px',
        marginRight:'3px'
    },
    invoiceNoCol: {
        width:'60px',
        [theme.breakpoints.down(1470)] : {
            width:'65px',
        }
    }

}))

const Proposals =  (props) => {
    const classes = useStyles();
    const isMount = useIsMount();
    const {enqueueSnackbar} = useSnackbar();

    const [isActive, setIsActive] = useState(false);
    const [menuId, setMenuId] = useState('');
    //const [openDialog, setOpenDialog] = useState(false);
    const [proposalList, setProposalList] = useState([]);
    const [loading, setLoading] = useState(false);
    
    const [total, setTotal] = useState();
    const [isMoreLoading, setIsMoreLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [items, setItems] = useState([]);
    
    const { fullName, proposalsComponentState, setProposalsComponentState, handleProposalModalOpen, 
        updatedProposalObj, setUpdateProposalObj} = usePropsContext();

    const { setScrollContainer, saveState, restoreState } = useRestoreComponentState(proposalsComponentState, setProposalsComponentState);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [proposalItem, setProposalItem] = useState(undefined);
    const [apiLoading, setApiLoading] = useState(false);

    const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
    const [id, setId] = useState(props.selectedAccount?.id);

    const { access_level } = props.selectedAccount || {};
    const accessLevelViewer = (AccessLevel.getUserAccessLevelValue(access_level) === AccessLevel.VIEWER) ? true : false;
    const entityList = props.selectedAccount?.entities;
    const restoredState = restoreState();
    const invoice_account_id = props.selectedAccount?.id;

    useEffect(() => {
        if(!props.fromClientDetails) {
            document.title = `Proposal - ${fullName}`;
        }
    }, [])

    useLayoutEffect(() => {
        
        if (Object.keys(restoredState).length > 0) {
            setProposalList(restoredState.itemList);
            setItems(restoredState.items);
            setPage(restoredState.page);
            setTotal(restoredState.total);
        } 
    }, [restoredState]);
    
    useEffect(() => {
            if(Object.keys(restoredState).length === 0) {
                getProposalList();
            }
    }, [id]);

    useEffect(() => {
        setId(props.selectedAccount?.id)
    }, [props.selectedAccount?.id])

    useEffect(() => {
        if(!isMount) {
            if(id !== props.selectedAccount?.id) {
                setPage(1);
            }
        }   
    }, [props.selectedAccount?.id])


    useEffect(() => {
        if (!isMount) {
            if (isMoreLoading) {
                //consoleToLog('isMore loading api called')
                getProposalList();
            }
        }
    }, [isMoreLoading]);

    useEffect(() => {
        if (!isMount) {
                //consoleToLog('filter changed');
                getProposalList();
        }
    }, [props.proposalIssueStartDateFilter, props.proposalIssueEndDateFilter, props.selectedClientsProposalFilter, props.selectedEntitiesProposalFilter,
        props.selectedActivityStatusProposalFilter
    ]);


    useEffect(() => {
        if(!isMount) {
            if(updatedProposalObj && proposalList && proposalList.length > 0) {
                const proposalObjPresent = proposalList.find((proposalItem) => proposalItem.id === updatedProposalObj?.id);
                if(proposalObjPresent) {
                    const updatedProposalArr = proposalList.map((proposalItem) => 
                        proposalItem.id === updatedProposalObj?.id ? updateProposalItem(proposalItem, updatedProposalObj) : proposalItem
                    );
                    setProposalList([...updatedProposalArr]);
                    setUpdateProposalObj(undefined);
                } else {
                    //setProposalList(oldInvoices => [updatedInvoiceObj, ...oldInvoices]);
                    setUpdateProposalObj(undefined);
                }
            }
        }
    }, [updatedProposalObj, proposalList]);


    const pageReset = () => {
        setPage(1);
        // if(page === 1) {
        //     getProposalList();
        // }
    }

    let count = 0;
    if (props.selectedClientsProposalFilter && props.selectedClientsProposalFilter.length > 0) {
        count = count + 1
    }

    if(props.selectedStatusProposalFilter && props.selectedStatusProposalFilter.length < 6) {
        count = count + 1
    } 

    if (props.selectedEntitiesProposalFilter && props.selectedEntitiesProposalFilter.length > 0) {
        count = count + 1
    }

    if (props.selectedActivityStatusProposalFilter && props.selectedActivityStatusProposalFilter.length < 4) {
        count = count + 1
    }

    const getProposalList = async(pageNo) => {
        const invoice_account_id = props.selectedAccount?.id;
        const issue_start_date = props.proposalIssueStartDateFilter; 
        const issue_end_date = props.proposalIssueEndDateFilter; 
        const clients = props.selectedClientsProposalFilter && props.selectedClientsProposalFilter.length > 0 ? props.selectedClientsProposalFilter?.map((client) => client.id)?.join() : undefined;
        const status = props.selectedStatusProposalFilter && props.selectedStatusProposalFilter.length > 0 ? props.selectedStatusProposalFilter?.map((status) => status)?.join() : undefined;
        const entities = props.selectedEntitiesProposalFilter && props.selectedEntitiesProposalFilter.length > 0 ? props.selectedEntitiesProposalFilter.map((entity) => entity.id).join() : undefined;
        const activity_status = props.selectedActivityStatusProposalFilter && props.selectedActivityStatusProposalFilter.length > 0 ? props.selectedActivityStatusProposalFilter?.map((status) => status)?.join() : undefined;

        if(!isMoreLoading) setLoading(true);
        try {
            const response = await getProposalListApi(invoice_account_id, pageNo ? pageNo : page, issue_start_date, issue_end_date, status, entities, clients, activity_status);
            const res = response.data;
            consoleToLog('Response getProposalListApi: ', res);

            setItems(res.proposals);
            setLoading(false);

            if(isMoreLoading) {
                const newItems = proposalList.concat(res.proposals);
                setProposalList(newItems);
                setPage(page + 1);
                setIsMoreLoading(!isMoreLoading);
            } else {
                if(res.proposals.length === res.page_size && id === props.selectedAccount?.id) setPage(page + 1);
                setProposalList(res.proposals);
                setTotal(res.total);
            }
        } catch (e) {
            consoleToLog('Error getProposalListApi', e.response);
            setLoading(false);
            if (e.response && e.response.status !== 401 && e.response.data && e.response.data.message) {
                enqueueSnackbar( e.response.data.message, {variant:'error'});
                return false;
            }
        }
    }


    const handleOpenFilterDrawer = () => {
        setOpenFilterDrawer(true);
    }

    const handleCloseFilterDrawer = () => {
        setOpenFilterDrawer(false);
    }

    const closeOptionsMenu = () => {
        setIsActive(false);
        setMenuId('');
    }

    const loadMoreLogic = () => {
        return (
            <Grid item container>
                <Grid item lg={12}>
                {items.length === 20 && !isMoreLoading &&
                    <div style={{textAlign: "center", margin:'16px 0px'}}>
                        <Button type="button"
                            color="secondary"
                            variant="contained"
                            onClick={() => {
                                setIsMoreLoading(!isMoreLoading);
                            }}>Load More
                        </Button>
                    </div>}

                { isMoreLoading &&
                    <CircularProgress size={35} style={{position:'relative', left:'50%', right:'50%', margin:'16px 0px'}}/>
                }
                </Grid>
            </Grid>
        )
    }

    // const updateInvoiceListItem = () => {
    //     let updated_array = proposalList.map((proposal) => {
    //         if(proposal.id === paymentInvoiceObj.invoice_id) {
    //             return proposal = paymentInvoiceObj.proposal
    //         }
    //         return proposal;
    //     });

    //     setProposalList(updated_array);
    //     setPaymentInvoiceObj(undefined);
    // }

    

    const callDeleteProposalApi = async(invoice_account_id, proposal_id) => {

        closeOptionsMenu();

        setApiLoading(true);

        try {
            const response = await deleteProposalApi(invoice_account_id, proposal_id);
            const res = response.data;
            consoleToLog('Response deleteProposalApi: ', res);
            enqueueSnackbar(res.message, {variant: 'success'});

            const updated_array = proposalList.filter((proposal) => proposal.id !== proposal_id);
            setProposalList(updated_array);
            setApiLoading(false);

            trackGAEvent(props.selectedAccount.name, 'Proposal deleted', `${props.user.firstname} ${props.user.lastname}`);
            setTotal(total - 1);

            handleConfirmDialogClose();
            
        } catch (e) {
            consoleToLog('Error deleteProposalApi: ', e.response);
            setApiLoading(false);
            closeOptionsMenu();
            if(e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {variant:'error'});
                return;
            }
        }
    }

    const duplicateProposalClicked = async(e, proposal, closeOptionsMenu) => {
        e.stopPropagation();

        trackGAEvent(props.selectedAccount.name, 'Duplicate Proposal', `${props.user.firstname} ${props.user.lastname}`);
        history.push('/proposal/create', {proposalObj: proposal});
        handleProposalModalOpen();
        closeOptionsMenu();

        // closeOptionsMenu();
        // try {
        //     const response = await duplicateProposalApi(invoice_account_id, proposa_id);
        //     const res = response.data;
        //     consoleToLog('Response duplicateProposalApi: ', res);
        //     enqueueSnackbar('Proposal duplicated successfully', {variant:'success'});
        //     trackGAEvent(props.selectedAccount.name, 'Duplicate Proposal clicked', `${props.user.firstname} ${props.user.lastname}`)

        //     const obj = {
        //         ...res,
        //     }

        //     setProposalList([obj, ...proposalList]);
        // } catch (e) {
        //     consoleToLog('Error duplicateProposalApi: ', e.response);
        //     if(e.response.data && e.response.data.message) {
        //         enqueueSnackbar(e.response.data.message, {variant:'error'});
        //         return;
        //     }
        // }
    }

    

    const updateEstimateObjInArray = (res) => {
        const updatedArray = proposalList.map((proposal) => {
            if(proposal.id === res.id) {
                return {
                    ...proposal,
                    status: res.status,
                    bill_status: res.bill_status,
                    can_cancel: res.can_cancel,
                    can_close: res.can_close,
                    can_create_invoice: res.can_create_invoice,
                    can_edit: res.can_edit,
                    can_reopen: res.can_reopen,
                }
            }
            return proposal
        })
        setProposalList([...updatedArray]);
    }

    const handleConfirmDialogOpen = (e, proposal, closeOptionsMenu, obj) => {
        e.stopPropagation();
        setOpenConfirmDialog(true);
        let proposalObj = {
            ...obj,
            ...proposal
        }
        setProposalItem(proposalObj)
        closeOptionsMenu();
    }

    const handleConfirmDialogClose = () => {
        setOpenConfirmDialog(false);
        setProposalItem(undefined);
    }

    const storeProposalState = (fromEdit, fromCreate, proposal, showSendEmail) => {
        storeComponentState(fromEdit, fromCreate, proposalList, items, page, total, proposal, saveState, 'proposal', props, showSendEmail);
    }

    return (
        <div className={classes.mainContent}
            ref={(node) => setScrollContainer(node)}
            id='invoices-container'
            style={{
                height: '100%',
                backgroundColor: props.fromClientDetails ? '#ffffff' : '#f7f7f7'}}>

            <Container className={classes.container} 
                maxWidth="xl" style={{padding: "0px 16px"}}>

                {/* {loading 
                ?
                <Grid item container justifyContent='center' style={{marginTop:'32px'}}> 
                    <CircularProgress size={25} />
                </Grid>
                : */}
                <Paper variant='outlined' elevation={0} style={{marginTop:'10px', marginBottom:'24px', width:'100%'}}>
                    <TableContainer style={{height:'100%', overflow:'visible'}}>
                        <Table>
                        <TableHead style={{position:'sticky', top:'0px', zIndex:99, background:'#f7f7f7'}}>
                            <TableRow>
                                <TableCell className='tableheading_style'>Status</TableCell>
                                <TableCell className='tableheading_style'>Activity</TableCell>
                                <TableCell className='tableheading_style'>Issued</TableCell>
                                <TableCell className='tableheading_style'>Title</TableCell>
                                {entityList && entityList.length > 1 && <TableCell className='tableheading_style'>Entity</TableCell>}
                                <TableCell className='tableheading_style'>Client</TableCell>
                                <TableCell className='tableheading_style'>Amount</TableCell>
                                {!props.fromClientDetails &&
                                <TableCell align='center' className='tableheading_style'>
                                        <Tooltip title="Filters" arrow>
                                            <Button
                                                onClick={handleOpenFilterDrawer}  
                                                className={classes.icons}
                                                >
                                                {<img src='/images/filter_icon.svg' 
                                                className={(count && count > 0) ? `filter_icon active` : 'filter_icon'}/>}
                                                {/* <i className={(count && count > 0) ? `flaticon-filter active` : `flaticon-filter`}></i> */}
                                                {   count && count > 0 ?
                                                    <div className='filter__dot'></div> : <div></div>
                                                }
                                            </Button>
                                        </Tooltip>
                                </TableCell>}
                            </TableRow>
                        </TableHead>
                        {loading ?
                        <TableBody>
                            <TableRow hover className={classes.invoiceListHover}>
                                <TableCell colSpan={entityList && entityList.length > 1 ? 9 : 8} align='center'>
                                    <CircularProgress size={25} style={{margin:'24px 0px'}}/>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                        :
                        <TableBody>
                            {
                            proposalList && proposalList.length > 0 ? proposalList.map((proposal) => {
                                const currencyObj = proposal?.currency;
                                return (
                                    <ProposalTableRow key={proposal.id}
                                        proposal={proposal}
                                        entityList={entityList}
                                        accessLevelViewer={accessLevelViewer}
                                        currencyObj={currencyObj}
                                        user={props.user}
                                        selectedAccount={props.selectedAccount}
                                        storeProposalState={storeProposalState}
                                        handleConfirmDialogOpen={handleConfirmDialogOpen}
                                        duplicateProposalClicked={duplicateProposalClicked}
                                        fromClientDetails={props.fromClientDetails}
                                    />
                                );
                            })
                            :
                            <TableRow hover className={classes.invoiceListHover}>
                                <TableCell colSpan={entityList && entityList.length > 1 ? 9 : 8} align='center'>
                                    No Proposals Found
                                </TableCell>
                            </TableRow>
                            
                            }
                        </TableBody>
                        }
                        </Table>
                    </TableContainer>
                    {!loading && loadMoreLogic()}
                </Paper>

                {
                    openConfirmDialog &&
                    <ShowConfirmModal openConfirmDialog={openConfirmDialog}
                        handleConfirmDialogClose={handleConfirmDialogClose}
                        callDeleteProposalApi={callDeleteProposalApi}
                        proposalItem={proposalItem}
                        apiLoading={apiLoading}
                        fromProposalListComponent={true}
                    />
                }

                {
                    openFilterDrawer &&
                    <FilterDrawer openFilterDrawer={openFilterDrawer}
                        handleCloseFilterDrawer={handleCloseFilterDrawer}
                        pageReset={pageReset}
                        fromProposalListComponent={true}
                    />
                }
            </Container>
        </div>
    );
}

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
    proposalIssueStartDateFilter: state.proposalFilters.proposalIssueStartDateFilter,
    proposalIssueEndDateFilter: state.proposalFilters.proposalIssueEndDateFilter,
    selectedEntitiesProposalFilter: state.proposalFilters.selectedEntitiesProposalFilter,
    selectedClientsProposalFilter: state.proposalFilters.selectedClientsProposalFilter,
    selectedStatusProposalFilter: state.proposalFilters.selectedStatusProposalFilter,
    user: state.invoiceAccounts.user,
    drawerState: state.drawerState.setDrawerState,
    selectedActivityStatusProposalFilter: state.proposalFilters.selectedActivityStatusProposalFilter
});

export default connect(mapStateToProps)(Proposals);