import React, {useState, useEffect, useRef} from 'react';
import {Grid, Button, Typography, Link, CircularProgress, Box} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { consoleToLog, generateMd5ForApi, DocumentStatus, DroppedItemTypes } from '../../util/AppUtil';
import {clientDocumentPreviewApi, getDocumentByIdApi, signByClientApi} from '../../services/documentService';
import { useSnackbar } from 'notistack';
import { Spinner } from '../Spinner';
import {
    PictureAsPdf as PictureAsPdfIcon
} from '@material-ui/icons';
import {makeStyles} from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DroppedItemsPreview from './DroppedItemsPreview';
import { Document, Page, pdfjs } from "react-pdf";
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import {connect} from 'react-redux';
import { splitArray } from '../../util/AppUtil';
import SignatureModalComponent from './SignatureModalComponent';
import VerifyOtpModal from './VerifyOtpModal';
import {signByMemberApi, downloadDocumentPdfApi, clientDownloadDocumentPdfApi} from '../../services/documentService';

const useStyles = makeStyles((theme) => ({
    approvedProposal: {
        background:'#def7ec',
        color:'#222',
    },
    rejectedProposal: {
        background:'#FFEAEB',
        color:'#222',
    },
    statusIcons: {
        fontSize:"17px",
        position:'relative',
        top:'2px',
        right:'6px',
        '& .checkIcon' : {
            color:"green !important"
        },
        '& .cancelIcon' : {
            color:"red"
        }
    },
    checkIcon : {
        color:"#0e9f6e"
    },
    cancelIcon : {
        color:"red"
    },
    signedDocument: {
        background:'#def7ec',
        color:'#222'
    }
}))

const PreviewAndDocument = (props) => {
    const {enqueueSnackbar} = useSnackbar();
    const classes = useStyles();
    const containerRef = useRef(null)

    const [loading, setLoading] = useState(false);
    const [documentObj, setDocumentObj] = useState({});
    const [apiLoading, setApiLoading] = useState(false);
    const [downloadApiLoading, setDownloadApiLoading] = useState(false);
    const [droppedItems, setDroppedItems] = useState([]);
    const [pageHeight, setPageHeight] = useState(0);
    const [originalDimension, setOriginalDimension] = useState({width: 0, height: 0});
    const [containerWidth, setContainerWidth] = useState(0);
    const [numPages, setNumPages] = useState(0);
    const [openVerifyDialog, setOpenVerifyDialog] = useState(false);
    const [saveOtp, setSaveOtp] = useState(0);
    const [errorMessage, setErrorMessage] = useState('');
    const [uploadedPdf, setUploadedPdf] = useState(undefined);
    const {encrypted_url, document_uuid} = useParams();
    const [pdfRendered, setPdfRendered] = useState(false);

    const urlParts = window.location.pathname?.split('/');
    const pathname = urlParts[1];
    

    const url = new URL(window.location.href);
    const emailParam = url.searchParams.get('email');
    const urlkeyParam = url.searchParams.get('url_key');
    const email = decodeURIComponent(emailParam).replace(/[\[\]']/g, '').replace(/\s/g, '');
    const urlkey = decodeURIComponent(urlkeyParam).replace(/[\[\]']/g, '').replace(/\s/g, '');
    const status = documentObj?.status;

    const signComponentObj = droppedItems.find((item) => item.component_type === DroppedItemTypes.SIGNATURE && item.value_json.email === email);
    const isSigned = signComponentObj?.value_json.is_signed;

    useEffect(() => {
        setLoading(true);

        if(pathname === 'member') {
            callDocumentByIdApi();
        } else {
            callClientDocumentPreviewApi();
        }

    }, []);
    
    useEffect(() => {
        if (containerRef.current) {
            setContainerWidth(containerRef.current.offsetWidth);
        }
        
    }, [containerRef.current]);

    useEffect(() => {
        if (uploadedPdf && pdfRendered) {
            const canvasRectArr = document.querySelectorAll('.react-pdf__Page__canvas');
            const canvasEl = canvasRectArr[0];
            const pageRect = canvasEl?.getBoundingClientRect();
            const splitTransformedArray = splitArray(documentObj.components, pageRect.width, pageRect.height);
            setDroppedItems([...splitTransformedArray]);
            setPdfRendered(false);
            handleSignDialogClose();
        }
        
    }, [pdfRendered]);

    const [openSignDialog, setOpenSignDialog] = useState(false);

    const handleSignDialogOpen = () => {
        setOpenSignDialog(true);
    }

    const handleSignDialogClose = () => {
        setOpenSignDialog(false);
    }

    const handleVerifyDialogOpen = () => {
        setOpenVerifyDialog(true);
    }

    const handleVerifyDialogClose = () => {
        setOpenVerifyDialog(false);
    }

    const onSignButtonClick = () => {
        if(pathname === 'client' && !saveOtp) {
            handleVerifyDialogOpen();
        } else {
            handleSignDialogOpen();
        }

        if(pathname === 'member') {
            handleSignDialogOpen();
        }
    }



        const callClientDocumentPreviewApi = async() => {
            const md5_hash = generateMd5ForApi(`${process.env.REACT_APP_BASE_URL}/client/${encrypted_url}/document/${document_uuid}/preview`);
            consoleToLog('preview md5 hash: ', md5_hash, email);

            try {
                const response =  await clientDocumentPreviewApi(md5_hash, encrypted_url, document_uuid)
                const res = response.data;
                consoleToLog('Response clientDocumentPreviewApi: ', res);
                setDocumentObj({...res});
                setUploadedPdf(res.generated_pdf_path_url);
                // const splitTransformedArray = splitArray(res.components);
                // setDroppedItems([...splitTransformedArray]);
                setErrorMessage('');
                setLoading(false);

            } catch(e) {
                consoleToLog('Error clientDocumentPreviewApi: ', e.response);
                    if(e.response.data && e.response.data.message) {
                        setErrorMessage(e.response.data.message);
                        //enqueueSnackbar(e.response.data.message, {variant:'error'})
                        return;
                    }
                    setLoading(false);
            }
        }

        const callDocumentByIdApi = async() => {
            const invoice_account_id = props.selectedAccount?.id;
            try {
                const response =  await getDocumentByIdApi(invoice_account_id, document_uuid)
                const res = response.data;
                consoleToLog('Response getDocumentById: ', res);
                setDocumentObj({...res});
                setUploadedPdf(res.generated_pdf_path_url);
                // const splitTransformedArray = splitArray(res.components);
                // setDroppedItems([...splitTransformedArray]);
                setLoading(false);

            } catch(e) {
                consoleToLog('Error getDocumentById: ', e.response);
                    setLoading(false);
                    if(e.response.data && e.response.data.message) {
                        enqueueSnackbar(e.response.data.message, {variant:'error'})
                        return;
                    }
            }
        }

    const onDocumentLoadSuccess = (pdfObj) => {
        const {numPages} = pdfObj
        setNumPages(numPages);
    }

    const callSignByMemberApi = async(signFileObj) => {
        const invoice_account_id = props.selectedAccount?.id;
        try {
            const response =  await signByMemberApi(invoice_account_id, document_uuid, email, signFileObj)
            const res = response.data;
            setDocumentObj({...res});
            consoleToLog('Response signByMemberApi: ', res);
            setUploadedPdf(res.generated_pdf_path_url);
            // const splitTransformedArray = splitArray(res.components);
            // setDroppedItems([...splitTransformedArray]);
            handleSignDialogClose();
        } catch(e) {
            consoleToLog('Error signByMemberApi: ', e.response);
                setApiLoading(false);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant:'error'})
                    return;
                }
        }
    }

    const callSignByClientApi = async(signFileObj) => {
        const md5_hash = generateMd5ForApi(`${process.env.REACT_APP_BASE_URL}/document/${documentObj?.id}/sign`);
        try {
            const response =  await signByClientApi(md5_hash, documentObj?.id, email, signFileObj, saveOtp)
            const res = response.data;
            setDocumentObj({...res});
            consoleToLog('Response signByClientApi: ', res);
            setUploadedPdf(res.generated_pdf_path_url);
            // const splitTransformedArray = splitArray(res.components);
            // setDroppedItems([...splitTransformedArray]);
            handleSignDialogClose();
        } catch(e) {
            consoleToLog('Error signByClientApi: ', e.response);
                setApiLoading(false);
                setSaveOtp(0);
                handleSignDialogClose();
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant:'error'})
                    return;
                }
        }
    }

    const onDownloadDocumentClick = () => {
        if(pathname === 'member') {
            callMemberDocumentDownloadApi();
        } else {
            callClientDocumentDownloadApi();
        }
    }

    const callMemberDocumentDownloadApi = async() => {
        const invoice_account_id = props.selectedAccount?.id;
        setDownloadApiLoading(true);
        
        try {
            const response = await downloadDocumentPdfApi(invoice_account_id, document_uuid);
            const res = response.data;
            consoleToLog("Response downloadDocumentPdfApi", res);
            setDownloadApiLoading(false);

            document.getElementById('document_download').href = res.pdf_signed_url;
            document.getElementById('document_download').click();

        } catch(e) {
            consoleToLog("Error downloadDocumentPdfApi", e);
            setDownloadApiLoading(false);
            enqueueSnackbar('Error downloading pdf', {variant:'error'});
            if(e.response.data && e.response.data.message) {
                return;
            }
        }
    }

    const callClientDocumentDownloadApi = async() => {
        const md5_hash = generateMd5ForApi(`${process.env.REACT_APP_BASE_URL}/client/${encrypted_url}/document/${documentObj?.id}/download`);

        setDownloadApiLoading(true);
        
        try {
            const response = await clientDownloadDocumentPdfApi(md5_hash, encrypted_url, document_uuid);
            const res = response.data;
            consoleToLog("Response clientDownloadDocumentPdfApi", res);
            setDownloadApiLoading(false);

            document.getElementById('document_download').href = res.pdf_signed_url;
            document.getElementById('document_download').click();

        } catch(e) {
            consoleToLog("Error clientDownloadDocumentPdfApi", e);
            setDownloadApiLoading(false);
            enqueueSnackbar('Error downloading pdf', {variant:'error'});
            if(e.response.data && e.response.data.message) {
                return;
            }
        }
    }


    return (
        <div style={{position:"relative"}}>
            {loading ?
            errorMessage !== ''
            ?
            <Box display='flex' justifyContent='center' 
                flexDirection='column' 
                alignItems='center' 
                height='100vh'>

                <div style={{width:'400px'}}>
                    <img src='/images/no_pdf_fallback_image.svg' width={'100%'} />
                </div>
                <Typography variant='h6' style={{textAlign:'center', width:'50%', marginTop:'16px'}}>
                    {errorMessage}
                </Typography>
                
            </Box>
            :
            <Spinner />
            :
            <div style={{display:'flex', justifyContent:'center', overflow:'auto', background:'#f7f7f7'}}>
                <Box width={'60%'}  position='relative'  height={signComponentObj ? '90vh' : '100vh'}>
                {uploadedPdf &&
                <div className="pdf_container" style={{justifyContent:'space-between'}} ref={containerRef}>
                    <Document file={uploadedPdf} onLoadSuccess={onDocumentLoadSuccess}>
                    {numPages > 0 && Array.from(new Array(numPages), (el, index) => (
                        <div 
                            key={`page_${index + 1}`}
                            style={{ position: 'relative' }}
                        >
                            <Page
                                key={`page_${index + 1}`}
                                pageNumber={index + 1}
                                renderTextLayer={false}
                                onRenderSuccess={(page) => {
                                    if(pageHeight === 0 && index === 0) {
                                        const height = page.height;
                                        setPageHeight(height);
                                    }
    
                                    if(originalDimension?.width === 0 && index === 0) {
                                        setOriginalDimension({
                                            width: page.originalWidth,
                                            height: page.originalHeight
                                        });
                                    }
                                    setPdfRendered(true);
                                }}
                            />
                            <div className="pdf-overlay" style={{ 
                                position: 'absolute', 
                                top: 0, 
                                left: 0,
                                width: '100%',
                                height: '100%',
                                pointerEvents: 'none'
                            }}>
                                {containerWidth > 0 && originalDimension.width > 0 && originalDimension.height > 0 &&
                                droppedItems.length > 0 &&  droppedItems
                                    .filter(item => item?.coordinates?.page === index + 1 && item.component_type === DroppedItemTypes.SIGNATURE && !item.value_json.is_signed)
                                    .map((item) => (
                                        <DroppedItemsPreview
                                            key={item.id}
                                            item={item}
                                            pdfWidth={containerWidth}
                                            pdfHeight={pageHeight}
                                            originalDimension={originalDimension}
                                        />
                                    ))}
                            </div>
                        </div>
                    ))}
                    </Document>
                </div>}
                </Box>

            </div> 
            }

            {!loading && documentObj?.id && signComponentObj &&
            <div className={`proposals_footer_buttons ${isSigned && classes.signedDocument}`}>

                {isSigned &&
                <Grid item container justifyContent='center'>
                    <Typography style={{textAlign:'center !important', fontWeight:600}}>
                        You have signed this document
                    </Typography>
                </Grid>
                }

                {signComponentObj &&
                <Grid item>
                    <Grid item container alignItems='center' justifyContent='flex-end' spacing={1}>
                        {documentObj && documentObj?.status === DocumentStatus.COMPLETED && 
                        <Grid item>
                            <Button 
                                onClick={onDownloadDocumentClick}
                                style={{background:'#fff'}}
                                className='footer_btn footer_btn_cancel'>
                                {downloadApiLoading ? <CircularProgress size={15} style={{marginRight:'5px'}}/> : <PictureAsPdfIcon fontSize='small'
                                    style={{marginRight:'5px', fontSize:'16px'}} />}
                                Download
                                <Link
                                    style={{ display: "none" }}
                                    id={"document_download"}
                                    target="_blank"
                                    onClick={(e) => e.stopPropagation()}
                                    download
                                ></Link> 
                            </Button>
                        </Grid>}

                            
                        {pathname === 'member' 
                        ? 
                        !isSigned &&
                        <Grid item>
                            <Button 
                                onClick={handleSignDialogOpen}
                                className='footer_btn approveButton'>
                                
                                Sign
                            </Button>
                        </Grid>
                        :
                        documentObj?.status !== DocumentStatus.COMPLETED && !isSigned &&
                        <Grid item>
                            <Button 
                                onClick={onSignButtonClick}
                                className='footer_btn approveButton'>
                                Sign
                            </Button>
                        </Grid>
                        }
                        
                        
                    </Grid>
                </Grid>}
            </div>}

            {openSignDialog &&
                <SignatureModalComponent openSignDialog={openSignDialog}
                    handleSignDialogClose={handleSignDialogClose}
                    fromDocumentPreview={true}
                    callSignByMemberApi={callSignByMemberApi}
                    pathname={pathname}
                    callSignByClientApi={callSignByClientApi}
                    />
            }

            {openVerifyDialog &&
                <VerifyOtpModal openVerifyDialog={openVerifyDialog}
                    handleVerifyDialogClose={handleVerifyDialogClose}
                    fromDocumentPreview={true}
                    email={email}
                    documentObj={documentObj}
                    saveOtp={saveOtp}
                    setSaveOtp={setSaveOtp}
                    handleSignDialogOpen={handleSignDialogOpen}
                />
            }
        </div>
    );
}

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount
});

export default connect(mapStateToProps)(PreviewAndDocument);