import React, {useState, memo, useCallback} from 'react';
import {Grid, Typography, FormControlLabel, Checkbox, TextField, Button, MenuItem} from '@material-ui/core';
import { DroppedItemTypes } from '../../util/AppUtil';

const SignatureValueComponent = memo(({element, index, droppedItems, setDroppedItems, handleSignDialogOpen, 
    accountMembersList, loggedInUser}) => {

    let img_path = element.value_json?.signature_image_path_url;

    const handleSelfSignChange = useCallback((e) => {
        const updatedFormElements = droppedItems;
        const value = e.target.checked;
        updatedFormElements.forEach((item, i) => {
            if (item.component_type === DroppedItemTypes.SIGNATURE && item.component_label === updatedFormElements[index].component_label) {
                updatedFormElements[i].value_json.is_member = value;
                if(!value) {
                    updatedFormElements[i].value_json.signature_image_path = '';
                    updatedFormElements[i].value_json.signature_image_path_url = '';
                    updatedFormElements[i].value_json.member_id = null;
                } 
            }
        });
        setDroppedItems([...updatedFormElements]);
    }, [droppedItems, setDroppedItems]);

    const handleChange = useCallback((e, setMemberId) => {
        const { name, value } = e.target;
        const memberObj = accountMembersList.find((member) => member.user.id === value);
        const member_id = setMemberId && value;
        const updatedFormElements = droppedItems;
        updatedFormElements.forEach((item, i) => {
            if (item.component_type === DroppedItemTypes.SIGNATURE && item.component_label === updatedFormElements[index].component_label) {
                if(setMemberId) {
                    if(member_id === loggedInUser?.id) {
                        updatedFormElements[i].value_json.signature_image_path = memberObj.user.signature_path;
                        updatedFormElements[i].value_json.signature_image_path_url = memberObj.user.signature_path_url;
                    }
                    updatedFormElements[i].value_json.member_id = member_id;
                } else {
                    updatedFormElements[i].value_json.member_id = null;
                    // updatedFormElements[i].value_json.signature_image_path = null;
                    // updatedFormElements[i].value_json.signature_image_path_url = null;
                    updatedFormElements[i].value_json[name] = value;
                }
            }
        });
        setDroppedItems([...updatedFormElements]);
    }, [droppedItems, setDroppedItems]);

    const onSignButtonClick = () => {
        const memberObj = accountMembersList.find((member) => member.user.id === loggedInUser?.id);
        if(memberObj && memberObj.user.signature_path) {
            const updatedFormElements = droppedItems;
            updatedFormElements.forEach((item, i) => {
                if (item.component_type === DroppedItemTypes.SIGNATURE && item.component_label === updatedFormElements[index].component_label) {
                    updatedFormElements[i].value_json.signature_image_path = memberObj.user.signature_path;
                    updatedFormElements[i].value_json.signature_image_path_url = memberObj.user.signature_path_url;
                } 
            });
            setDroppedItems([...updatedFormElements]);
        } else {
            handleSignDialogOpen(index)
        }
    }

    return (
        <div>
            <Grid item container alignItems='center' justifyContent='space-between'>
                <Grid item>
                    <Typography style={{marginBottom:'4px', fontSize:"14px"}}>
                        {element.component_label}
                    </Typography>
                </Grid>
                <Grid item>
                    <FormControlLabel
                        control={
                        <Checkbox
                            name="checkedB"
                            color="primary"
                            onClick={(e) => e.stopPropagation()}
                            onChange={(e) => handleSelfSignChange(e)}
                            checked={droppedItems[index].value_json.is_member}
                            style={{marginLeft:'8px'}}
                        />
                        }
                        label={`Member Signature`}
                    />
                </Grid>
            </Grid>
            {img_path ? (
                <div className='dropped_sign_image_container'>
                    <img
                        src={img_path}
                        alt="signature"
                        className='sign_image'
                    />
                    <div style={{position:'absolute', top:8, right:8}}
                        onClick={() => handleSignDialogOpen(index)}
                        >
                        <Typography style={{color:'#007bfb', fontSize:'10px', cursor:'pointer'}}>
                            Edit
                        </Typography>
                    </div>
                </div>
            ) : (
                element.component_label !== '' && (
                    <div>
                        <div className='dropped_sign_image_container'>
                            <Button 
                                variant='contained' 
                                color='primary'
                                style={{margin:'0px auto'}}
                                onClick={onSignButtonClick}
                                disabled={!droppedItems[index].value_json.is_member || droppedItems[index].value_json.member_id !== loggedInUser.id}
                            >
                                Sign
                            </Button>
                        </div>
                        {!droppedItems[index].value_json.is_member ?
                        <TextField  
                            variant='outlined'
                            fullWidth
                            margin='dense'
                            placeholder='Name'
                            name='name'
                            value={droppedItems[index].value_json.name}
                            onChange={(e) => handleChange(e, false)}
                            style={{background:'#fff'}}
                        />
                        :
                        <TextField  
                            fullWidth
                            margin="dense"
                            style={{transform:'translateY(-1.5px)', background:'#fff'}}
                            variant="outlined"
                            select
                            value={droppedItems[index].value_json.member_id}
                            onChange={(e) => handleChange(e, true)}
                            name='name'
                        >
                            {
                                accountMembersList && accountMembersList.map((member) => {
                                return  <MenuItem key={member.user.id} value={member.user.id}>
                                            {member.user.id === loggedInUser.id ? 'Me' : `${member.user.firstname} ${member.user.lastname}`} 
                                        </MenuItem>
                                })
                            }

                        </TextField>
                            
                        }

                        {!droppedItems[index].value_json.is_member &&
                        <TextField  
                            variant='outlined'
                            fullWidth
                            margin='dense'
                            placeholder='Email'
                            name='email'
                            value={droppedItems[index].value_json.email}
                            onChange={(e) => handleChange(e)}
                            style={{background:'#fff'}}
                        />
                        }
                    </div>
                )
            )}
        </div>
    );
});

export default SignatureValueComponent;