import { Button, Grid, makeStyles, Popover, Typography } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useState } from "react";
import { connect } from "react-redux";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ClearIcon from '@material-ui/icons/Clear';
import { formatAmount } from "../util/currencyUtil";

const useStyles = makeStyles((theme) => ({
   taxItemStyle: {
        padding: "8px 8px", 
        cursor: 'pointer',
        '&:hover': {
            background: '#f5f5f5'
        }
    },
    taxItems: {
        width:'auto',
        display: 'inline-block',
        border: '1px solid transparent',
        borderRadius: '6px',
        background: '#e3e8f5',
        fontSize: '9px',
        color: '#3c51be',
        whiteSpace: 'nowrap',
        fontWeight: 500,
        marginLeft:'8px',
        paddingLeft: "4px",
        marginBottom: "4px",
        '&:first-child' : {
            marginLeft:'0px'
        },
        '&:hover': {
            background: 'rgba(60, 82, 190, 0.108)'
        }
    },
}))


const ApplyTax = (props) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;
    const [showAddTaxMenu, setShowAddTaxMenu] = useState(false);
    const [taxPresetsArr, setTaxPresetsArr] = useState(props.taxPresetsArr?props.taxPresetsArr:[]);
    const [openDrawer, setOpenDrawer] = useState(false);
    

    const handleDrawerOpen = (e) => {
        setOpenDrawer(true);
    }

    const onAddTaxClick = (event) => {
        //console.log("Apply tax click", showAddTaxMenu);
        setAnchorEl(anchorEl ? null : event.currentTarget);
        setShowAddTaxMenu(!showAddTaxMenu);
        /* if(dropdownIndex !== undefined && anchorEl) {
            setDropDownIndex(undefined);
            setAnchorEl(null);
        } */
    }

    const displayTaxAmount = (rate, invoiceItem/* , index */) => {
        //let itemQuantity = invoiceItem.quantity ? invoiceItem.quantity : 0;
        let itemCost = invoiceItem.amount ? invoiceItem.amount : 0;

        // const discount_type = invoiceItem?.discount_type;
        // const discount_value = invoiceItem?.discount_value;
        // const taxValue = (rate * itemCost * itemQuantity) / 100;

        let calTax=0;
        calTax= (rate * itemCost) / 100;
        return parseFloat(calTax.toFixed(2));

        /* if(discount_value > 0) {
            let calTax=0; 
            if(discount_type === DiscountTypes.PERCENT){ 
                calTax=taxValue - taxValue * (discount_value / 100);
            }else{ 
                calTax=taxValue - (rate * discount_value)/100
            }
            return parseFloat(calTax.toFixed(2)); 
        } else {
            let calTax=0;
            calTax= (rate * itemCost * itemQuantity) / 100;
            return parseFloat(calTax.toFixed(2));
        } */
    }

    const onTaxItemClick=(item, tax)=>{
        let localBillItem=item;
        let {rate, id, name} = tax;
        let newObj = {
            tax_preset_id: id,
            name: name,
            rate: rate,
            amount: (localBillItem.amount*rate)/100
        }
        //setSelectedTaxes([...selectedTaxes, tax]);
        localBillItem.tax=[...localBillItem.tax, newObj];
        props.updateLineItem(localBillItem, props.index);

        setShowAddTaxMenu(false);
        setAnchorEl(null);
    }

    const onDeleteAppliedTaxItem = (item, tax)=>{
        let localBillItem=item;
        localBillItem.tax=localBillItem.tax.filter((t)=>t.tax_preset_id !== tax.tax_preset_id);

        props.updateLineItem(localBillItem,  props.index);
    }

    return(
        <Grid style={{marginBottom: "16px", position: "relative"}}>
        <Button className="dd-button"
            onClick={(event) => onAddTaxClick(event)}
            style={{marginRight:'-6px'}}
        >
            Apply Tax
            <ExpandMoreIcon className="arrow_down_icon"/>
        </Button>
        {
        showAddTaxMenu && 
            <Popover 
                anchorEl={anchorEl}
                open={open}
                onClose={()=>{
                    setShowAddTaxMenu(false);
                    setAnchorEl(false);
                }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                style={{height:'auto', maxHeight:'330px'}}
                >
                <>
                {
                    <Button style={{width:'250px', margin: "8px 8px 8px 16px"}}
                        onClick={()=>{
                            setShowAddTaxMenu(false);
                            setAnchorEl(false);
                            props.handleDrawerOpen();
                        }}
                        variant="outlined" color="secodary">
                        <AddCircleOutlineIcon fontSize='small'
                            style={{marginRight:'8px',
                                    fontSize: '16px'
                                    }}
                        />
                        Create Preset
                    </Button>
                }
                    {
                        <Typography className={classes.taxPresetHeading} 
                            style={{fontWeight: "bold", marginLeft: "8px", marginTop: "8px", marginBottom: "8px", fontSize:"14px"}}>
                            TAX PRESET
                        </Typography>
                    }
                    {
                        (taxPresetsArr && taxPresetsArr.length>0) && taxPresetsArr.map((tax, i) => {
                        return <Grid container justifyContent="space-between" 
                                    key={tax.id} 
                                    onClick={(e) => onTaxItemClick(props.billLineItem,tax)}
                                    className={classes.taxItemStyle}>
                                <Typography variant="subtitle1">
                                    {tax.name}
                                </Typography>
                                <Typography variant="subtitle1">
                                    {`${(tax.rate).toFixed(2)}%`}
                                </Typography>
                            </Grid>
                        })
                    }
                </>
            </Popover>
        }

        {
            props.billLineItem?.tax.map((tax) => {
            return <Grid item key={tax.tax_preset_id} 
                        style={{paddingTop:0, paddingBottom:0}}
                        className={classes.taxItems}>

                        <Typography variant='subtitle1'
                            className='d_flex center'
                        >
                                {`${tax.name} ${tax.rate}% (${formatAmount(tax.amount/* displayTaxAmount(tax.rate, props.billLineItem) */, props.currencyObj)})`}
                            <ClearIcon
                                onClick={(e) => {
                                    onDeleteAppliedTaxItem(props.billLineItem,tax)
                                    //getTaxDetails(tax);
                                }}
                                className="clear_icon"
                            />
                        </Typography>
                    </Grid>
            })
        }
            
    </Grid>
    )
}

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
    user: state.invoiceAccounts.user,
    tdsTypeList: state.invoiceAccounts.tdsTypeList,
});

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(ApplyTax);