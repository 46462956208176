import React, {useState, useEffect, useLayoutEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Container, Button, Typography, Tooltip, CircularProgress} from '@material-ui/core';
import {deleteBillPaymentTransactionApi, getBillTransactionsListApi, getVendorTransactionsApi} from '../services/authService';
import {connect} from 'react-redux';
import {consoleToLog, showDate, showPaymentMethodString, trackGAEvent} from '../util/AppUtil';
import { useIsMount } from './useIsMount';
import { useSnackbar } from 'notistack';
import TransactionsFilterDrawer from './TransactionsFilterDrawer';
import { logout } from '../actions/invoiceAuth';
import { clearSelectedInvoiceFilters } from '../actions/invoiceFilters';
import { setSelectedDrawerState } from '../actions/drawer';
import { clearSelectedTransactionFilters } from "../actions/transactionFilters";
import {setClearInvoiceAccounts} from '../actions/invoiceAccount';
import {AccessLevel, showListItemDate} from '../util/AppUtil';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {OverlayMessage} from '../util/AppUtil'; 
import { setOvelayMessage, setOverlayToken, setOverlayView, OverlayView, setApiRoute } from '../actions/overlay';
import {getClientTransactionsApi} from '../services/authService';
import {downloadReceiptApi} from '../services/authService';
import printJS from 'print-js';
import { formatAmount } from '../util/currencyUtil';
import { usePropsContext } from './context';
import { history } from '../routers/AppRouter';
import useRestoreComponentState from './useRestoreComponentState';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import ShowConfirmDialog from './ShowConfirmDialog';
import { exportBillPaymentsApi } from '../services/authService';

const useStyles = makeStyles((theme) => ({
    mainContent : {
        transition: ".5s", 
        flexGrow: 1,
        //overflow:'hidden',
        overflow:'auto',
        overflowX:'hidden',
        "&::-webkit-scrollbar": {
            display: "none"
        }
    },
    container: {
        marginTop: "8px",
        marginBottom:'24px'
    },
    transactionContainer : {
        marginTop:'24px',
        padding:'0px 6px 8px 6px',
    },
    transactionTitleContainer : {
        padding:'8px 22px 16px 22px',
        borderBottom:'1px solid rgba(0,0,0,0.1)',
    },
    transactionListContainer : {
        background:'white', 
        minWidth:"100%", 
        borderRadius:'4px', 
        padding:'0px 16px 0px 16px', 
        color:'#2B2B2B',
    },
    transactionHover: {
        padding:'16px 0px',
        position:'relative',
        '& .action__icon' : {
            padding:'2px 4px 0px 4px',
            border:'1px solid white',
            borderRadius:'4px',
        },
        '&:hover .action__icon' : {
            border:'1px solid #ccc'
        },
        '&:hover':{
            cursor:"pointer"
        },
        '& .more_actionButton' : {
            visibility:'hidden',
            display: "flex",
            alignItems: "center",
            width:'150px !important',
            background:'#fff',
            border:'1px solid #ccc',
            borderRadius:'5px',
            padding:'1px 0px',
            fontSize:'12px',
        },
        '&:hover .more_actionButton' : {
            visibility:'visible',
        },
    },
    marginDropdown: {
        right: '4%',
        top:'41px',
        [theme.breakpoints.down(1400)] :{
            right: '3.7%'
        },
        [theme.breakpoints.down(1300)] :{
            right: '3.4%'
        }
    },
    customColumn1 : {
        flexBasis:'3.5%',
        maxWidth:'3.5%'
    },
    customColumn2 : {     
        flexBasis:'14%',
        maxWidth:'14%'
    },
    customColumn3 : {
        flexBasis:'11%',
        maxWidth:'11%'
    },
    customColumn4 : {
        flexBasis:'18%',
        maxWidth:'18%'
    },
    customColumn5 : {
        flexBasis:'2%',
        maxWidth:'2%'
    },
    customColumn6 : {
        flexBasis:'30%',
        maxWidth:'30%'
    },
    customColumn7 : {
        flexBasis:'12%',
        maxWidth:'12%'
    },
    customColumn8 : {
        flexBasis:'9%',
        maxWidth:'9%'
    },
    MuiButtonlabel : {
        fontSize: "12px"
    },
    buttonPlusIcon: {
        marginRight:'6px', 
        fontSize:'1.2rem', 
        marginTop:'-2px'
    },
    loading: {
        position:'relative',
        left:'50%',
        right:'50%',
        marginTop:'3%'
    },
    textEllipsis: {
        maxWidth: '40ch !important',
        whiteSpace:  'nowrap !important',
        overflow: 'hidden !important',
        textOverflow: 'ellipsis !important',
        [theme.breakpoints.down(1400)] : {
            maxWidth: '34ch !important',
        }
    },
    seeInvoiceStyles: {
        color:'#5b51bf',
        cursor:'pointer',
        marginLeft:'3px',
        '&:hover' : {
            color:'#738bed'
        }
    },
    headings: {
        fontWeight: '600',
        [theme.breakpoints.down(1400)]: {
            fontSize:'14px'
        },
    },
    icons : {
        color: "black",
        fontSize: "15px",
        minWidth:'30px',
        lineHeight: '10px',
    },
    stickyTopBar : {
        position:'sticky', 
        top:0, 
        zIndex:'999', 
        backgroundColor:theme.palette.primary.light,
    },
    actionIconContainer : {
        marginRight:'auto',
        display:'flex',
        flexFlow:'row',
        flexGrow:1,
        position: "absolute",
        right: "4px",
        [theme.breakpoints.down(1500)] :{
            marginLeft:'-16px',
        },
        [theme.breakpoints.down(1450)] :{
            marginLeft:'-32px',
        },
        [theme.breakpoints.down(1400)] :{
            marginLeft:'-48px',
        },
        [theme.breakpoints.down(1320)] :{
            marginLeft:'-56px',
        },
        [theme.breakpoints.down(1290)] :{
            marginLeft:'-68px',
        },
    },
    flatIcons : {
        fontSize:'15px', 
        marginRight:'6px',
        display:'flex',
        alignItems:'center'
    },

    }
));    


const BillTransactionsComponent = (props) => {
    const classes = useStyles();
    const isMount = useIsMount();
    const {enqueueSnackbar} = useSnackbar();

    const [transactionList, setTransactionList] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);

    const [isActive, setIsActive] = useState(false);
    const [menuId, setMenuId] = useState('');
    //const [openAddPaymentDrawer, setOpenAddPamentDrawer] = useState(false);
    const [openAddNewPaymentDrawer, setOpenAddNewPaymentDrawer] = useState(false);
    const [isMoreLoading, setIsMoreLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [id, setId] = useState(props.selectedAccount?.id);
    //const [transactionObj, setTransactionObj] = useState(undefined);
    const [openInvoiceDetailsModal, setOpenInvoiceDetailsModal] = useState(false);
    const [invoiceObj, setInvoiceObj] = useState(undefined);
    const [animationActive, setAnimationActive] = useState(false);
    const [invoiceDetailObj, setInvoiceDetailObj] = useState(undefined);
    const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
    const [items, setItems] = useState(0);

    const [downloadInvoiceURL, setDownloadInvoiceURL] = useState(undefined);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [transactionItem, setTransactionItem] = useState(undefined);
    const [apiLoading, setApiLoading] = useState(false);
    const [nextPage, setNextPage] = useState(false);

    const { fullName, updateTranscItemFromParent, setUpdateTranscItemFromParent, setTransactionObj, setOpenAddPamentBillDrawer,
        handleEditPreviewBillDialogOpen, addTransaction, setTransactionComponentState, transactionsComponentState } = usePropsContext();

    const { setScrollContainer, saveState, restoreState } = useRestoreComponentState(
        transactionsComponentState, 
        setTransactionComponentState, 
    );

    const { access_level } = props.selectedAccount || {};
    const accessLevelViewer = (AccessLevel.getUserAccessLevelValue(access_level) === AccessLevel.VIEWER) ? true : false;
    const restoredState = restoreState();

    useEffect(() => {
        if(!props.fromClientDetails) {
            document.title = `Payments - ${fullName}`;
        }
    }, []);

    useLayoutEffect(() => {
        if (Object.keys(restoredState).length > 0) {
            setTransactionList(restoredState.transactionList);
            setItems(restoredState.items);
            setPage(restoredState.page);
            setTotal(restoredState.total);
        }
    }, [restoredState]);

    useEffect(() => {
        if(props.fromClientDetails && props.clientInfo && Object.keys(restoredState).length === 0) {
            getClientTransactions();
        } else {
            if(Object.keys(restoredState).length === 0) {
                getTransactionsList();
            }
        }
    }, [id]);


    useEffect(() => {
        setId(props.selectedAccount?.id)
    }, [props.selectedAccount?.id])

    useEffect(() => {
        if(!isMount) {
            if(id !== props.selectedAccount?.id) {
                setPage(1);
            }
        }   
    }, [props.selectedAccount?.id]);

    useEffect(() => {
        if (!isMount) {
            if (isMoreLoading) {
                //consoleToLog('More Loading Useffect');
                consoleToLog('isMore loading api called');
                if(props.fromClientDetails && props.clientInfo) {
                    getClientTransactions();
                } else {
                    getTransactionsList();
                }
            }
        }
    }, [isMoreLoading]);

    useEffect(() => {
        if (!isMount) {
            consoleToLog('Transaction Filters Changed');
            getTransactionsList();
        }
    }, [props.paymentStartDateFilter, props.paymentEndDateFilter, props.selectedMethodTransactionFilter, props.transactionClientsFilter]);

    useEffect(() => {
        if(!isMount) {
            if(updateTranscItemFromParent && addTransaction && transactionList && transactionList.length > 0) {
                addToTransactionList();
            }
            if(updateTranscItemFromParent && !addTransaction && transactionList && transactionList.length > 0) {
                updateTransactionList();
            }

        }
    }, [updateTranscItemFromParent, transactionList])

    useEffect(() => {
        if(!isMount) {
            if(downloadInvoiceURL) {
                printJS({printable: downloadInvoiceURL, type:'pdf', showModal:true});
                setDownloadInvoiceURL(undefined);
            }
        }
    }, [downloadInvoiceURL]);
    
    const pageReset = () => {
        setPage(1)
        // if(page === 1) {
        //     getTransactionsList()
        // }
    }

    let count = 0;
    if (props.transactionClientsFilter && props.transactionClientsFilter.length > 0) {
        count = count + 1
    }

    if(props.selectedMethodTransactionFilter && props.selectedMethodTransactionFilter.length > 0) {
        count = count + 1
    }


    const getTransactionsList = () => {
        const invoice_account_id = props.selectedAccount?.id; 
        const payment_start_date = props.paymentStartDateFilter;
        const payment_end_date = props.paymentEndDateFilter;
        
        const payment_method = props.selectedMethodTransactionFilter && props.selectedMethodTransactionFilter.length > 0 ? 
        props.selectedMethodTransactionFilter.map((method) => method.value).join() : undefined;
        
        const vendors = props.transactionClientsFilter && props.transactionClientsFilter.length > 0 ? 
        props.transactionClientsFilter?.map((client) => client.id)?.join() : undefined;

        if(!isMoreLoading) setLoading(true);
        getBillTransactionsListApi(invoice_account_id, page, payment_start_date, 
            payment_end_date, payment_method, vendors) 
            .then((response) => {
                const res = response.data
                consoleToLog('Response getBillTransactionsListApi: ', res);
                setLoading(false);
                setItems(res.items);

                if(isMoreLoading) {
                    const newItems = transactionList.concat(res.items);
                    setTransactionList(newItems);
                    setPage(page + 1);
                    setIsMoreLoading(!isMoreLoading);
                    setNextPage(res.next_page);
                } else {
                    if(res.next_page){ setPage(page + 1);}
                    setNextPage(res.next_page);
                    setTransactionList(res.items);
                    setTotal(res.total);
                }
            })
            .catch((e) => {
                consoleToLog('Error getTransactionsListApi: ', e.response);
                setLoading(false);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant: 'error'});
                    return;
                }
            })
    }

    const getClientTransactions = () => {
        const invoice_account_id = props.selectedAccount?.id;
        const client_id = props.clientInfo?.id;

        if(!isMoreLoading){ setLoading(true);}
        getVendorTransactionsApi(invoice_account_id, client_id, page)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response getVendorTransactionsApi: ', res);
                setLoading(false);

                setItems(res.items);

                if(isMoreLoading) {
                    const newItems = transactionList.concat(res.items)
                    setTransactionList(newItems);
                    setPage(page + 1);
                    setIsMoreLoading(!isMoreLoading);
                    setNextPage(res.next_page);
                } else {
                    if(res.next_page){ setPage(page + 1);}
                    setTransactionList(res.items);
                    setTotal(res.total);
                    setNextPage(res.next_page);
                }
            })
            .catch((e) => {
                consoleToLog('Error getVendorTransactionsApi: ', e.response);
                setLoading(false);
                if(e.respose.data && e.response.data.message) {
                    return;
                }
            })
    }

    const handleOpenAddPaymentDrawer = (invoice) => {
        trackGAEvent(props.selectedAccount.name, 'Transaction - Add Payment Clicked', `${props.user.firstname} ${props.user.lastname}`);
        setOpenAddPamentBillDrawer(true);
        setInvoiceObj(invoice);
        setTransactionObj(undefined);
    };

    const handleCloseAddPaymentDrawer = () => {
        setOpenAddPamentBillDrawer(false);
    };

    const handleOpenAddNewPaymentDrawer = () => {
        setOpenAddNewPaymentDrawer(true);
    };

    const handleCloseAddNewPaymentDrawer = () => {
        setOpenAddNewPaymentDrawer(false);
    };

    const onSeeInvoiceClick = () => {
        setOpenInvoiceDetailsModal(true);
    }

    const closeMoreOptionsMenu = () => {
        setIsActive(false);
        setMenuId('');
    }

    const storeTransactionState = (invoice) => {
        const stateObj = {
            transactionList,
            items,
            page,
            total,
            ...(props.fromClientDetails && {
                clientListState: {...props.clientComponentObj},
                clientInfo: {...props.showClientInfo},
                tabValue: props.tabValue,
                clientScrollPosition: props.scrollContainer?.scrollTop
            }),
        };
        saveState(stateObj);
        history.push(`/bill/${invoice.slug}/preview`); 
    }

    const handleOpenInvoiceDetailModal = (e, transactionObj) => {
        trackGAEvent(props.selectedAccount.name, 'Payment list item - See bill link clicked', `${props.user.firstname} ${props.user.lastname}`);
        storeTransactionState(transactionObj.purchase_invoice);
        //getInvoiceDetails(transactionObj.invoice_id);
        const selectedEntity = props.selectedAccount.entities.find((entity) => Number(entity.id) === transactionObj?.purchase_invoice?.entity_id);
        handleEditPreviewBillDialogOpen(selectedEntity);
    }

    const handleCloseInvoiceDetailModal = () => {
        setOpenInvoiceDetailsModal(false);
    }

    const callDeleteTransactionApi = (invoice_account_id, transaction_id) => {
            setAnimationActive(true);
            setApiLoading(true);
            deleteBillPaymentTransactionApi(invoice_account_id, transaction_id)
                .then((response) => {
                    const res = response.data;
                    consoleToLog('Response deleteBillPaymentTransactionApi: ', res);

                    enqueueSnackbar('Payment deleted successfully', {variant:'success'})

                    const updated_array = transactionList.filter((transaction) => transaction.id !== transaction_id);
                    setTransactionList(updated_array);
                    setTotal(total - 1);
                    setAnimationActive(false);
                    setApiLoading(false);

                    handleConfirmDialogClose();

                    trackGAEvent(props.selectedAccount.name, 'Payment list item - Payment deleted', `${props.user.firstname} ${props.user.lastname}`);
                })
                .catch((e) => {
                    consoleToLog('Error deleteBillPaymentTransactionApi', e.response);
                    setAnimationActive(false);
                    setApiLoading(false);
                    if(e.response.data && e.response.data.message) {
                        enqueueSnackbar(e.response.data.message, {variant: 'error'});
                        return;
                    }
            })
    }

    const loadMoreLogic = () => {
        return (
            <Grid item container>
                <Grid item lg={12}>
                {nextPage && !isMoreLoading &&
                    <div style={{textAlign: "center", margin:'16px 0px'}}>
                        <Button type="button"
                            color="secondary"
                            variant="contained"
                            onClick={() => {
                                setIsMoreLoading(!isMoreLoading);
                            }}>Load More
                        </Button>
                    </div>}

                { isMoreLoading &&
                    <CircularProgress size={35} style={{position:'relative', left:'50%', right:'50%', margin:'16px 0px'}}/>
                }
                </Grid>
            </Grid>
        )
    }

    const onEditTransactionClick = (e, transaction) => {
        trackGAEvent(props.selectedAccount.name, 'Bill Payment list item - payment deleted', `${props.user.firstname} ${props.user.lastname}`);
        e.stopPropagation();
        setOpenAddPamentBillDrawer(true);
        setTransactionObj(transaction);
        closeMoreOptionsMenu();
    }

    const updateTransactionList = () => {
        let updated_array = transactionList.map((transaction) => {
            if(transaction.id === updateTranscItemFromParent.id) {
                transaction = updateTranscItemFromParent
            }
            return transaction
        });

        setTransactionList(updated_array);
        setUpdateTranscItemFromParent(undefined);
    }

    const addToTransactionList = () => {
        setTransactionList(oldTransactions => [updateTranscItemFromParent, ...oldTransactions]);
        setUpdateTranscItemFromParent(undefined);

    }

    const handleOpenFilterDrawer = () => {
        setOpenFilterDrawer(true);
    }

    const handleCloseFilterDrawer = () => {
        setOpenFilterDrawer(false);
    }

    const onExportBillPaymentsClick = () => {
        const invoice_account_id = props.selectedAccount?.id;
        const payment_start_date = props.paymentStartDateFilter;
        const payment_end_date = props.paymentEndDateFilter;
        
        const payment_method = props.selectedMethodTransactionFilter && props.selectedMethodTransactionFilter.length > 0 ? 
        props.selectedMethodTransactionFilter.map((method) => method.value).join() : undefined;
        
        const vendors = props.transactionClientsFilter && props.transactionClientsFilter.length > 0 ? 
        props.transactionClientsFilter?.map((client) => client.id)?.join() : undefined;

        props.setApiRoute('purchase_transaction_export');
        exportBillPaymentsApi(invoice_account_id, payment_start_date, payment_end_date, payment_method, vendors)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response exportTransactionsApi: ', res);

                props.setOverlayView(OverlayView.SHOW_OVERLAY_LOADING);
                props.setOverlayToken(res.task_id);
                props.setOvelayMessage(OverlayMessage.EXPORT_MSG);
            })
            .catch((e) => {
                consoleToLog('Error exportTransactionsApi: ', e);
                props.setOverlayView(OverlayView.NO_VIEW);
                props.setOverlayToken("");
                if(e.response.data && e.response.data.message){
                    enqueueSnackbar(e.response.data.message, {variant:"error"});
                    return;
                }
            })
    }

    const onPrintReceiptClick = (e, transaction) => {
        trackGAEvent(props.selectedAccount.name, 'Transaction list item - Print Receipt', `${props.user.firstname} ${props.user.lastname}`);
        e.stopPropagation();
        printDownloadReceipt(transaction, true);
    }

    const onDownloadReceiptClick = (e, transaction) => {
        trackGAEvent(props.selectedAccount.name, 'Transaction list item - Download Receipt', `${props.user.firstname} ${props.user.lastname}`);
        e.stopPropagation();
        printDownloadReceipt(transaction, false);
    }

    const printDownloadReceipt = (transactionObj, isToPrint) => {
        const invoice_account_id = props.selectedAccount?.id; 
        const invoice_id = transactionObj.invoice?.id;
        const transaction_id = transactionObj?.id;
        const details = {};

        downloadReceiptApi(invoice_account_id, invoice_id, transaction_id, details)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response downloadReceiptApi: ', res);

                const downloadableURL = res;
                if(isToPrint) {
                    setDownloadInvoiceURL(downloadableURL);
                } else {
                    document.getElementById('dowload_receipt').href = downloadableURL;
                    document.getElementById('dowload_receipt').click();
                    closeMoreOptionsMenu();
                }

            })
            .catch((e) => {
                consoleToLog('Error downloadReceiptApi: ', e);
                if(e.response.data && e.response.data.message){
                    enqueueSnackbar(e.response.data.message, {variant:"error"});
                    return;
                }
            })
    }

    const handleConfirmDialogOpen = (transaction, obj) => {
        setOpenConfirmDialog(true);

        let transaction_obj = {
            ...transaction,
            ...obj
        }
        setTransactionItem(transaction_obj);
        closeMoreOptionsMenu();
    }

    const handleConfirmDialogClose = () => {
        setOpenConfirmDialog(false);
        setTransactionItem(undefined)
    }

    return (
        <div className={classes.mainContent} 
            ref={(node) => setScrollContainer(node)}
            style={{
                backgroundColor: props.fromClientDetails ? '#ffffff' : '#f7f7f7'
            }}
        >
            <Container className={classes.container} maxWidth="xl"
                style={{padding: "0px 16px"}}>
                


                <Paper variant='outlined' elevation={0} style={{marginTop:'10px', marginBottom:'24px', width:'100%'}}>
                    <TableContainer style={{overflow:'visible', height:'100%'}}>
                        <Table>
                        <TableHead style={{position:'sticky', top:'0px', zIndex:99, background:'#f7f7f7'}}>
                            <TableRow>
                                <TableCell className='tableheading_style'>Date</TableCell>
                                <TableCell className='tableheading_style'>Name</TableCell>
                                <TableCell className='tableheading_style'>Invoice</TableCell>
                                <TableCell className='tableheading_style'>Method</TableCell>
                                <TableCell className='tableheading_style'>Notes</TableCell>
                                <TableCell className='tableheading_style'>Amount</TableCell>
                                {!props.fromClientDetails &&
                                <TableCell className='tableheading_style' style={{width:'30px'}}>
                                    <div style={{display:'flex', alignItems:'center'}}> 

                                    <Tooltip title="Filters" arrow>
                                        <Button 
                                            onClick={handleOpenFilterDrawer}  
                                            style={{margin:'0px 2px 0px 8px'}}
                                            className={classes.icons}
                                            >
                                            {<img src='/images/filter_icon.svg' 
                                                className={(count && count > 0) ? `filter_icon active` : 'filter_icon'}/>}
                                            {   count && count > 0 ?
                                                <div className='filter__dot'></div> : <div></div>
                                            }
                                        </Button>
                                    </Tooltip>

                                        <Tooltip title="Export" arrow>
                                            <Button
                                                onClick={onExportBillPaymentsClick}  
                                                className={classes.icons}
                                                >
                                                <CloudUploadOutlinedIcon className='table_header_icons'/>
                                            </Button>
                                        </Tooltip> 

                                    </div>
                                </TableCell>}
                            </TableRow>
                        </TableHead>
                        {loading ?
                        <TableBody>
                            <TableRow hover className={classes.invoiceListHover}>
                                <TableCell colSpan={8} align='center'>
                                    <CircularProgress size={25} style={{margin:'24px 0px'}}/>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                        :
                        <TableBody>
                            {transactionList && transactionList.length > 0 ? transactionList.map((transaction) => {
                                const currencyObj = transaction.invoice?.currencies;

                                return (
                                    <TableRow hover  key={transaction.id} className={classes.transactionHover}>
                                        <TableCell className='tablebody_style'>
                                            {showListItemDate(transaction.payment_date)}
                                        </TableCell>
                                        <TableCell className='tablebody_style'>
                                            <Tooltip title={transaction.invoice?.client_name} arrow>
                                                <Typography variant="subtitle1" className={classes.textEllipsis}>
                                                        {transaction.purchase_invoice?.name}
                                                </Typography>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell className='tablebody_style'>
                                            <Typography variant="subtitle1" >
                                                #{transaction.invoice?.invoice_number}  
                                                <span className={classes.seeInvoiceStyles} 
                                                    onClick={(e) => handleOpenInvoiceDetailModal(e, transaction)}
                                                >
                                                    (See Bill)
                                                </span>
                                            </Typography>
                                        </TableCell>
                                        <TableCell className='tablebody_style'>
                                            {showPaymentMethodString(transaction.payment_method)}
                                        </TableCell>
                                        <TableCell className='tablebody_style'>
                                            {transaction.remarks}
                                        </TableCell>
                                        <TableCell className='tablebody_style'>
                                            {formatAmount(transaction.amount, currencyObj)}
                                        </TableCell>
                                        {/* <TableCell className='tablebody_style'>
                                            {!accessLevelViewer && 
                                            <div className={classes.actionIconContainer}>
                                                <Button 
                                                    //onClick={(e) => onEditClientClick(e, client)}
                                                    className='more_actionButton action__icon'>
                                                    <i className={`flaticon-edit ${classes.flatIcons}`}></i>
                                                    Edit
                                                </Button>
                                                <Button 
                                                    //onClick={(e) => onDeleteClientClick(e, client)}
                                                    className='more_actionButton action__icon'>
                                                    <i className={`flaticon-delete-1 ${classes.flatIcons}`}></i>
                                                    Delete
                                                </Button>
                                            </div>}
                                        </TableCell> */}
                                        {!props.fromClientDetails &&
                                        <TableCell className='tablebody_style' align='right'>
                                        {!accessLevelViewer &&
                                            <Grid item
                                                style={{display:'flex',
                                                        alignItems:'center',
                                                        justifyContent:'center',
                                                        marginLeft:'13px',
                                                        position:'relative'
                                                    }}> 
                                                <div  className={classes.actionIconContainer}>
                                                    <Button 
                                                        onClick={(e) => onEditTransactionClick(e, transaction)}
                                                        className='more_actionButton action__icon'>
                                                        <i className={`flaticon-edit ${classes.flatIcons}`}></i>
                                                        Edit
                                                    </Button>
                                                    <Button 
                                                        onClick={(e) => handleConfirmDialogOpen(transaction, {delete_payment_clicked:true})}
                                                        className='more_actionButton action__icon'>
                                                        <i className={`flaticon-delete ${classes.flatIcons}`}></i>
                                                        Delete
                                                    </Button>
                                                    {/* <Button 
                                                        onClick={(e) => onDownloadReceiptClick(e, transaction) }
                                                        className='more_actionButton action__icon'>
                                                        <CloudDownloadOutlinedIcon  
                                                            style={{marginTop:'-1.5px'}}
                                                            className={`${classes.flatIcons}`}/>
                                                        Download Receipt
                                                        <Link
                                                            style={{ display: "none" }}
                                                            id='dowload_receipt'
                                                            target="_blank"
                                                            onClick={(e) => e.stopPropagation()}
                                                            download
                                                        ></Link>
                                                    </Button> */}
                                                </div>
                                                {
                                                //     <div style={{position: "absolute", right: "0px"}}>
                                                //     <Tooltip title="More Actions" arrow>  
                                                //         <i className="flaticon-more action__icon"  
                                                //         onClick={(e) => {
                                                //                 e.stopPropagation();
                                                //                 setIsActive(!isActive);
                                                //                 setMenuId(transaction.id);
                                                //             }}
                                                //         style={{fontSize:'15px', position:'relative', left:'-4px'}}></i>
                                                //     </Tooltip>
                                                //     <>    
                                                //         {transaction.id === menuId &&
                                                //         <ClickAwayListener onClickAway={closeMoreOptionsMenu}>
                                                //             <Grid item className={`menu ${isActive ? `active ${classes.marginDropdown}` : "inactive"}`}>
                                                //                 <ul>
                                                //                     {/* <li onClick={(e) => onEditTransactionClick(e, transaction)}>
                                                //                         <a  style={{display:'flex', alignItems:'center'}}>
                                                //                             <EditOutlinedIcon fontSize='small'  
                                                //                             className='more_actionsIcons'/>
                                                //                             Edit
                                                //                         </a>
                                                //                     </li> */}

                                                //                     {/* <li onClick={(e) => onPrintReceiptClick(e, transaction)}>
                                                //                         <a  style={{display:'flex', alignItems:'center'}}>
                                                //                             <PrintOutlinedIcon fontSize='small'  
                                                //                             className='more_actionsIcons'/>
                                                //                             Print Receipt
                                                //                         </a>
                                                //                     </li> */}

                                                //                     {/* <li onClick={(e) => onDownloadReceiptClick(e, transaction)} id='dowload-receipt'>
                                                //                         <a  style={{display:'flex', alignItems:'center'}}>
                                                //                             <CloudDownloadOutlinedIcon fontSize='small'  
                                                //                             style={{marginTop:'-1.5px'}}
                                                //                             className={`more_actionsIcons`}/>
                                                //                             Download Receipt
                                                //                         </a>
                                                //                         <Link
                                                //                             style={{ display: "none" }}
                                                //                             id='dowload_receipt'
                                                //                             target="_blank"
                                                //                             onClick={(e) => e.stopPropagation()}
                                                //                             download
                                                //                         ></Link>
                                                //                     </li> */}

                                                //                     {/* <li 
                                                //                         onClick={(e) => {
                                                //                             e.stopPropagation();
                                                //                             handleConfirmDialogOpen(transaction, {delete_transaction_clicked:true})
                                                //                         }}>
                                                //                         <a style={{display:'flex', alignItems:'flex-start'}}>
                                                //                             <DeleteOutlinedIcon fontSize='small'  className='more_actionsIcons'/>
                                                //                             Delete
                                                //                         </a>
                                                //                     </li> */}
                                                //                 </ul>
                                                //             </Grid>
                                                //             </ClickAwayListener>
                                                //             }
                                                //     </>
                                                // </div>
                                                }
                                            </Grid>  }
                                        </TableCell>}
                                    </TableRow>
                                    
                                );
                            })
                            :
                            <TableRow hover className={classes.invoiceListHover}>
                                <TableCell colSpan={8} align='center'>
                                    No Payments Found
                                </TableCell>
                            </TableRow>
                            }
                            
                        </TableBody>
                    }
                    </Table>
                    </TableContainer>
                    {!loading && loadMoreLogic()}
                </Paper>
                
                {
                    // <AddNewPaymentDrawer openAddNewPaymentDrawer={openAddNewPaymentDrawer} 
                    //     handleCloseAddNewPaymentDrawer={handleCloseAddNewPaymentDrawer}
                    //     handleOpenAddPaymentDrawer={handleOpenAddPaymentDrawer}
                    // />
                }
                {
                // <AddEditPaymentDrawer openAddPaymentDrawer={openAddPaymentDrawer}
                //     fromTransactionComponent={true}
                //     transactionObj={transactionObj}
                //     updateTransactionList={updateTransactionList}
                //     handleCloseAddPaymentDrawer={handleCloseAddPaymentDrawer}
                //     invoiceObj={invoiceObj}
                //     addToTransactionList={addToTransactionList}
                //     handleCloseAddNewPaymentDrawer={handleCloseAddNewPaymentDrawer}
                //     accessLevelViewer={accessLevelViewer}
                //     setTransactionObj={setTransactionObj}
                // />
                }

                {openFilterDrawer && <TransactionsFilterDrawer openFilterDrawer={openFilterDrawer}
                    handleCloseFilterDrawer={handleCloseFilterDrawer}
                    pageReset={pageReset}
                    isFromBillPaymentsComponent={true}
                />}

                {
                    openConfirmDialog && <ShowConfirmDialog openConfirmDialog={openConfirmDialog}
                                            handleConfirmDialogClose={handleConfirmDialogClose}
                                            fromTransactionListComponent={true}
                                            transactionItem={transactionItem}
                                            apiLoading={apiLoading}
                                            callDeleteTransactionApi={callDeleteTransactionApi}
                                        />
                }

            </Container> 
        
        </div>
    );
}

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
    paymentStartDateFilter:  state.transactionFilters.paymentStartDateFilter,
    paymentEndDateFilter: state.transactionFilters.paymentEndDateFilter,
    selectedMethodTransactionFilter: state.transactionFilters.selectedMethodTransactionFilter,
    transactionClientsFilter: state.transactionFilters.transactionClientsFilter,
    user: state.invoiceAccounts.user
});

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logout()),
    clearSelectedInvoiceFilters: () => dispatch(clearSelectedInvoiceFilters()),
    setClearInvoiceAccounts: () => dispatch(setClearInvoiceAccounts()),
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState)),
    clearSelectedTransactionFilters: () => dispatch(clearSelectedTransactionFilters()),

    setOverlayView: (exportView) => dispatch(setOverlayView(exportView)),
    setOverlayToken: (randomToken) => dispatch(setOverlayToken(randomToken)),
    setOvelayMessage: (overlayMessage) => dispatch(setOvelayMessage(overlayMessage)),
    setApiRoute: (route) => dispatch(setApiRoute(route))
})

export default connect(mapStateToProps, mapDispatchToProps)(BillTransactionsComponent);