import { Button, Chip, CircularProgress, Divider, Grid, makeStyles, Typography, Link } from "@material-ui/core";
import { connect } from "react-redux";
import ClearIcon from '@material-ui/icons/Clear';
import { useEffect, useState } from "react";
import { formatAmount } from "../util/currencyUtil";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { history } from '../routers/AppRouter';
import ActivityFeed from "./ActivityFeed";
import AllActivityFeedDrawer from "./AllActivityFeedDrawer";
import CancelIcon from "@material-ui/icons/Cancel";
import { cancelBillApi, billDownloadApi } from "../services/authService";
import ShowConfirmDialog from "./ShowConfirmDialog";
import { consoleToLog } from "../util/AppUtil";
import { useSnackbar } from "notistack";
import TopStatus from "./TopStatus";

const useStyles = makeStyles((theme) => ({
    btnStyle: {
        width: "130px",
        marginTop: "8px"
    },
    detailsStyle:{
        marginTop: "10px",
    },
    
    detailText: { fontSize: "15px", fontWeight: 'bold', color: "#4a5568" },
    detailText1: { fontSize: "15px", fontWeight: 'bold' },
    detailBodyText:{
        marginLeft: "6px",
        color: "#4a5568" 
    },
    blueColorStyle:{
        color:"#4c51bf"
    }

}))

const PreviewBillComponent = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();

    const [billObj, setBillObj]=useState(null);
    const [openActivityDrawer, setOpenActivityDrawer] = useState(false);
    const [apiLoading, setApiLoading] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [invoiceItem, setInvoiceItem] = useState(undefined);
    const [downloadApiLoading, setDownloadApiLoading] = useState(false);

    useEffect(() => {
        if(props.billObj && Object.keys(props.billObj).length>0){
            console.log("**right side useeffect of billObj", props.billObj);
            setBillObj(props.billObj);
        }
    }, [props.billObj]);


    const billNo=billObj?.purchase_invoice?.bill_number || "";
    const billDate=billObj?.purchase_invoice?.bill_date || "";
    const dueDate=billObj?.purchase_invoice?.due_date || "";
    const vendorName=billObj?.purchase_invoice?.vendor?.name || "";
    const category=billObj?.purchase_invoice?.category || null;
    const subtotal=billObj?.purchase_invoice?.subtotal;
    const total=billObj?.purchase_invoice?.total;
    const tdsAmt=billObj?.purchase_invoice?.tds_amount || 0;
    const currencyObj=billObj ? (billObj?.purchase_invoice?.currency) : props.selectedAccount?.currencies;
    const tdsTypeObj=billObj?.purchase_invoice?.tds_type_object;
    const paid=billObj?.purchase_invoice?.paid;
    const due=billObj?.purchase_invoice?.due;
    const employee=billObj?.purchase_invoice?.employee;
    const client=billObj?.purchase_invoice?.client;
    const taxes=billObj?.purchase_invoice?.taxes;
    const can_edit = billObj?.purchase_invoice?.can_edit;
    const can_add_payment = billObj?.purchase_invoice?.can_add_payment;
    const can_cancel = billObj?.purchase_invoice?.can_cancel;
    const entity = billObj?.purchase_invoice?.entity;
    const purchase_invoice_id = billObj?.purchase_invoice?.id;
    const attachment_url = billObj?.purchase_invoice?.attachment_url;
    const purchase_items=billObj?.purchase_invoice?.purchase_items || [];
    const status = billObj?.purchase_invoice?.status;
    console.log("***status:", status);

    const getTotal = () => {
        let tax = (taxes && taxes.length>0)? taxes.reduce((acc, curr) => acc + parseFloat(curr.amount), 0) : 0;
        let total=parseFloat(subtotal?subtotal:0)+parseFloat(tax);
        return total
    }

    const onEditButtonClick=()=>{
        if(billObj){
            props.setBillObj({...billObj});
            history.push(`/bill/${billObj?.purchase_invoice?.slug}/edit`)
        }
    }

    const onShowMoreActivities = ()=>{
        setOpenActivityDrawer(true);
    }

     const handleCloseActivityDrawer = ()=>{
        setOpenActivityDrawer(false);
     }


    const callCancelInvoiceApi = async (invoice_account_id, bill_id) => {

        setApiLoading(true);

        try {
            const response = await cancelBillApi(invoice_account_id, bill_id);
            
            consoleToLog("***Response cancelBillApi: ", response.data);
            const res = response.data;
            let obj = {
                ...billObj,
                purchase_invoice: {
                    ...res
                }
            }
            setBillObj(obj);
            props.setUpdatedBillObj(obj.purchase_invoice);
            enqueueSnackbar('Bill updated successfully', { variant: 'success'});
            setApiLoading(false);

            handleConfirmDialogClose();
            
        } catch (e) {
            consoleToLog("Err: cancelBillApi", e);
            setApiLoading(false);
            if (e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, { variant: 'error' });
            }
        }
    }

    const handleConfirmDialogOpen = (obj) => {
        setOpenConfirmDialog(true);

        let bill_obj = {
            ...billObj?.purchase_invoice,
            ...obj
        }
        setInvoiceItem(bill_obj);
    }

    const handleConfirmDialogClose = () => {
        setOpenConfirmDialog(false);
        setInvoiceItem(undefined);
    }

    const onDownloadBillClick = async () => {
        const invoice_account_id = props.selectedAccount?.id;
        const entity_id = entity.id;

        setDownloadApiLoading(true);

        try{

            const response = await billDownloadApi(invoice_account_id, entity_id, purchase_invoice_id);
            const res = response.data;
            consoleToLog('Response billDownloadApi: ', res);

            const downloadableURL = res.download_signed_url;
            setDownloadApiLoading(false);

            //initiate download
            document.getElementById('bill_download').href = downloadableURL;
            document.getElementById('bill_download').click();
        } catch(e) {
            consoleToLog('Error billDownloadApi: ', e.response);
            setDownloadApiLoading(false);
            if(e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {variant:'error'});
                return;
            }
        }
    }

    return (
        <>
        {
            props.loading ?
            <Grid item container justifyContent="center">
                <CircularProgress style={{marginTop:'16px'}}/>
            </Grid>
            :
            <Grid container style={{background: "#f7f7f7"}}>
                {/* <Grid container item style={{ height: '50px', backgroundColor: '#dddddd', padding: "0px 8px"}}>
                    <Grid item md={6} style={{ display: 'flex', alignItems: 'center' }}>
                        
                    </Grid>
                    <Grid item md={6} 
                        style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
                        >
                        <Button variant="text" color="primary" 
                            onClick={()=>props.handleBillDetailClickDialogClose()}>
                            <ClearIcon />
                        </Button>
                    </Grid>
                </Grid> */}
                <Grid container item  className="sidebar1" style={{padding: "12px 10px 10px 10px"}}>
                    <Grid item md={props.isFromUnclaimedBill?12:8}>
                        {status && <TopStatus status={status}
                            fromInvoicePreview={false}/>}
                        <Grid item md={12} style={{padding: "8px 16px 0px 16px", height:'100vh', background: "#FFFFFF"}}>
                        <Grid container className={classes.detailsStyle}>
                            <Grid item xs={6}>
                                <Typography className={classes.detailText}>
                                
                                </Typography>
                            </Grid>
                            <Grid item xs={6} style={{textAlign: 'right'}}>
                                <Typography className={classes.detailBodyText}>
                                    <span style={{fontWeight: "bold", color: "#4a5568"}}>Bill No.:</span> {billNo}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.detailsStyle}>
                            <Grid item xs={6}>
                                <Typography >
                                    <span style={{fontWeight: "bold",color: "#4a5568"}}>Bill Date:</span> {billDate}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} style={{textAlign: 'right'}}>
                                <Typography className={classes.detailBodyText}>
                                    <span style={{fontWeight: "bold", color: "#4a5568"}}>Due Date:</span> {dueDate}
                                </Typography>
                            </Grid>
                        </Grid>
                        {billObj?.purchase_invoice?.vendor && <Grid container className={classes.detailsStyle}>
                            <Grid item xs={8} style={{ textAlign: 'left' }}>
                                <Typography className={[classes.detailText, classes.blueColorStyle]}>
                                        Bill from:
                                </Typography>
                                <Typography className={classes.clientOwnerName}>
                                    {vendorName}
                                </Typography>
                                {billObj?.purchase_invoice?.vendor && 
                                <Typography>
                                    <div dangerouslySetInnerHTML={{__html: billObj?.purchase_invoice?.vendor?.address}}>
                                    </div>
                                    <div>{billObj?.purchase_invoice?.vendor?.state}</div>
                                    <div>{billObj?.purchase_invoice?.vendor?.country}</div>
                                </Typography>}
                            </Grid>
                            <Grid item xs={4}>
                            </Grid>
                        </Grid>}
                         {purchase_items && purchase_items.length>0 && <Grid container  className={classes.detailsStyle} 
                            style={{backgroundColor: "#F6F8FB", padding: "4px", color: "#535dC5", marginBottom:"6px"}}>
                            <Grid item md={6}>
                                <Typography className={classes.detailText1}>Category</Typography>
                            </Grid>
                            <Grid item md={6} style={{textAlign: 'right'}}>
                                <Typography className={classes.detailText1}>Amount</Typography>
                            </Grid>
                        </Grid>}
                        {purchase_items && purchase_items.length>0 && purchase_items.map((item, index) => (
                            <>
                            <Grid container key={index} style={{marginBottom: "8px", padding: "4px"}}>
                                <Grid item md={6}>
                                    <Typography>{item?.category?.name}</Typography>
                                    <Typography style={{fontSize: "12px"}}>{item?.description}</Typography>
                                </Grid>
                                <Grid item md={6} style={{textAlign: 'right'}}>
                                    <Typography>{formatAmount(item.amount, currencyObj)}</Typography>
                                    {item.tax.map((tax, index) => (
                                        <Typography key={index} style={{fontSize: "11px"}}>{tax.name} {tax.rate}% {formatAmount(tax.amount, currencyObj)}</Typography>
                                    ))}
                                </Grid>
                            </Grid>
                            {index !== purchase_items.length - 1 && <Divider style={{ margin: "6px 0px" }} />}
                            </>
                        ))}
                        {/* <Grid container className={classes.detailsStyle}>
                            <Grid item xs={6}>
                                <Typography className={classes.detailText}>
                                    Bill Category
                                </Typography>
                            </Grid>
                            <Grid item xs={6} style={{textAlign: 'right'}}>
                                {category && category?.name &&<Chip label={category?.name} 
                                    className={classes.detailBodyText}
                                    style={{height: "25px"}} />}
                            </Grid>
                        </Grid> */}
                        <Grid container style={{marginTop: "15px"}}>
                            <Grid item xs={6}>
                                <Typography className={classes.detailText}>
                                    Subtotal
                                </Typography>
                            </Grid>
                            <Grid item xs={6} style={{textAlign: 'right'}}>
                                 <Typography className={classes.detailBodyText}>
                                     {formatAmount(subtotal, currencyObj)}
                                </Typography>
                            </Grid>
                        </Grid>
                        {
                            taxes && taxes.length>0 && taxes.map((taxItem)=>{
                                return(
                                    <Grid container className={classes.detailsStyle}>
                                        <Grid item xs={6}>
                                            <Typography className={classes.detailText}>
                                                
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} style={{textAlign: 'right'}}>
                                            <Typography className={classes.detailBodyText}>
                                                {taxItem.name} {taxItem.rate}% ({formatAmount(taxItem.amount, currencyObj)})
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )
                            })
                        }
                        <Divider style={{ margin: "10px 0px" }} />
                        <Grid container className={classes.detailsStyle}>
                            <Grid item xs={6}>
                                <Typography className={classes.detailText}>
                                    Total
                                </Typography>
                            </Grid>
                            <Grid item xs={6} style={{textAlign: 'right'}}>
                                 <Typography className={classes.detailBodyText}>
                                 {formatAmount(total, currencyObj)}
                                </Typography>
                            </Grid>
                        </Grid>
                       {tdsAmt>0 && <Grid container className={classes.detailsStyle}>
                            <Grid item xs={6}>
                                <Typography className={classes.detailText}>
                                    TDS<span style={{fontSize: "12px"}}>{tdsTypeObj?" ("+tdsTypeObj.tds_display+")" : ""}</span>
                                </Typography>
                            </Grid>
                            <Grid item xs={6} style={{textAlign: 'right'}}>
                                 <Typography className={classes.detailBodyText}>
                                     {formatAmount(tdsAmt, currencyObj)}
                                </Typography>
                            </Grid>
                        </Grid>}
                        <Divider style={{ margin: "10px 0px" }} />
                        <Grid container className={classes.detailsStyle}>
                            <Grid item xs={6}>
                                <Typography variant="h5" style={{fontWeight: "bold",color: "#415568"}}>
                                    Net Payable
                                </Typography>
                            </Grid>
                            <Grid item xs={6} style={{textAlign: 'right'}}>
                                 <Typography variant="h5" 
                                    className={classes.detailBodyText}
                                    style={{fontWeight: "bold"}}>
                                     {formatAmount(total-tdsAmt, currencyObj)}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.detailsStyle}>
                            <Grid item xs={6}>
                                <Typography className={classes.detailText}>
                                    Paid to date
                                </Typography>
                            </Grid>
                            <Grid item xs={6} style={{textAlign: 'right'}}>
                                 <Typography className={classes.detailBodyText}>
                                     {formatAmount(paid, currencyObj)}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.detailsStyle}>
                            <Grid item xs={6}>
                                <Typography className={classes.detailText}>
                                    Due amount
                                </Typography>
                            </Grid>
                            <Grid item xs={6} style={{textAlign: 'right'}}>
                                 <Typography className={classes.detailBodyText}>
                                     {formatAmount(due, currencyObj)}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Divider style={{ margin: "10px 0px" }} />
                        {employee && <Grid container className={classes.detailsStyle}>
                            <Grid item xs={6}>
                                <Typography className={classes.detailText}>
                                    Reimburse to
                                </Typography>
                            </Grid>
                            <Grid item xs={6} style={{textAlign: 'right'}}>
                                 <Typography className={classes.detailBodyText}>
                                     {employee? employee?.name : ""}
                                </Typography>
                            </Grid>
                        </Grid>}
                       {client && <Grid container className={classes.detailsStyle}>
                            <Grid item xs={6}>
                                <Typography className={classes.detailText}>
                                    Claim expense from
                                </Typography>
                            </Grid>
                            <Grid item xs={6} style={{textAlign: 'right'}}>
                                 <Typography className={classes.detailBodyText}>
                                 {client? client?.name : ""}
                                 </Typography>
                            </Grid>
                        </Grid>}

                        {/* {props.isFromUnclaimedBill &&
                        <ClearIcon style={{position: 'absolute', right: '5px', top: '5px', color: 'white', background: "red", borderRadius: '50%', border: '1px solid red', cursor: 'pointer', width: '20px', height: '20px'}} 
                            onClick={() => props.handleBillDetailClickDialogClose()} />} */}
                        </Grid>
                    </Grid>
                   <Grid item md={4} style={{padding: "0px 8px"}}>
                       {!props.isFromUnclaimedBill && <Grid item container direction='column' 
                            alignItems='center' 
                            className="save_invoice"
                            style={{marginLeft:"4px", position: 'relative'}}>
                            {can_edit &&
                            <Button fullWidth className={classes.buttonStyles}
                                onClick={onEditButtonClick}
                                //disabled={accessLevelViewer}
                                variant="outlined" color='secondary' >
                                <EditOutlinedIcon fontSize='small'
                                    style={{marginRight:'5px', fontSize:'16px'}} />
                                    Edit
                            </Button>}
                            {attachment_url &&
                            <Button fullWidth className={classes.buttonStyles}
                                style={{marginTop: "8px"}}
                                onClick={onDownloadBillClick}
                                //disabled={accessLevelViewer}
                                variant="outlined" color='secondary'>
                                {downloadApiLoading ? <CircularProgress size={15}  style={{marginRight:'5px', color:'#222'}}/> : <PictureAsPdfIcon fontSize='small'
                                    style={{marginRight:'5px', fontSize:'16px'}} />}
                                    Download
                                    <Link
                                        style={{ display: "none" }}
                                        id={"bill_download"}
                                        target="_blank"
                                        onClick={(e) => e.stopPropagation()}
                                        download
                                    ></Link>
                            </Button>}
                            {can_add_payment && 
                            <Button fullWidth className={classes.buttonStyles}
                                onClick={()=>{
                                    props.setOpenAddPamentBillDrawer(true);
                                    props.updateBillObj(billObj?.purchase_invoice);
                                    props.setFromPreviewScreen(props.editPreview);
                                }}
                                //disabled={accessLevelViewer}
                                style={{marginTop: "8px"}}
                                variant="outlined" color='secondary' >
                                <MonetizationOnOutlinedIcon fontSize='small'
                                    style={{marginRight:'5px', color:'green', fontSize:'16px'}} />
                                    Add payment
                            </Button>}

                            {can_cancel &&
                            <Button fullWidth
                                style={{marginTop: "8px"}}
                                className="cancel_button"
                                onClick={() => handleConfirmDialogOpen({cancel_invoice_clicked: true})}
                                //disabled={accessLevelViewer}
                                variant="contained" color='secondary'>
                                <CancelIcon fontSize='small'
                                    style={{marginRight:'5px', fontSize:'16px', color:'#fff'}} />
                                    Cancel
                            </Button>}
                            {/* <ClearIcon style={{position: 'absolute', right: '-5px', top: '-5px', color: 'white', background: "red", borderRadius: '50%', border: '1px solid red', cursor: 'pointer', width: '20px', height: '20px'}} 
                                onClick={() => props.handleBillDetailClickDialogClose()} /> */}
                        </Grid>}
                        
                        {!props.isFromUnclaimedBill && billObj?.purchase_invoice.activities && 
                            billObj?.purchase_invoice.activities.length>0 
                            && <ActivityFeed activityFeeds={billObj?.purchase_invoice.activities}
                                    onShowMoreActivities={onShowMoreActivities}
                                />
                        }
                    </Grid>
                </Grid>
            </Grid>
        }
        {openActivityDrawer && <AllActivityFeedDrawer openActivityDrawer={openActivityDrawer}
            handleCloseActivityDrawer={handleCloseActivityDrawer}
            obj={billObj?.purchase_invoice}
            type="bill"/>
        }

        {
            openConfirmDialog && <ShowConfirmDialog openConfirmDialog={openConfirmDialog}
                                    handleConfirmDialogClose={handleConfirmDialogClose}
                                    fromInvoiceListComponent={true}
                                    invoiceItem={invoiceItem}
                                    callCancelInvoiceApi={callCancelInvoiceApi}
                                    apiLoading={apiLoading}
                                    isFromBill={true}
                                />
        }               
        </>
    )
}

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
    user: state.invoiceAccounts.user,
    tdsTypeList: state.invoiceAccounts.tdsTypeList,
});

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(PreviewBillComponent);