import React, {useState, useEffect, useLayoutEffect} from 'react';
import { Container, Paper, Tabs, Tab, Grid, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import { usePropsContext } from '../context';
import DocumentList from './DocumentList';
import useRestoreComponentState from '../useRestoreComponentState';
import { history } from '../../routers/AppRouter';

const useStyles = makeStyles((theme) => ({
    mainContent: {
        backgroundColor: theme.palette.primary.light,
        transition: ".5s",
        flexGrow: 1,
        //overflow:'hidden',
        height: "calc(100vh - 48px)",
        overflow: "auto",
        overflowX: "hidden",
    },
    container: {
        marginTop: "0px",
    },
    tab: {
        flexGrow: 1,
        borderRadius: 0,
        borderBottom: "1px solid #c5b9b9",
    },
}));

const TabValues = {
    DOCUMENTS: 0,
    TEMPLATES: 1,
    PREDEFINED_TEMPLATES: 2
} 

const ESignDocuments = ({ ...props }) => {

    const classes = useStyles();

    const [value, setValue] = useState(TabValues.DOCUMENTS);
    const { fullName, handleDocumentModalOpen, documentsComponentState, setDocumentsComponentState} = usePropsContext();

    const { setScrollContainer, saveState, restoreState } = useRestoreComponentState(documentsComponentState, setDocumentsComponentState);
    const restoredState = restoreState();

    useEffect(() => {
        document.title = `Documents - ${fullName}`;
    }, []);

    useLayoutEffect(() => {
        if(Object.keys(restoredState).length > 0) {
            setValue(restoredState.value);
        }
    }, [restoredState])

    const handleChange = (event, newValue) => {
        documentsComponentState && setDocumentsComponentState();
        setValue(newValue);
    };

    const {selectedAccount, user} = props;

    const storeComponentState = (fromEdit, itemList, page, total, componentObj, templateId, showSendEmail) => {
        let str = value === TabValues.DOCUMENTS ? 'document' : 'template'
        const stateObj = {
            itemList,
            page,
            total,
            value,
            templateId,
            ...(props.fromClientDetails && {
                clientListState: {...props.clientComponentObj},
                clientInfo: {...props.showClientInfo},
                tabValue: props.tabValue,
                clientScrollPosition: props.scrollContainer?.scrollTop
            }),
        };
        saveState(stateObj);
        if(str === 'document') {
            history.push(`/${str}/${componentObj.slug}/${fromEdit ? 'edit' : 'preview'}`, {fromEditDocument:fromEdit, fromPreviewDocument:!fromEdit,  selectedDoc: componentObj}) 
        } else {
            history.push(`/${str}/${componentObj.slug}/edit`, {fromEditTemplate:fromEdit, selectedTemplate: componentObj});
        }
        handleDocumentModalOpen();
    }

    return (
        <div className={classes.mainContent}
            ref={(node) => setScrollContainer(node)}
        >
            <Container className={classes.container} maxWidth="xl">

                <Grid item container direction="column" style={{ marginTop: "3px" }}>
                    <Grid item container>
                        <Paper
                            className={classes.tab}
                            elevation={1}
                            style={{ marginTop: "10px" }}
                        >
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                indicatorColor="primary"
                                textColor="primary"
                            >
                            
                                <Tab label="Documents" />

                                <Tab label="Templates"/>

                                {selectedAccount?.id.toString() === process.env.REACT_APP_INVOICE_ACCOUNT_ID && user.id === process.env.REACT_APP_USER_ID && <Tab label="Predefined Templates"/>}
                            
                            </Tabs>
                        </Paper>
                </Grid>
                <Grid item style={{ backgroundColor: "white", marginBottom: "1rem" }}>
                    { 
                        value === TabValues.DOCUMENTS && <DocumentList value={value}
                                                                TabValues={TabValues}
                                                                storeComponentState={storeComponentState}
                                                            />
                    }

                    { 
                        value === TabValues.TEMPLATES && <DocumentList value={value}
                                                                TabValues={TabValues}
                                                                storeComponentState={storeComponentState}
                                                            />
                    }

                    { 
                        value === TabValues.PREDEFINED_TEMPLATES && <DocumentList value={value}
                                                                TabValues={TabValues}
                                                                storeComponentState={storeComponentState}
                                                            />
                    }           
                </Grid>
            </Grid>
        </Container>
    </div>
    );
}

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
    user: state.invoiceAccounts.user
})

export default connect(mapStateToProps)(ESignDocuments);