import { Button, Grid, makeStyles, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useState } from "react";
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import { consoleToLog } from "../util/AppUtil";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { connect } from "react-redux";
import ApplyTax from "./ApplyTax";
import AddEditBillCategoriesDrawer from "./AddEditBillCategoriesDrawer";
import CreateTaxPresetDrawer from "./CreateTaxPresetDrawer";

const MuiFormControlroot = {
    width:'100%',
    background:'#fff'
};

const useStyles = makeStyles((theme) => ({
    itemOptionStyle: {
        width: "100%", 
        height:'100%',
        color:'#777f8e',
        margin:'5px 0px'
    },
    MuiAutocompleteinputRoot : {
        '& .MuiOutlinedInput-root' : {
        padding:'0.5px 1px 0.5px 1px',
        borderRadius: '4px',
        }
    },
    customTextField: {
        "& input::placeholder": {
            paddingLeft: "8px",
        }
    },
    taxItemStyle: {
        padding: "8px 8px", 
        cursor: 'pointer',
        '&:hover': {
            background: '#f5f5f5'
        }
    },
    MuiTextFieldroot: {
        background:'#fff',
        // '& .MuiOutlinedInput-input': {
        //     paddingTop: '8px',
        //     paddingBottom: '8px'
        // }
    },
    dateBtn: {
        background:'#fff',
        padding:'5px 8px'
    }
}))

const BillLineItem=React.memo(({
    billCategoryList, lineItem, index, onDeleteLine, onUpdateLineItemsArr,
    currencyObj, setBillCategoryList, taxPresetsArr, CreateItemButton
})=>{
    const classes = useStyles();
    const [selectedBillCategory, setSelectedBillCategory] = useState(null);
    const [openBillCategoryDrawer, setOpenBillCategoryDrawer] = useState(false);
    const [description, setDescription]=useState("");
    const [billLineItem, setBillLineItem] = useState({...lineItem});
    const [openDrawer, setOpenDrawer] = useState(false);
    
    consoleToLog("****Rendering InvoiceLineItem Component:", billLineItem);

    const onBillCategoryChange = (e, newValue, reason) => {
        const newLineItem = { ...billLineItem };
        if(newValue){
            if(newValue.id !=='add_new'){
                newLineItem.category=newValue;
            }
        }
        else{
            newLineItem.category=null;
        }
        setBillLineItem(newLineItem);
        onUpdateLineItemsArr(newLineItem, index);
    }

    const updateLineItem = (localBillItem, i)=>{
        const newLineItem = { ...localBillItem };

        setBillLineItem(newLineItem);
        onUpdateLineItemsArr(newLineItem, i);
    }
    
    const handleCategoryDrawerClose = () => {
        setOpenBillCategoryDrawer(false);
    }

    const onDescriptionChange=(e)=>{
        const newLineItem = { ...billLineItem };
        let input = e.target.value;
        newLineItem.description=input;
        setBillLineItem(newLineItem);
        onUpdateLineItemsArr(newLineItem, index);
    }

    const onAmountChange = (e)=>{
        const newLineItem = { ...billLineItem };
        const input=e.target.value;
        if (input !== "" || /^\d+(\.\d{0,2})?$/.test(input)) {
            newLineItem.amount=parseFloat(input);
            //calculate tax
            newLineItem.tax=newLineItem.tax?.map((t)=>{
                return {...t, amount:(t.rate*parseFloat(input)/100)}
            })
        }
        else{
            newLineItem.amount=""
        }
        setBillLineItem(newLineItem);
        onUpdateLineItemsArr(newLineItem, index);
    }

    const handleCategoryDrawerOpen = () => {
        setOpenBillCategoryDrawer(true);
    }

    const handleDrawerOpen = (e) => {
        setOpenDrawer(true);
    }

    const handleDrawerClose = () => {
        setOpenDrawer(false);
    }

    const onAddBillCategoryClick = (category) => {
        setBillCategoryList(billCategoryList => [...billCategoryList, category]);
    }

    return (
        <>
        <Grid container style={{ marginBottom: '2px' }} spacing={1}>
            <Grid item md={3}>
                <TextField
                    size="small"
                    placeholder="Amount"
                    variant="outlined"
                    fullWidth
                    value={lineItem.amount}
                    onChange={onAmountChange}
                    classes={{
                        root: classes.MuiTextFieldroot
                    }}
                />
            </Grid>
            <Grid item md={4}>
                <Autocomplete
                    size="small"
                    id="bill_category"
                    getOptionLabel={(option) => option.name || ''}
                    value={lineItem?.category}
                    options={billCategoryList}
                    onChange={(e, newValue, reason) => onBillCategoryChange(e, newValue, reason)}
                    style={MuiFormControlroot}
                    renderInput={(params) => 
                        <TextField {...params} color="secondary" variant="outlined"  placeholder="Select Bill Category"
                        />}
                    renderOption={(option) =>  option.name}
                    PaperComponent={(props) => <CreateItemButton {...props} text='Category' onClick={handleCategoryDrawerOpen} />}
                    // renderOption={(option) => 
                    //     option.id === "add_new" ? (
                    //         <Button variant="text" color="primary" style={{ width: '100%' }}
                    //             onClick={()=>handleCategoryDrawerOpen()}>
                    //             <AddOutlinedIcon fontSize='small'
                    //                 style={{
                    //                     marginRight:'8px',
                    //                         fontSize: '16px'
                    //                         }}
                    //             />
                    //             Add New
                    //         </Button>
                    //     ) : (
                    //         option.name
                    //     )
                    // }
                    getOptionSelected={(option, value) => option.id === value.id}
                />
            </Grid>
            <Grid item md={4}>
                <TextField
                    size="small"
                    placeholder="Description"
                    variant="outlined"
                    fullWidth
                    value={lineItem?.description}
                    onChange={onDescriptionChange}
                    classes={{
                        root: classes.MuiTextFieldroot
                    }}
                />
            </Grid>
            <Grid item sm={1}
                style={{
                    flexBasis:'5%',
                    maxWidth:'5%',
                    textAlign:'center',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <DeleteOutlineIcon fontSize='small'
                        style={{ color: 'red', cursor: "pointer" }}
                        onClick={() => onDeleteLine(index)}
                        className={classes.binIcon}/>
            </Grid>
        </Grid>
            {billLineItem && <ApplyTax billLineItem={billLineItem}
                currencyObj={currencyObj}
                index={index}
                updateLineItem={updateLineItem}
                handleDrawerOpen={handleDrawerOpen}
                taxPresetsArr={taxPresetsArr}/>
            }
            {openDrawer && <CreateTaxPresetDrawer openDrawer={openDrawer}
                handleDrawerClose={handleDrawerClose}
            />
        }
            {openBillCategoryDrawer && <AddEditBillCategoriesDrawer
                openDrawer={openBillCategoryDrawer} 
                handleDrawerClose={handleCategoryDrawerClose}
                onAddItemsClick={onAddBillCategoryClick}
            />
            }
        </>
    )
})


const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
    user: state.invoiceAccounts.user,
    tdsTypeList: state.invoiceAccounts.tdsTypeList,
});

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(BillLineItem);