import React, {useEffect, useState} from 'react';
import { Dialog, Slide, Grid, Button, Box, Typography, CircularProgress } from '@material-ui/core';
import {consoleToLog, getUrlAccordingToSelectedComponent, DroppedItemTypes, splitArray, transformArray, DocumentStatus} from '../../util/AppUtil';
import {connect} from 'react-redux';
import {history} from '../../routers/AppRouter';
import {makeStyles} from '@material-ui/core/styles';
import DocumentLayout from './DocumentLayout';
import { Spinner } from '../Spinner';
import { useSnackbar } from 'notistack';
import DocumentNameModal from './DocumentNameModal';
import { getDocumentBySlugApi, createTemplateApi, createDocumentApi, updateTemplateApi, updateDocumentApi} from '../../services/documentService';
import { useLocation } from 'react-router-dom';
import {getTemplateBySlugApi, createDocumentFromTemplateApi, createTemplateFromDocumentApi, revokeDocumentApi} from '../../services/documentService';
import { useIsMount } from '../useIsMount';
import { createPredefinedTemplateApi, updatePredefinedTemplateApi } from '../../services/documentService';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    MuiDialogpaper: {
        background:theme.palette.primary.light,
        color:'#222',
        position:'relative',
        height:'100vh'
        
    },

    buttonContainer: {
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        position:'fixed',
        right:0,
        left:0,
        bottom:0,
        padding:'16px 32px',
        background:'#ffffff',
        boxShadow: '0px 0px 15px 0px #00000026'

    }
    }
))

const DocumentModal = ({openDocumentModal, handleDocumentModalClose, setUpdatedDocumentObj, ...props  }) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const location = useLocation();
    const isMount = useIsMount();

    const [documentObj, setDocumentObj] = useState(undefined);
    const [uploadedPdf, setUploadedPdf] = useState('');
    const [showCreateEditTemplateScreen, setShowCreateEditTemplateScreen] = useState(false);
    const [showCreateEditDocumentScreen, setShowCreateEditDocumentScreen] = useState(false);
    const [showPreviewDocumentScreen, setShowPreviewDocumentScreen] = useState(false);
    const [openDocumentNameModal, setOpenDocumentNameModal] = useState(false);
    const [documentName, setDocumentName] = useState('');
    const [loading, setLoading] = useState(true);
    const [callTemplateApi, setCallTemplateApi] = useState(false);
    const [pdfFileObj, setPdfFileObj] = useState({});
    const [templateObj, setTemplateObj] = useState(undefined);
    const [createDocumentFromTemplate, setCreateDocumentFromTemplate] = useState(false);
    const [createTemplateFromDocument, setCreateTemplateFromDocument] = useState(false);
    const [showSendEmail, setShowSendEmail] = useState(false);

    const [predefinedTemplateObj, setPredefinedTemplateObj] = useState(undefined);
    const [callPredefinedTemplateApi, setCallPredefinedTemplateApi] = useState(false);


    const [droppedItems, setDroppedItems] = useState([]);
    const [transformedArray, setTransformedArray] = useState([]);
    const [apiLoading, setApiLoading] = useState(false);
    const [pdfRendered, setPdfRendered] = useState(false);

    //console.log('droppedItems', droppedItems)

    useEffect(() => {
        if(location.state?.documentCreate) {
            setPdfFileObj(location.state?.uploadedPdf);
            const reader = new FileReader();
            reader.onload = () => {
                setUploadedPdf(reader.result); // Store the Data URL in the state
            };
            reader.readAsDataURL(location.state?.uploadedPdf);
            setLoading(false);
            setShowCreateEditTemplateScreen(true);
        }   
    }, [location.state?.documentCreate]);

    useEffect(() => {
        if(location.state?.documentFromTemplate || location.state?.fromEditTemplate) {
            fetchTemplateDetails();
        }   
    }, [location.state?.documentFromTemplate]);

    useEffect(() => {
        if(location.state?.fromEditDocument || location.state?.fromPreviewDocument) {
            fetchDocumentDetails();
        }   
    }, [location.state?.fromEditDocument]);

    useEffect(() => {
        if(location.state?.fromRevoke) {
            callRevokeDocumentApi(documentObj?.id);
        }   
    }, [location.state?.fromRevoke]);

    useEffect(() => {
        if(!isMount) {
            if((showCreateEditTemplateScreen || showCreateEditDocumentScreen || showPreviewDocumentScreen)) {
                setLoading(false);
            }
        }
    }, [showCreateEditTemplateScreen, showCreateEditDocumentScreen, showPreviewDocumentScreen]);

    useEffect(() => {
        if(!isMount) {
            if(pdfRendered) {
                
                if(templateObj || documentObj || predefinedTemplateObj) {
                    const canvasRectArr = document.querySelectorAll('.react-pdf__Page__canvas');
                    const canvasEl = canvasRectArr[0];
                    if(canvasEl) {
                        const canvasRect = canvasEl.getBoundingClientRect();
                        const obj = templateObj || documentObj || predefinedTemplateObj;
                        const splitTransformedArray = splitArray(obj.components, canvasRect.width, canvasRect.height);
                        setTransformedArray([...obj.components]);
                        setDroppedItems([...splitTransformedArray]);
                        setPdfRendered(false);
                    }
                }
            }
        }
    }, [pdfRendered]);

    const {selectedAccount, drawerState, user} = props;
    const invoice_account_id = selectedAccount.id;
    const template_slug = location.state?.selectedTemplate?.slug;
    const document_slug = location.state?.selectedDoc?.slug;
    const edit_template = location.state?.fromEditTemplate;
    const edit_document = location.state?.fromEditDocument;
    const preview_document = location.state?.fromPreviewDocument;
    const document_from_template = location.state?.documentFromTemplate;

    const fetchTemplateDetails = async() => {

        try {
            const response = await getTemplateBySlugApi(invoice_account_id, template_slug)
            const res = response.data;
            consoleToLog('Response getTemplateBySlugApi: ', res);

            if(document_from_template) {
                setCreateDocumentFromTemplate(true);
                setShowCreateEditDocumentScreen(true);
            } else {
                setShowCreateEditTemplateScreen(true);
                setDocumentName(res.name);
            }
            if(res.is_predefined) {
                setPredefinedTemplateObj({...res});
            } else {
                setTemplateObj({...res});
            }

            setUploadedPdf(res.pdf_path_url);
            
        } catch(e) {
            consoleToLog('Error getTemplateBySlugApi: ', e.response);
            if(e.response?.data?.message) {
                enqueueSnackbar(e.response.data.message, {variant:'error'});
                return;
            }
        }
    }

    const fetchDocumentDetails = async() => {

        try {
            const response = await getDocumentBySlugApi(invoice_account_id, document_slug)
            const res = response.data;
            consoleToLog('Response getDocumentBySlugApi: ', res);

            if(location.state?.fromEditDocument) {
                if(res.can_revoke && res.status === DocumentStatus.PENDING_SIGNTAURE) {
                    callRevokeDocumentApi(res.id);
                } else {
                    setDocumentObj({...res});
                    setShowCreateEditDocumentScreen(true);
                    setUploadedPdf(res.pdf_path_url);
                }
            } else {
                setShowCreateEditDocumentScreen(false);
                setShowCreateEditTemplateScreen(false);
                setShowPreviewDocumentScreen(true);
                setUploadedPdf(res.generated_pdf_path_url);
                setDocumentObj({...res});
            }
            setDocumentName(res.name);

            // const splitTransformedArray = splitArray(res.components);
            // //console.log('splitTransformedArray', splitTransformedArray)
            // setDroppedItems([...splitTransformedArray]);
            // setTransformedArray([...res.components])

            
        } catch(e) {
            consoleToLog('Error getDocumentBySlugApi: ', e.response);
            if(e.response?.data?.message) {
                enqueueSnackbar(e.response.data.message, {variant:'error'});
                return;
            }
        }
    }

    const callRevokeDocumentApi = async(document_id) => {

        try {

            const response = await revokeDocumentApi(invoice_account_id, document_id);
            const res = response.data;
            consoleToLog('Response revokeDocumentApi: ', res);

            setDocumentObj({...res});
            setUpdatedDocumentObj({...res});
            setShowCreateEditDocumentScreen(true);
            setUploadedPdf(res.pdf_path_url);
            

        } catch (error) {
            consoleToLog("Error revokeDocumentApi", error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    }

    const onCloseDocumentModal = () => {
        history.push(getUrlAccordingToSelectedComponent(drawerState));
        handleDocumentModalClose();
    }

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setPdfFileObj(e.target.files[0]);
        if (file && file.type === "application/pdf") {
            const reader = new FileReader();
            reader.onload = () => {
                setUploadedPdf(reader.result); // Store the Data URL in the state
            };
            reader.readAsDataURL(file);
        } else {
            enqueueSnackbar("Please select a valid PDF file.", {variant:'error'});
            return;
        }
        setShowCreateEditDocumentScreen(true);
    }

    const onNextClick = () => {
        const emptySocialLinks = droppedItems.filter((item) => !item.component_label);
        if(emptySocialLinks && emptySocialLinks.length > 0) {
            enqueueSnackbar('Please set labels for all dropped items', {variant:'error'});
            return;
        } else {
            setShowCreateEditDocumentScreen(true);
            setShowCreateEditTemplateScreen(false);
        }

    }

    const validateDroppedItemArray = (transformedArray) => {
        for (var i = 0; i < transformedArray.length; i++) {
            if (transformedArray[i].component_type === DroppedItemTypes.TEXT && transformedArray[i].value_json.text === '') {
                return `Text Field cannot be empty fot label ${transformedArray[i].component_label}`;
            }

            if (transformedArray[i].component_type === DroppedItemTypes.DATE && transformedArray[i].value_json.date === '') {
                return `Set Date for label ${transformedArray[i].component_label}`;
            }

            if (transformedArray[i].component_type === DroppedItemTypes.SIGNATURE && 
                (transformedArray[i].value_json.signed_img_path === '' &&
                    transformedArray[i].value_json.name === '' &&
                    transformedArray[i].value_json.member_id === '' &&
                    transformedArray[i].value_json.email === ''
                )
                ) {
                return `Please either set sign image, select name or enter your name for ${transformedArray[i].component_label}`;
            }
        }
    }

    const onCreateDocumentClick = () => {
        let obj = documentObj || templateObj || predefinedTemplateObj; 

        const transformedArray = transformArray(droppedItems, obj ? true : false);
        setTransformedArray([...transformedArray]);
        //console.log('transformed Array', transformedArray);
        const errorString = validateDroppedItemArray(transformedArray);
        if(errorString) {
            enqueueSnackbar(errorString, {variant: 'error'});
            return; 
        } else {
            handleDocumentNameModalOpen();
        }
    }

    const onBackClick = () => {
        setShowCreateEditDocumentScreen(false);
        setShowCreateEditTemplateScreen(true);
    }

    const onSaveTemplateClick = (fromPredefinedTemplate) => {
        let obj = documentObj || templateObj || predefinedTemplateObj; 
        const transformedArray = transformArray(droppedItems, obj ? true : false);
        //console.log('transformed', transformedArray)
        setTransformedArray([...transformedArray]);
        const emptySocialLinks = transformedArray.filter((item) => !item.component_label);
        if(emptySocialLinks && emptySocialLinks.length > 0) {
            enqueueSnackbar('Please set labels for all dropped items', {variant:'error'});
            return;
        } else {
            fromPredefinedTemplate ? setCallPredefinedTemplateApi(true) : setCallTemplateApi(true);
            if((documentObj?.id || predefinedTemplateObj)) setCreateTemplateFromDocument(true);
            handleDocumentNameModalOpen();
        }
    }

    const handleDocumentNameModalOpen = () => {
        setOpenDocumentNameModal(true);
    }

    const handleDocumentNameModalClose = () => {
        setOpenDocumentNameModal(false);
    }

    const FooterButtons = ({}) => {
        return (
            <div className={classes.buttonContainer}>

                {showCreateEditTemplateScreen && selectedAccount?.id.toString() === process.env.REACT_APP_INVOICE_ACCOUNT_ID && 
                user.id === process.env.REACT_APP_USER_ID && !documentObj?.id && !templateObj?.id ?
                <Grid item>
                    <Button disabled={droppedItems && droppedItems.length === 0}
                        onClick={() => onSaveTemplateClick(true)}
                        className='footer_btn_preview' style={{width:'220px', opacity: droppedItems && droppedItems.length > 0 ? 1 : 0.3}}>
                            {apiLoading && callPredefinedTemplateApi && <CircularProgress size={18}  className='loading_style' />}
                            Save Predefined Template 
                    </Button>
                </Grid>
                :
                <Grid item></Grid>
                }

                {showCreateEditTemplateScreen ?
                <Grid item>
                    <Grid item container alignItems='center' spacing={1}>

                        <Grid item>
                            <Button 
                                onClick={onCloseDocumentModal}
                                className='footer_btn footer_btn_cancel_doc'>
                                Close
                            </Button>
                        </Grid>

                        {(!documentObj || documentObj?.template_id === null) &&
                        <Grid item>
                            <Button disabled={droppedItems && droppedItems.length === 0}
                                onClick={() => onSaveTemplateClick(false)}
                                className='footer_btn_preview' style={{width:'150px', opacity: droppedItems && droppedItems.length > 0 ? 1 : 0.3}}>
                                    {apiLoading && callTemplateApi && <CircularProgress size={18}  className='loading_style' />}
                                    Save Template 
                            </Button>
                        </Grid>}

                        {!edit_template &&
                        <Grid item>
                            <Button disabled={droppedItems && droppedItems.length === 0} 
                                onClick={onNextClick}
                                style={{width:'200px', opacity: droppedItems && droppedItems.length > 0 ? 1 : 0.3}}
                                className='footer_btn footer_btn_finish'>
                                    Next
                            </Button>
                        </Grid>}
                    </Grid>
                </Grid>
                :
                showCreateEditDocumentScreen &&
                <Grid item>
                    <Grid item container alignItems='center' spacing={1}>
                        
                        <Grid item>
                            <Button 
                                onClick={onCloseDocumentModal}
                                className='footer_btn footer_btn_cancel_doc'>
                                Close
                            </Button>
                        </Grid>
                            
                        <Grid item>
                            <Button onClick={onBackClick} 
                                className='footer_btn footer_btn_preview'>
                                Edit Template
                            </Button>
                        </Grid>

                        <Grid item>
                            <Button disabled={droppedItems && droppedItems.length === 0} 
                                onClick={onCreateDocumentClick}
                                style={{width:'200px'}}
                                className='create_doc_btn'>
                                    {apiLoading && !callTemplateApi && <CircularProgress size={18}  className='loading_style' />}
                                    {documentObj?.id ? 'Update Document' : 'Create Document'}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>}
            </div>
        )
    }
    
    const callApi = () => {
        if(callPredefinedTemplateApi) {
            if(predefinedTemplateObj?.id) {
                callPredefinedUpdateTemplateApi();
            } else {
                callCreatePredefinedTemplateApi();
            }
        }else if(callTemplateApi) {
            if(templateObj?.id) {
                callUpdateTemplateApi();
            } else {
                if(createTemplateFromDocument) {
                    callCreateTemplateFromDocumentApi();
                } else {
                    callCreateTemplateApi();
                }
            }
        } else {
            if(documentObj?.id) {
                callUpdateDocumentApi();
            } else {
                if(createDocumentFromTemplate) {
                    callCreateDocumentFromTemplateApi();
                } else {
                    callCreateDocumentApi();
                }
            }
        }
    }

    const callCreateTemplateApi = async() => {
        const name = documentName;
        let components = transformedArray;
        const document_pdf = pdfFileObj || predefinedTemplateObj?.pdf_path;
    
        setApiLoading(true);

        try {

            // Create document with processed signatures
            const response = await createTemplateApi(invoice_account_id, name, components, document_pdf)
            const res = response.data;
            consoleToLog('Response createTemplateApi: ', res);

                callTemplateApi && setCallTemplateApi(false);
                setTemplateObj(res);
                setCreateDocumentFromTemplate(true);
                enqueueSnackbar(`Template created successfully`, {variant:'success'});

                setApiLoading(false);

        } catch(e) {
            consoleToLog('Error createTemplateApi: ', e.response);
            setApiLoading(false);
            enqueueSnackbar(`Error creating Template`, {variant:'error'})
            if(e.response?.data?.message) {
                //enqueueSnackbar(e.response.data.message, {variant:'error'});
                return;
            }
        }
    }

    const callCreateDocumentApi = async() => {
        const name = documentName;
        let components = transformedArray;
        const document_pdf = pdfFileObj;
    
        setApiLoading(true);

        try {

            // Create document with processed signatures
            const response = await createDocumentApi(invoice_account_id, name, components, document_pdf)
            const res = response.data;

            consoleToLog('Response createDocumentApi: ', res);
            setUploadedPdf(res.generated_pdf_path_url);
            setShowCreateEditDocumentScreen(false);
            setShowPreviewDocumentScreen(true);
            setDocumentObj({...res});
        

            enqueueSnackbar(`Document created successfully`, {variant:'success'});

            setApiLoading(false);

        } catch(e) {
            consoleToLog('Error createDocumentApi: ', e.response);
            setApiLoading(false);
            enqueueSnackbar(`Error creating Document`, {variant:'error'})
            if(e.response?.data?.message) {
                //enqueueSnackbar(e.response.data.message, {variant:'error'});
                return;
            }
        }
    }


    const callUpdateTemplateApi = async() => {
        const name = documentName;
        let components = transformedArray;
        const document_pdf = documentObj?.pdf_path || templateObj?.pdf_path;
        const template_id = templateObj?.id;
    
        setApiLoading(true);

        try {

            // Create document with processed signatures
            const response = await updateTemplateApi(invoice_account_id, template_id, name, components, undefined)
            const res = response.data;

            consoleToLog('Response updateTemplateApi: ', res);
            callTemplateApi && setCallTemplateApi(false);
            setTemplateObj(res);
            setUpdatedDocumentObj({...res});
            
            setCreateDocumentFromTemplate(true);

            enqueueSnackbar(`Template updated successfully`, {variant:'success'});

            setApiLoading(false);

        } catch(e) {
            consoleToLog('Error updateDocumentOrTemplateApi: ', e.response);
            setApiLoading(false);
            enqueueSnackbar(`Error updating Template`, {variant:'error'})
            if(e.response?.data?.message) {
                //enqueueSnackbar(e.response.data.message, {variant:'error'});
                return;
            }
        }
    }

    const callUpdateDocumentApi = async() => {
        const name = documentName;
        let components = transformedArray;
        const document_pdf = documentObj?.pdf_path || templateObj?.pdf_path;
        const document_id = documentObj?.id;
        const template_id = templateObj?.id || documentObj?.template_id;
    
        setApiLoading(true);

        try {

            // Create document with processed signatures
            const response = await updateDocumentApi(invoice_account_id, document_id, name, components, template_id)
            const res = response.data;

            consoleToLog('Response updateDocumentApi: ', res);
            setShowCreateEditDocumentScreen(false);
            setShowPreviewDocumentScreen(true);
            setDocumentObj({...res});
            setUploadedPdf(res.generated_pdf_path_url);
        
            createDocumentFromTemplate && setCreateDocumentFromTemplate(false);
            setUpdatedDocumentObj({...res});

            enqueueSnackbar(`Document updated successfully`, {variant:'success'});

            history.push(`/document/${documentObj.slug}/preview`);
            setApiLoading(false);

        } catch(e) {
            consoleToLog('Error updateDocumentApi: ', e.response);
            setApiLoading(false);
            enqueueSnackbar(`Error updating 'Document`, {variant:'error'})
            if(e.response?.data?.message) {
                //enqueueSnackbar(e.response.data.message, {variant:'error'});
                return;
            }
        }
    }

    const callCreateDocumentFromTemplateApi = async() => {
        const name = documentName;
        const components = transformedArray;
        const template_id = templateObj?.id ? templateObj?.id : predefinedTemplateObj?.id || documentObj?.template_id;
    
        setApiLoading(true);

        try {

            const response = await createDocumentFromTemplateApi(invoice_account_id, template_id, name, components)
            const res = response.data;

            consoleToLog('Response createDocumentFromTemplateApi: ', res);
            setUploadedPdf(res.generated_pdf_path_url);
            setTemplateObj(undefined);
            predefinedTemplateObj && setPredefinedTemplateObj(undefined);
            setDocumentObj({...res});
            setShowCreateEditDocumentScreen(false);
            setShowPreviewDocumentScreen(true);
            createDocumentFromTemplate && setCreateDocumentFromTemplate(false);

            enqueueSnackbar(`Document created successfully`, {variant:'success'});
        
            setApiLoading(false);

        } catch(e) {
            consoleToLog('Error createDocumentFromTemplateApi: ', e.response);
            setApiLoading(false);
            enqueueSnackbar(`Error creating document`, {variant:'error'});
            if(e.response?.data?.message) {
                //enqueueSnackbar(e.response.data.message, {variant:'error'});
                return;
            }
        }
    }

    const callCreateTemplateFromDocumentApi = async() => {
        const name = documentName;
        let components = transformedArray.map((doc) => {
            return {
                ...doc,
                value_json: undefined
            }
        });
        const document_pdf_path = documentObj?.pdf_path || predefinedTemplateObj?.pdf_path;
    
        setApiLoading(true);

        try {

            const response = await createTemplateFromDocumentApi(invoice_account_id, name, components, document_pdf_path)
            const res = response.data;

            consoleToLog('Response createTemplateFromDocumentApi: ', res);
            
            callTemplateApi && setCallTemplateApi(false);
            setTemplateObj(res);
            documentObj && setCreateDocumentFromTemplate(true);

            enqueueSnackbar(`Template created successfully`, {variant:'success'});

            setApiLoading(false);

        } catch(e) {
            consoleToLog('Error createTemplateFromDocumentApi: ', e.response);
            setApiLoading(false);
            enqueueSnackbar(`Error creating Template`, {variant:'error'})
            if(e.response?.data?.message) {
                //enqueueSnackbar(e.response.data.message, {variant:'error'});
                return;
            }
        }
    }

    const callCreatePredefinedTemplateApi = async() => {
        const name = documentName;
        let components = transformedArray;
        const document_pdf = pdfFileObj;
    
        setApiLoading(true);

        try {

            // Create document with processed signatures
            const response = await createPredefinedTemplateApi(invoice_account_id, name, components, document_pdf)
            const res = response.data;
            consoleToLog('Response createPredefinedTemplateApi: ', res);

                callPredefinedTemplateApi && setCallPredefinedTemplateApi(false);
                setPredefinedTemplateObj(res);
                setCreateDocumentFromTemplate(true);
                enqueueSnackbar(`Template created successfully`, {variant:'success'});

                setApiLoading(false);

        } catch(e) {
            consoleToLog('Error createPredefinedTemplateApi: ', e.response);
            setApiLoading(false);
            enqueueSnackbar(`Error creating Template`, {variant:'error'})
            if(e.response?.data?.message) {
                //enqueueSnackbar(e.response.data.message, {variant:'error'});
                return;
            }
        }
    }

    const callPredefinedUpdateTemplateApi = async() => {
        const name = documentName;
        let components = transformedArray;
        const template_id = predefinedTemplateObj?.id;
    
        setApiLoading(true);

        try {

            // Create document with processed signatures
            const response = await updatePredefinedTemplateApi(invoice_account_id, template_id, name, components, undefined)
            const res = response.data;

            consoleToLog('Response updatePredefinedTemplateApi: ', res);
            callPredefinedTemplateApi && setCallPredefinedTemplateApi(false);
            setPredefinedTemplateObj(res);
            setUpdatedDocumentObj({...res});

            enqueueSnackbar(`Template updated successfully`, {variant:'success'});

            setApiLoading(false);

        } catch(e) {
            consoleToLog('Error updatePredefinedTemplateApi: ', e.response);
            setApiLoading(false);
            enqueueSnackbar(`Error updating Template`, {variant:'error'})
            if(e.response?.data?.message) {
                //enqueueSnackbar(e.response.data.message, {variant:'error'});
                return;
            }
        }
    }

    return (
        <Dialog open={openDocumentModal} 
        onClose={onCloseDocumentModal} 
        TransitionComponent={Transition}
        scroll="paper"
        classes={{
            paper: classes.MuiDialogpaper}}
        fullScreen
        style={{zIndex: "100"}}>

        {
            !loading ?
            !uploadedPdf ?
            <Box display='flex' alignItems='center' justifyContent='center' height='100vh'>
                <Grid item>
                    <Typography variant='h5'>
                        Please upload PDF to continue
                    </Typography>

                    <Grid item container justifyContent='center' 
                        alignItems='center' 
                        spacing={1}
                        style={{marginTop:'8px'}}
                        >
                        <Grid item>
                            <label>
                                <Button size='large' component='div' variant='contained' color='secondary'>
                                    Upload
                                </Button>
                                <input style={{display:'none'}}
                                    type="file"
                                    id="document_pdf"
                                    accept=".pdf"
                                    onChange={handleFileChange}
                                />
                            </label>
                        </Grid>

                        <Grid item>
                            <Button onClick={onCloseDocumentModal} 
                                size='large' 
                                variant='outlined' 
                                color='secondary'>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            :
            (showCreateEditDocumentScreen || showCreateEditTemplateScreen || showPreviewDocumentScreen) &&
            <DocumentLayout uploadedPdf={uploadedPdf} 
                showCreateEditTemplateScreen={showCreateEditTemplateScreen}
                showCreateEditDocumentScreen={showCreateEditDocumentScreen}
                droppedItems={droppedItems}
                setDroppedItems={setDroppedItems}
                setShowCreateEditDocumentScreen={setShowCreateEditDocumentScreen}
                setShowCreateEditTemplateScreen={setShowCreateEditTemplateScreen}
                setShowPreviewDocumentScreen={setShowPreviewDocumentScreen}
                showPreviewDocumentScreen={showPreviewDocumentScreen}
                showSendEmail={showSendEmail}
                setShowSendEmail={setShowSendEmail}
                document_id={documentObj?.id}
                setDocumentObj={setDocumentObj}
                documentObj={documentObj}
                onCloseDocumentModal={onCloseDocumentModal}
                setUpdatedDocumentObj={setUpdatedDocumentObj}
                setUploadedPdf={setUploadedPdf}
                setPdfRendered={setPdfRendered}
                templateObj={templateObj}
                predefinedTemplateObj={predefinedTemplateObj}
            />
            :
            <Spinner />
        }
        { (showCreateEditDocumentScreen || showCreateEditTemplateScreen) && uploadedPdf && <FooterButtons />}

        {
            openDocumentNameModal &&
            <DocumentNameModal openDocumentNameModal={openDocumentNameModal}
                handleDocumentNameModalClose={handleDocumentNameModalClose}
                documentName={documentName}
                setDocumentName={setDocumentName}
                callApi={callApi}
                templateObj={templateObj}
                documentObj={documentObj}
                callTemplateApi={callTemplateApi}
                setCallTemplateApi={setCallTemplateApi}
                callPredefinedTemplateApi={callPredefinedTemplateApi}
                predefinedTemplateObj={predefinedTemplateObj}
                setCallPredefinedTemplateApi={setCallPredefinedTemplateApi}
            />
        }
    </Dialog>
    );
}

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
    drawerState: state.drawerState.setDrawerState,
    user: state.invoiceAccounts.user
})

export default connect(mapStateToProps)(DocumentModal);