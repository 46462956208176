import { Button, FormControl, FormControlLabel, Grid, makeStyles, Modal, Radio, RadioGroup, TextField, Typography } from "@material-ui/core";
import { useState } from "react";
import { connect } from "react-redux";
import { formatAmount } from "../util/currencyUtil";
import { useSnackbar } from "notistack";


const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        position: 'absolute',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        width:'450px',
        height: "250px",
        outline:'none',
        paddingLeft: "20px",
        paddingTop: "20px"
    },
    MuiFormControlroot: {
        '& .MuiOutlinedInput-input' :{
             paddingTop: '10px',
             paddingBottom: '10px'
        },   
    },
}));


const ShowPaymentSelectionModal = (props) => {
    const classes = useStyles();
    const [value, setValue] = useState('full');
    const [partial_amt, setPartialAmount]=useState(0.00);
    const {enqueueSnackbar} = useSnackbar();

    const total_amount = props.invoiceEstimateObj?.due - props.invoiceEstimateObj?.tds_amount;

    const handleChange = (event) => {
        setPartialAmount(0);
        setValue(event.target.value);
    };

    return(
        <Modal
            open={props.openPaymentModal}
            onClose={()=>props.handlePaymentModalClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className={classes.modal}
            >
            <div className={classes.paper}>
                <Grid item sm={12}>
                    <Typography variant="h6" style={{ textAlign: "center",marginBottom: "10px" }}>
                        Select A Payment Type
                    </Typography>
                    <FormControl component="fieldset">
                        <RadioGroup aria-label="payment-type" 
                            name="payment-type" 
                            value={value}
                            onChange={handleChange}>
                            <FormControlLabel value="full" control={<Radio />} 
                            label={
                                <>
                                    Full Payment ({formatAmount(total_amount, props.invoiceEstimateObj?.currencies)}) 
                                    {props.invoiceEstimateObj?.tds_amount > 0 && 
                                        <span style={{ fontSize: '13px', marginLeft: '8px' }}>
                                            (TDS Deducted: {formatAmount(props.invoiceEstimateObj.tds_amount, props.invoiceEstimateObj?.currencies)})
                                        </span>
                                    }
                                </>
                            }/>
                            <FormControlLabel value="partial" control={<Radio />} label="Partial Payment" />
                        </RadioGroup>
                    </FormControl>
                    {<Grid item md={12} style={{marginLeft: "20px"}}>
                        <TextField  
                            id="payment" 
                            variant="outlined"
                            type="number"
                            margin="dense"
                            autoComplete="off"
                            disabled={value !== 'partial'}
                            value={partial_amt}
                            onChange={(e)=>{
                                let input = e.target.value;
                                // Regular expression to allow only numbers with up to 2 decimal places
                                if (input === "" || /^\d+(\.\d{0,2})?$/.test(input)) {
                                    setPartialAmount(input);
                                }
                            }}
                            label="Enter Amount"
                        />
                    </Grid>}
                    <Grid item md={12} style={{ marginTop: '20px', textAlign: "center" }}>
                        <Button variant="contained" color="primary" 
                            onClick={() => {
                                if(value === 'full'){
                                    props.callPayNow(total_amount, false)
                                    props.handlePaymentModalClose();
                                }
                                else{
                                    if(!partial_amt){
                                        enqueueSnackbar("Invalid Amount!", {variant:'error'})
                                    }
                                    else{
                                        if(partial_amt<=props.invoiceEstimateObj?.due){
                                            props.callPayNow(partial_amt, true)
                                            props.handlePaymentModalClose();
                                        }
                                        else{
                                            enqueueSnackbar("Amount is higher than due amount!", {variant:'error'})
                                        }
                                    }
                                }
                            }} 
                            style={{width: '150px', marginRight: '10px'}}>
                            Pay
                        </Button>
                        <Button variant="outlined" color="primary" 
                            onClick={() => {props.handlePaymentModalClose()}} style={{width: '150px'}}>
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </div>
            </Modal>
    )
}

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount
})

export default connect(mapStateToProps)(ShowPaymentSelectionModal);