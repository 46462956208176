import {getAxios} from './axiosUtil';


export const createProposalApi = (invoice_account_id, entity_id, client_id, entity_name, currency_id, business_information, items, quantity_terminology, quantity_enable) => {
    const data = {
        invoice_account_id,
        entity_name,
        currency_id, 
        business_information, 
        items,
        quantity_terminology,
        quantity_enable,
        emails: null,
        email_message: null,
        rejection_reason: null,
    }
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/entity/${entity_id}/client/${client_id}/proposal/create`, data);
}

export const getProposalByTaskApi = (invoice_account_id, task_uuid) => {
    const param = {
        params: {
            task_uuid
        }
    }
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/proposal/get`, param);
} 

export const getProposalListApi = (invoice_account_id, page, issue_start_date, issue_end_date, status, entities, clients, activity_status) => {
    const param = {
        params: {
            issue_start_date, 
            issue_end_date, 
            page, 
            status, 
            entities, 
            clients,
            activity_status
        }
    }
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/proposal/all`, param);
}

export const getProposalBySlugApi = (invoice_account_id, slug) => {
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/proposal/${slug}/get`);
}

export const changeOrderOfProposalSectionApi = (invoice_account_id, proposal_id, section_id, order_number) => {
    const data = {order_number};
    return getAxios().patch(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/proposal/${proposal_id}/section/${section_id}/order_number/update`, data);
}

export const showProposalSectionApi = (invoice_account_id, proposal_id, section_id, show_page) => {
    const data = {show_page};
    return getAxios().patch(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/proposal/${proposal_id}/section/${section_id}/show_page/update`, data);
}

export const updateProposalSectionApi = (invoice_account_id, proposal_id, section_id, type, show_page, new_page, order_number, data) => {
    const updated_data = {
        type,
        new_page,
        show_page,
        order_number,
        data
    };
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/proposal/${proposal_id}/section/${section_id}/update`, updated_data);
}

export const addProposalSectionApi = (invoice_account_id, proposal_id, type, show_page, new_page, order_number, data) => {
    const updated_data = {
        type, 
        show_page, 
        new_page, 
        order_number,
        data
    };
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/proposal/${proposal_id}/section/add`, updated_data);
}


export const updateProposalTitleApi = (invoice_account_id, proposal_id, name) => {
    const data = {name};
    return getAxios().patch(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/proposal/${proposal_id}/name/update`, data);
}

export const updateQuantitySettingsApi = (invoice_account_id, proposal_id, quantity_terminology, quantity_enable) => {
    const data = {quantity_terminology, quantity_enable};
    return getAxios().patch(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/proposal/${proposal_id}/update_quantity_settings`, data);
}

export const updateCurrencyApi = (invoice_account_id, proposal_id, currency_id) => {
    const data = {currency_id};
    return getAxios().patch(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/proposal/${proposal_id}/update_currency`, data);
}

export const updateOrCreateProposalItemsApi = (invoice_account_id, proposal_id, proposal_items) => {
    const data = {proposal_items};
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/proposal/${proposal_id}/update_or_create_proposal_items`, data);
}

export const deleteProposalItemsApi = (invoice_account_id, proposal_id, proposal_item_id) => {
    return getAxios().delete(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/proposal/${proposal_id}/proposal_item/${proposal_item_id}/delete`);
}

export const deleteProposalApi = (invoice_account_id, proposal_id) => {
    return getAxios().delete(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/proposal/${proposal_id}/delete`);
}

export const updateProposalBillingSectionApi = (invoice_account_id, proposal_id, section_id, section, proposal_items, currency_id) => {
    const data = {
        section,
        proposal_items,
        currency_id
    }
    return getAxios().patch(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/proposal/${proposal_id}/section/${section_id}/update_item_section`, data);
}

export const deleteCustomSectionApi = (invoice_account_id, proposal_id, section_id) => {
    return getAxios().delete(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/proposal/${proposal_id}/section/${section_id}/delete`);
}

export const duplicateProposalApi = (invoice_account_id, proposal_id, entity_id, client_id, currency_id, quantity_enable, proposal_items) => {
    const data = {
        entity_id, 
        client_id, 
        currency_id, 
        quantity_enable, 
        proposal_items
    }
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/proposal/${proposal_id}/duplicate`, data);
}

export const markApporvedRejectedSentApi = (invoice_account_id, proposal_id, apiStr) => {
    const data = {}
    return getAxios().patch(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/proposal/${proposal_id}/${apiStr}`, data);
}

export const downloadProposalPdfApi = (invoice_account_id, entity_id, client_id, proposal_id) => {
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/entity/${entity_id}/client/${client_id}/proposal/${proposal_id}/download`);
}

export const sendProposalMailApi = (invoice_account_id, proposal_id, email_array, subject, body) => {
    const data = {
        email_array,
        subject,
        body
    }
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/proposal/${proposal_id}/send`, data);
}

export const getClientProposalPreviewApi = (md5, proposal_id, url_key) => {
    const param = {
        params: {
            url_key
        }
    }
    getAxios().defaults.headers.common['Challenge'] = md5;
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/client/proposal/${proposal_id}/preview`, param);
}


export const approveRejectApi = (md5, proposal_id, url_key, apiPath) => {
    const data = {
            url_key
    }
    getAxios().defaults.headers.common['Challenge'] = md5;
    return getAxios().patch(`${process.env.REACT_APP_BASE_URL}/proposal/${proposal_id}/${apiPath}`, data);
}

export const downloadClientProposalApi = (md5, proposal_id, url_key) => {
    const param = {
        params: {
            url_key
        }
    }
    getAxios().defaults.headers.common['Challenge'] = md5;
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/proposal/${proposal_id}/download`, param);
}

export const revokeProposalApi = (invoice_account_id, proposal_id) => {
    return getAxios().patch(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/proposal/${proposal_id}/revoke`);
}

export const getAttachmentUploadURLApi = (invoice_account_id, attachment_type, attachment_subtype, attachment_filename, item_id) => {
    let proposal_id;
    let entity_slug;
    if(attachment_type === 'proposal') {
        proposal_id = item_id;
    }
    if(attachment_type === 'entity') {
        entity_slug = item_id;
    }

    const param = {
        params: {
            proposal_id,
            entity_slug,
            attachment_type,
            attachment_subtype,
            attachment_filename
        }
    }
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/upload_signed_url`, param);
}

export const getAttachmentDownloadURLApi = (invoice_account_id, attachment_subtype, path, proposal_id) => {

    const param = {
        params: {
            attachment_subtype,
            path,
            proposal_id
        }
    }
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/download_signed_url`, param);
}