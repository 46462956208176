import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles'; 
import {Grid, Typography,Button, CircularProgress, ClickAwayListener, Tooltip, Chip} from '@material-ui/core';
import { connect } from 'react-redux';
import {setInvoiceAccountsList, setSelectedInvoiceAccount, setClearInvoiceAccounts} from '../actions/invoiceAccount';
import { useIsMount } from './useIsMount';
import AddEditInvoiceItemsDrawer from './AddEditInvoiceItemsDrawer';
import {deleteItemApi, activateItemApi, getAllItemListApi} from '../services/authService';
import { consoleToLog, AccessLevel } from '../util/AppUtil';
import { logout } from '../actions/invoiceAuth';
import { clearSelectedInvoiceFilters } from '../actions/invoiceFilters';
import { setSelectedDrawerState } from '../actions/drawer';
import { clearSelectedTransactionFilters } from "../actions/transactionFilters";
import {trackGAEvent} from '../util/AppUtil';
import { useSnackbar } from 'notistack';
import CreateTaxPresetDrawer from './CreateTaxPresetDrawer';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { formatAmount } from '../util/currencyUtil';
import { isInvoiceAccountCountryIndia } from '../util/AppUtil';
import {Paper, Table, TableContainer, TableBody, TableHead, TableRow, TableCell} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    componentContainer: {
        backgroundColor: theme.palette.primary.light, 
        transition: ".5s", 
        flexGrow: 1,
        //overflow:'hidden',
        overflow:'auto',
        overflowX:'hidden',
        padding: "12px"
    },
    settingsContainer: {
        width:'100%',
        background:'#fff',
        borderRadius:'4px',
        marginBottom:'32px',
        padding: "20px",
    },
    heading: {
        fontSize: "20px",
        fontWeight: "600",
    },
    btn: {
        background: "#383838",
        width: "135px",
        paddingLeft: "4px",
        paddingRight: "4px",
        color: "white",
    },
    loading: {
        position:'relative',
        left:'50%',
        right:'50%',
        marginTop:'3%'
    },
    headings: {
        fontWeight: '600',
        color:'#6B7280',
        [theme.breakpoints.down(1400)]: {
            fontSize:'14px'
        },
    },
    itemTitleList: {
        background:'#f9fafb',
        borderBottom: '1px solid #edf2f7',
    },
    itemListContainer : {
        background:'white', 
        minWidth:"100%", 
        color:'#6b7280',
        position:'relative',
        borderBottom: '1px solid #edf2f7',
        '& .action__icon' : {
            padding:'2px 4px 0px 4px',
            border:'1px solid white',
            borderRadius:'4px',
            cursor:'pointer'
        },
        '&:hover .action__icon' : {
            border:'1px solid #ccc'
        },
        '&:hover':{
            backgroundColor:'#f7fafc',
        },
        '& .more_actionButton' : {
            //visibility:'hidden',
            // position:'absolute',
            // right:'90px',
            // width:'160px',
            // background:'white',
            // border:'1px solid #ccc',
            // borderRadius:'5px',
            // display:'flex',
            // justifyContent:'center',
            // aligItems:'center',
            // fontSize:'14px',
            // padding:'4px 0px 4px 0px',
            // [theme.breakpoints.down(1500)]: {
            //     right:'80px',
            //     padding:'3px 0px 3px 0px',
            // },
            visibility:'hidden',
            display: "flex",
            alignItems: "center",
            width:'120px !important',
            background:'#fff',
            border:'1px solid #ccc',
            borderRadius:'5px',
            padding:'1px 0px',
            fontSize:'12px',

        },
        '&:hover .more_actionButton' : {
            visibility:'visible',
        },
    },
    actionsIcons: {
        color: '#b5bbc2',
        fontSize: '18px',
        marginRight: '8px' 
    },
    actionIconContainer : {
        marginRight:'auto',
        display:'flex',
        alignItems:'center',
        position: "absolute",
        top:'10px',
        right: "10px",
        [theme.breakpoints.down(1500)] :{
            marginLeft:'-16px',
        },
        [theme.breakpoints.down(1450)] :{
            marginLeft:'-32px',
        },
        [theme.breakpoints.down(1400)] :{
            marginLeft:'-48px',
        },
        [theme.breakpoints.down(1320)] :{
            marginLeft:'-56px',
        },
        [theme.breakpoints.down(1290)] :{
            marginLeft:'-68px',
        },
    },
    flatIcons : {
        fontSize:'15px', 
        marginRight:'6px',
        display:'flex',
        alignItems:'center'
    },
}
));

const InvoiceItemsComponent = (props) => {
    const classes = useStyles();
    const isMount = useIsMount();
    const {enqueueSnackbar} = useSnackbar();

    const [openDrawer, setOpenDrawer] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [isActive, setIsActive] = useState(false);
    const [itemId, setItemId] = useState('');
    const [loading, setLoading] = useState(false);
    const [animationActive, setAnimationActive] = useState(false);
    const [itemObj, setItemObj] = useState({});
    const [openTaxPresetDrawer, setOpenTaxPresetDrawer] = useState(false);
    const [taxPresetObj,setTaxPresetObj]=useState(undefined);

    useEffect(() => {
        getItemList();
    }, []);

    useEffect(() => {
        if(!isMount) {
            getItemList();
        }
    }, [props.selectedAccount?.id]);

    useEffect(() => {
        if(!isMount) {
            if(props.openInvoiceItemsdrawerFromParent) {
                handleDrawerOpen();
            }
        }
    }, [props.openInvoiceItemsdrawerFromParent]);

    const { access_level } = props.selectedAccount || {};
    const accessLevelViewer = (AccessLevel.getUserAccessLevelValue(access_level) === AccessLevel.VIEWER) ? true : false;

    const currenciesObj = props.selectedAccount?.currencies;
    const invoiceAccountCountry = props.selectedAccount?.country;

    const getItemList = () => {
        const invoice_account_id = props.selectedAccount?.id;

        setLoading(true);
        getAllItemListApi(invoice_account_id)
        .then((respose) => {
            const res = respose.data;
            consoleToLog('Response getAllItemListApi: ', res);
            setLoading(false);

            setItemList(res);
        })
        .catch((e) => {
            consoleToLog('Error getAllItemListApi', e.response);
            setLoading(false);
            if(e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {variant: 'error'});
                return;
            }
        })
    }

    const handleDrawerOpen = () => {
        trackGAEvent(props.selectedAccount.name, 'Invoice Items Settings - Add Invoice items clicked', `${props.user.firstname} ${props.user.lastname}`);
        setOpenDrawer(true)
    }

    const handleDrawerClose = () => {
        setOpenDrawer(false);
        itemObj && setItemObj({});
        //!props.fromOnboardingComponent && props.handleInvoiceItemsDrawerCloseFromParent();
    }

    const handleTaxPresetDrawerOpen = () => {
       setOpenTaxPresetDrawer(true)
    }

    const handleTaxPresetDrawerClose = () => {
        setOpenTaxPresetDrawer(false)
    }

    const closeMoreOptionsMenu = () => {
        setIsActive(false);
        setItemId('');
    }

    const onEditItemClick = (e, item) => {
        trackGAEvent(props.selectedAccount.name, 'Invoice Items Settings - Edit Item button clicked', `${props.user.firstname} ${props.user.lastname}`);
        e.stopPropagation();
        setItemObj(item);
        handleDrawerOpen();
    }

    const onUpdateLocalList = (obj) => {
        let updatedArray = itemList.map((item) => {
            if(item.id === obj.id) {
                item = obj;
            }
            return item;
        });

        setItemList(updatedArray);
        setItemObj({});
    }

    const onDeleteItemClick = (e, item) => {
        e.stopPropagation();
        closeMoreOptionsMenu();
        //setAnimationActive(true);
        const str=item.active? "Deactivate": "Activate";
        if(window.confirm(`Are you sure you want to ${str} this item?`)) {
            const invoice_account_id = props.selectedAccount?.id;
            const item_id = item.id;
            //deactivate
            if(item.active){
                deleteItemApi(invoice_account_id, item_id)
                    .then((response) => {
                        const res = response.data;
                        consoleToLog('Response deleteItemApi: ', res);

                        const updated_array = itemList.map((item) =>{ 
                            if(item.id === res.id){
                                return res;
                            }
                            else {return item;}
                        });
                        setItemList(updated_array);
                        //props.fromOnboardingComponent && props.setOnboardingItemList(updated_array);
                        //setAnimationActive(false);

                        trackGAEvent(props.selectedAccount.name, 'Invoice Items Settings - Invoice item deactivated', `${props.user.firstname} ${props.user.lastname}`);
                    })
                    .catch((e) => {
                        setAnimationActive(false);
                        consoleToLog('Error deleteItemApi', e.response);
                        if(e.response.data && e.response.data.message) {
                            enqueueSnackbar(e.response.data.message, {variant: 'error'});
                            return;
                        }
                    })
            }//activate
            else{
                activateItemApi(invoice_account_id, item_id)
                    .then((response) => {
                        const res = response.data;
                        consoleToLog('Response deleteItemApi: ', res);

                        const updated_array = itemList.map((item) =>{ 
                            if(item.id === res.id){
                                return res;
                            }
                            else {return item;}
                        });
                        setItemList(updated_array);
                        //props.fromOnboardingComponent && props.setOnboardingItemList(updated_array);
                        //setAnimationActive(false);

                        trackGAEvent(props.selectedAccount.name, 'Invoice Items Settings - Invoice item activated', `${props.user.firstname} ${props.user.lastname}`);
                    })
                    .catch((e) => {
                        setAnimationActive(false);
                        consoleToLog('Error deleteItemApi', e.response);
                        if(e.response.data && e.response.data.message) {
                            enqueueSnackbar(e.response.data.message, {variant: 'error'});
                            return;
                        }
                    })
            }
        }
    }

    const onAddItemsClick = (obj) => {
        setItemList(oldItems => [...oldItems, obj]);
        props.fromOnboardingComponent && props.setOnboardingItemList(oldItems => [obj, ...oldItems]);
    }

    return (
        <div className={!props.fromOnboardingComponent?classes.componentContainer:{}}>

                <Paper variant='outlined' elevation={0} 
                style={{width:'100%', padding:props.fromOnboardingComponent && '16px 0px 0px 0px', background:props.fromOnboardingComponent && '#fff'}}>
                <TableContainer style={{overflow:'visible', height:'100%'}}>
                    <Table>
                    <TableHead style={{background:'#f7f7f7'}}>
                        <TableRow>
                            <TableCell className='tableheading_style'>Name</TableCell>
                            {isInvoiceAccountCountryIndia(invoiceAccountCountry) && <TableCell className='tableheading_style'>HSN/SAC Code</TableCell>}
                            <TableCell className='tableheading_style'>Price/Rate</TableCell>
                            <TableCell className='tableheading_style'>
                                <Grid item container justifyContent='flex-end'>
                                    <Button onClick={handleDrawerOpen} 
                                        variant='contained' 
                                        color='primary' size='small'>
                                        Add Item
                                        <AddCircleOutlineIcon fontSize='small' 
                                            style={{fontSize:'15px', marginLeft:'4px'}}
                                            className={'buttonPlusIcon'}/>
                                    </Button>
                                </Grid>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    {loading ?
                    <TableBody>
                        <TableRow hover className={classes.invoiceListHover}>
                            <TableCell colSpan={itemList && itemList.length > 1 ? 4 : 3} align='center'>
                                <CircularProgress size={25} style={{margin:'24px 0px'}}/>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                    :
                    <TableBody>
                        {
                        itemList && itemList.length > 0 ? itemList.map((item) => {
                            return (
                                <TableRow hover  key={item.id} className={classes.itemListContainer}>
                                    <TableCell className='tablebody_style'>
                                            {item?.title}
                                    </TableCell>
                                    {isInvoiceAccountCountryIndia(invoiceAccountCountry) &&
                                    <TableCell className='tablebody_style'>
                                            {item?.hsn_code}
                                    </TableCell>}
                                    <TableCell className='tablebody_style'>
                                        {formatAmount(item?.cost, currenciesObj)}
                                    </TableCell>
                                    <TableCell className='tablebody_style'>
                                    {!accessLevelViewer &&
                                        <div  className={classes.actionIconContainer}>
                                            <Button 
                                                onClick={(e) => onEditItemClick(e, item)}
                                                className='more_actionButton action__icon'>
                                                <i className={`flaticon-edit ${classes.flatIcons}`}></i>
                                                Edit
                                            </Button>
                                            <Button 
                                                onClick={(e) => onDeleteItemClick(e, item) }
                                                className='more_actionButton action__icon'>
                                                <i className={`flaticon-delete-1 ${classes.flatIcons}`}></i>
                                                {item.active? "Deactivate": "Activate"}
                                            </Button>
                                        </div>
                                    }
                                    </TableCell>
                                </TableRow>
                            );
                        })
                        :
                        <TableRow hover className={classes.invoiceListHover}>
                            <TableCell colSpan={4} align='center'>
                                {"No Items Found"}
                            </TableCell>
                        </TableRow>
                        
                        }
                    </TableBody>
                    }
                    </Table>
                </TableContainer>
            </Paper>

            {openDrawer && <AddEditInvoiceItemsDrawer 
                openDrawer={openDrawer} 
                handleDrawerClose={handleDrawerClose}
                onAddItemsClick={onAddItemsClick}
                itemObj={itemObj}
                setItemObj={setItemObj}
                onUpdateLocalList={onUpdateLocalList}
                handleTaxPresetDrawerOpen={handleTaxPresetDrawerOpen}
                taxPresetObj={taxPresetObj}
                setTaxPresetObj={setTaxPresetObj}
            />}
            {openTaxPresetDrawer && <CreateTaxPresetDrawer
                openDrawer={openTaxPresetDrawer}
                handleDrawerClose={handleTaxPresetDrawerClose}
                setTaxPresetObj={setTaxPresetObj}
            />}

        </div>
    );
}

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
    invoiceAccountsList: state.invoiceAccounts.invoiceAccountsList,
    user: state.invoiceAccounts.user
});

const mapDispatchToProps = (dispatch) => ({
    setInvoiceAccountsList: (accountList) => (dispatch(setInvoiceAccountsList(accountList))),
    setSelectedInvoiceAccount: (account) => (dispatch(setSelectedInvoiceAccount(account))),
    logout: () => dispatch(logout()),
    clearSelectedInvoiceFilters: () => dispatch(clearSelectedInvoiceFilters()),
    setClearInvoiceAccounts: () => dispatch(setClearInvoiceAccounts()),
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState)),
    clearSelectedTransactionFilters: () => dispatch(clearSelectedTransactionFilters())
})

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceItemsComponent);