import moment from 'moment';



const defaultState = {
    proposalIssueStartDateFilter: undefined,
    proposalIssueEndDateFilter: undefined,
    selectedStatusProposalFilter: ["draft", "approved", "rejected"],
    selectedClientsProposalFilter: [],
    selectedEntitiesProposalFilter: [],
    selectedActivityStatusProposalFilter: ["none", "sent", "viewed", "downloaded"]
}

function getDefaultStartDate() {
    return (moment().month() < 3) ? 
        moment().subtract(1, 'year').month('April').startOf('month').format('YYYY-MM-DD')
        : 
        moment().month('April').startOf('month').format('YYYY-MM-DD'); 
}

function getDefaultEndDate() {
    return (moment().month() < 3) ? 
        moment().month('March').endOf('month').format('YYYY-MM-DD')
        : 
        moment().add(1, 'year').month('March').endOf('month').format('YYYY-MM-DD');
}


export default (state = defaultState, action) => {
    switch(action.type) {
        
        case 'SET_PROPOSAL_ISSUE_START_DATE_FILTER' :
            return {
                ...state,
                proposalIssueStartDateFilter : action.start_date
            }
        
        case 'SET_PROPOSAL_ISSUE_END_DATE_FILTER' :
            return {
                ...state,
                proposalIssueEndDateFilter : action.end_date
            }  
            
        //status filters
        case 'ADD_SELECTED_STATUS_TO_PROPOSAL_FILTER':
            return {
                ...state,
                selectedStatusProposalFilter: [
                    ...state.selectedStatusProposalFilter,
                    action.status
                ]
            }
        
        case 'REMOVE_SELECTED_STATUS_FROM_PROPOSAL_FILTER':
            return {
                ...state,
                selectedStatusProposalFilter: state.selectedStatusProposalFilter.filter((s) => s !== action.status)
            }   

        //company filter
        case 'ADD_SELECTED_CLIENT_PROPOSAL_FILTER':
            return {
                ...state,
                selectedClientsProposalFilter: [
                    ...state.selectedClientsProposalFilter,
                    action.client
                ]
            }

        case 'DELETE_SELECTED_CLIENT_PROPOSAL_FILTER':
            return {
                ...state,
                selectedClientsProposalFilter: state.selectedClientsProposalFilter.filter((client) => client.id !== action.client.id)
            }
            
        case 'ADD_SELECTED_ENTITIES_TO_PROPOSAL_FILTER' :
            return {
                ...state,
                selectedEntitiesProposalFilter: [
                    ...state.selectedEntitiesProposalFilter,
                    action.entity
                ]
            }

        case 'REMOVE_SELECTED_ENTITIES_TO_PROPOSAL_FILTER' :
            return {
                ...state,
                selectedEntitiesProposalFilter: state.selectedEntitiesProposalFilter.filter((entity) => entity.id !== action.entity.id)
            }

        case 'ADD_SELECTED_ACTIVITY_STATUS_TO_PROPOSAL_FILTER':
            return {
                ...state,
                selectedActivityStatusProposalFilter: [
                    ...state.selectedActivityStatusProposalFilter,
                    action.activity_status
                ]
            }
        
        case 'REMOVE_SELECTED_ACTIVITY_STATUS_FROM_PROPOSAL_FILTER':
            return {
                ...state,
                selectedActivityStatusProposalFilter: state.selectedActivityStatusProposalFilter.filter((s) => s !== action.activity_status)
            }  
        
        //clear filters
        case 'CLEAR_SELECTED_PROPOSAL_FILTERS' :
            return defaultState     
        
        default:
            return state;
    }
}